import React, {useState} from 'react'
import {Avatar, Checkbox} from 'antd'
import API from '../../api'
import {Link} from 'react-router-dom'
import DynamicCounterChanger from '../DynamicCountChanger'
import DynamicCountModifier from '../DynamicCountModifier'
import {hasPermission} from '../../api/user'
import {FormattedMessage, useIntl} from 'react-intl'
import styles from './product.module.scss'
import {formatNumber} from "../helper/Integer/IntegerFormatter";

export default function ProductListItem({ product, onProductSelect }) {
  const [item, setItem] = useState(product)
  const language = useIntl()
  const [actualPrice, setActualPrice] = useState(item.actual_price)

  return (
    <div className={styles.productListItemContainer}>
      <div>
        <Avatar size={64} shape='square' src={item.image_url} />
      </div>
      <div className={styles.productDescriptionContainer}>
        <strong>{`(${item.product_id}) ${item.description}`}</strong>
        {hasPermission('product_actual_price_edit') && (
          <div>
            <FormattedMessage id='total_cost' />: {formatNumber((item.total_count + item.on_hand) * actualPrice, '֏')}
          </div>
        )}
        <FormattedMessage id='total_count' />: {(item.total_count + item.on_hand + (item.reserved_count ? item.reserved_count : 0)).toLocaleString()}
        <div>
          <FormattedMessage id='warehouse' />: {item.total_count.toLocaleString()}
        </div>
        <div>
          <FormattedMessage id='on_hand' defaultMessage='On hand' />:{' '}
          {item.on_hand ? item.on_hand.toLocaleString() : 0}
        </div>
        <div>
          <FormattedMessage id='reserved' />:{' '}
          {item.reserved_count ? item.reserved_count.toLocaleString() : 0}
        </div>
      </div>
      <div className={styles.productModifiersContainer}>
        <div>
          <DynamicCountModifier
            info={{
              name: language.formatMessage({ id: 'add' }),
            }}
            disabled={!hasPermission('product_count_edit')}
            onSubmit={async (amount) => {
              const newTotal = item.total_count + amount
              await API.put(`product/count/${item.product}`, {
                amount,
                total_count: item.total_count,
              })

              setItem({ ...item, total_count: newTotal })
            }}
            count={item.total_count}
          />
        </div>
        <div>
          <DynamicCountModifier
            maxValue={item.total_count}
            count={item.total_count}
            info={{
              name: language.formatMessage({ id: 'remove' }),
            }}
            disabled={!hasPermission('product_count_edit')}
            onSubmit={async (amount) => {
              const newTotal = item.total_count - amount
              await API.put(`product/count/${item.product}`, {
                amount: -amount,
                total_count: item.total_count,
              })

              setItem({ ...item, total_count: newTotal })
            }}
          />
        </div>
        {hasPermission('product_actual_price_edit') && (
          <>
            <div>
              <DynamicCounterChanger
                disabled={!hasPermission('product_actual_price_edit')}
                count={item.price}
                info={{
                  name: language.formatMessage({
                    id: 'default_price',
                  }),
                }}
                onSubmit={async (_, total) => {
                  await API.put(`product/price/${item.product}`, {
                    price: total,
                  })
                }}
              />
            </div>
            <div>
              <DynamicCounterChanger
                disabled={!hasPermission('product_actual_price_edit')}
                count={item.actual_price}
                info={{
                  name: language.formatMessage({
                    id: 'actual_price',
                  }),
                }}
                onSubmit={async (_, total) => {
                  await API.put(`product/actual_price/${item.product}`, {
                    actual_price: total,
                  })
                }}
                onChange={(amount) => {
                  setActualPrice(amount)
                }}
              />
            </div>
          </>
        )}
      </div>
      {hasPermission('product_view') && (
        <div >
          <Link style={{alignSelf: 'start', paddingLeft: 8}} to={`/view/product/${item.product}`}>
            <FormattedMessage id='view' />
          </Link>
          <Checkbox style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'end'}} onChange={(event) => {
            const cost = (item.total_count + item.on_hand) * actualPrice
            const count = item.total_count + item.on_hand + (item.reserved_count ? item.reserved_count : 0)
            onProductSelect(count, cost, event.target.checked)
          }}><FormattedMessage id='select' /></Checkbox>
        </div>
      )}
    </div>
  )
}
