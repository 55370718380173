import React from 'react'
import {Button, Form, Input, message, Modal} from 'antd'
import {FormattedMessage, useIntl} from 'react-intl'
import Api from "../../../api";
import {useLoading} from "../../helper/Hooks";

export default function CreateProfile({visibility, onProfileCreate, onCancel, permissions}) {
  const {loading, startLoading} = useLoading()
  const language = useIntl()

  async function onCreate(values) {
    startLoading(async () => {
      try {
        await Api.post(`/permission/profile/save`, {
          name: values.name,
          permissions: permissions
        })
        onProfileCreate()
      } catch (e) {
        message.error(e)
      }
    })
  }

  return (
    <Modal
      width={700}
      title={<FormattedMessage id='create_profile'/>}
      visible={visibility}
      onCancel={() => {
        onCancel()
      }}
      footer={''}
    >
      <Form onFinish={onCreate}>
        <Form.Item name='name' rules={[{required: true}]}>
          <Input placeholder={language.formatMessage({id: 'name'})}/>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' loading={loading}>
            <FormattedMessage id='create'/>
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
