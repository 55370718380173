import React, { useEffect, useState } from 'react'
import { DatePicker, message, Select, Table } from 'antd'
import moment from 'moment'
import SearchDropdown from '../helper/searchDropdown/SearchDropdown'
import SContent from '../helper/SidebarContent/'
import styles from './map.module.scss'
import { useLoading } from '../helper/Hooks'
import Map from './Map'
import API from '../../api'
import { isValidLocation } from '../helper/helper'
import { IntTextFormatter } from '../helper/Integer/IntegerFormatter'
import { useIntl } from 'react-intl'

const { Option } = Select

const statusList = [
  'matched',
  'not_matched',
  'client_missing',
  'manager_missing',
]

const ManagerTracker = () => {
  const lang = useIntl()
  const [filter, setFilter] = useState({
    manager: '',
    group: undefined,
    start: moment().startOf('day').valueOf(),
    end: moment().endOf('day').valueOf(),
    status: '',
  })
  const { loading, startLoading } = useLoading()
  const [clients, setClients] = useState([])
  const [markers, setMarkers] = useState([])
  const [center, setCenter] = useState({
    origin: undefined,
    destination: undefined,
  })

  useEffect(() => {
    if (filter.manager && filter.start && filter.end) {
      getClients()
    } else {
      setClients([])
      setMarkers([])
    }
  }, [filter.manager, filter.start, filter.end, filter.group, filter.status])

  const getClients = () => {
    startLoading(async () => {
      const response = await API.get(`manager/client/location`, {
        params: filter,
      })
      setClients(response.data.result)
      setMarkers(mapOrdersToMarkers(response.data.result))
    })
  }

  const mapOrdersToMarkers = (orders) => {
    return orders.reduce((prev, next) => {
      if (!!next.client_location) {
        prev.push({
          type: 'Feature',
          properties: {
            title: next.client.fullname,
            placeData: next,
            type: 'client',
            icon: 'shop-15-yellow',
          },
          geometry: {
            type: 'Point',
            coordinates: [next.client_location.long, next.client_location.lat],
          },
        })
      }

      return prev
    }, [])
  }

  const updateMarkers = (element, removeManager = false) => {
    if (!element.manager_location) {
      message.error(lang.formatMessage({ id: 'manager_location_corrupted' }))
      return
    }
    const managerMarker = {
      type: 'Feature',
      properties: {
        title: element.manager.fullname,
        placeData: element,
        type: 'manager',
        icon: 'pitch-15',
      },
      geometry: {
        type: 'Point',
        coordinates: [
          element.manager_location.long,
          element.manager_location.lat,
        ],
      },
    }
    const newMarkers = []
    if (!removeManager) {
      newMarkers.push(managerMarker)
    }
    if (markers[0].properties.type === 'client') {
      setMarkers([...newMarkers, ...markers])
    } else {
      setMarkers([...newMarkers, ...markers.splice(1, markers.length - 1)])
    }
  }

  return (
    <SContent
      title={lang.formatMessage({ id: 'manager_tracker' })}
      applyCss={false}
    >
      <div className={styles.managerMapContainer}>
        <div className={styles.managerMapFilter}>
          <div className={styles.tableFilterContainer}>
            <SearchDropdown
              placeholder={lang.formatMessage({
                id: 'select_manager_or_postman',
              })}
              width={240}
              url="/user/v2?roles=manager,postman&blocked=false"
              searchUrl="/user/v2?roles=manager,postman&blocked=false&name="
              onSelect={(selectedValue) =>
                setFilter({
                  ...filter,
                  manager: selectedValue,
                  group: undefined,
                })
              }
              renderItem={(item) => ({
                key: item._id,
                value: item._id,
                name: `${item.name} (${lang
                  .formatMessage({
                    id: item.role.name,
                  })
                  .toLocaleLowerCase()})`,
              })}
            />
            <Select
              allowClear={true}
              className={styles.filter}
              placeholder={lang.formatMessage({ id: 'select_status' })}
              onChange={(e) => {
                setFilter({ ...filter, status: e })
              }}
              disabled={!!!filter.manager}
            >
              {statusList.map((status) => (
                <Option key={status} value={status}>
                  {lang.formatMessage({ id: status })}
                </Option>
              ))}
            </Select>
            <SearchDropdown
              placeholder={lang.formatMessage({ id: 'select_group' })}
              value={filter.group}
              width={240}
              url={`/managerGroup/manager?manager=${filter.manager}`}
              onSelect={(selectedValue) => {
                setFilter({
                  ...filter,
                  group: selectedValue,
                })
              }}
              renderItem={(item) => ({
                key: item._id,
                value: item._id,
                name: item.name,
              })}
              enabled={!!filter.manager}
            />
            <DatePicker
              className={styles.filter}
              allowClear={false}
              format={'DD/MM/YYYY'}
              onChange={(value) => {
                setFilter({
                  ...filter,
                  start: moment(value).startOf('day').valueOf(),
                  end: moment(value).endOf('day').valueOf(),
                })
              }}
              value={moment(filter.start)}
            />
          </div>
          <Table
            showSorterTooltip={false}
            bordered={false}
            className={styles.managerTrackerCell}
            columns={[
              {
                title: lang.formatMessage({ id: 'date' }),
                dataIndex: 'date',
                width: 90,
                render: (date) =>
                  date ? moment(date).format('HH:mm:ss') : '-',
                sorter: (a, b) => {
                  if (!a.date) {
                    return -1
                  } else if (!b.date) {
                    return 1
                  }
                  return a.date - b.date
                },
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
              },
              {
                title: lang.formatMessage({ id: 'client' }),
                dataIndex: 'client',
                width: 220,
                render: (client) => (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    title={client.fullname}
                  >
                    <div
                      style={{
                        width: 200,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {client.fullname}
                    </div>
                  </div>
                ),
                sorter: (a, b) =>
                  a.client.fullname.localeCompare(b.client.fullname),
                sortDirections: ['descend', 'ascend'],
              },
              {
                title: lang.formatMessage({ id: 'distance_m' }),
                dataIndex: 'distance',
                width: 80,
                render: (distance) =>
                  distance ? (
                    <IntTextFormatter
                      number={distance < 999999 ? distance : 99999}
                    />
                  ) : (
                    '-'
                  ),
                sorter: (a, b) => a.distance - b.distance,
                sortDirections: ['descend', 'ascend'],
              },
              {
                title: lang.formatMessage({ id: 'status' }),
                dataIndex: 'status',
                render: (status) => lang.formatMessage({ id: status }),
                sorter: (a, b) => a.status.localeCompare(b.status),
                sortDirections: ['descend', 'ascend'],
              },
            ]}
            dataSource={clients}
            loading={loading}
            pagination={false}
            scroll={{ y: '67.8vh' }}
            onRow={(element) => {
              return {
                onClick: () => {
                  if (isValidLocation(element.manager_location)) {
                    updateMarkers(element)
                    if (isValidLocation(element.client_location)) {
                      setCenter({
                        origin: element.manager_location,
                        destination: element.client_location,
                      })
                    } else {
                      setCenter({ origin: element.manager_location })
                    }
                  } else {
                    message.error(
                      lang.formatMessage({ id: 'manager_location_missing' })
                    )
                  }
                },
              }
            }}
            rowClassName={(element) =>
              element.status !== 'matched'
                ? styles.managerTrackerRedRow
                : styles.rowHoverPointer
            }
            rowKey={(el) => el.date}
          />
        </div>
        <Map
          markers={markers}
          center={center.destination}
          route={center}
          isMarkerClickable={false}
          className={styles.managerTrackerMapContainer}
        />
      </div>
    </SContent>
  )
}

export default ManagerTracker
