import React from 'react'
import {Input, InputNumber} from "antd";

const IntTextFormatter = ({number, prefixNumber = 1,prefix = '', currencyText = '', nextLine = false}) => {
  let numberValue = number !== 0 ? number * prefixNumber: 0
  return nextLine ? (
    <div style={{display: "flex", flexDirection: 'column'}}>
      <span>{`${prefix}`}</span>
      <span>{`${numberValue.toLocaleString()}${currencyText}`}</span>
    </div>
  ) : (
    <span>{`${prefix}${numberValue.toLocaleString()}${currencyText}`}</span>
  )
}

const IntInputNumberFormatter = ({number = 0, prefixNumber= 1, prefix = '', currencyText = '', onChange, ...props}) => {
  let numberValue = number * prefixNumber
  return (
    <InputNumber
      {...props}
      max={9999999999}
      value={numberValue}
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').concat(currencyText)}
      parser={value => parseInt(value.replace(/(,*)/g, '').replace(currencyText, ''), 10)}
      onChange={value => {
        if (value <= 9999999999) {
          onChange(value)
        }
      }}
    />
  )
}

//TODO WIP
const IntInputFormatter = ({number = 0, prefix = '', currencyText = '֏', onChange}) => {
  return (
    <Input type={"number"} value={number} onChange={onChange}>

    </Input>
  );
};
const formatNumber = (number, currencyText = '', prefix = '', prefixNumber = 1) => {
  let numberValue = number ? number * prefixNumber : 0
  return `${prefix}${numberValue.toLocaleString()}${currencyText}`
}

export {IntTextFormatter, IntInputNumberFormatter, IntInputFormatter, formatNumber}