import { Select } from 'antd'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { isManager, isPostman, isUserClient } from '../../../api/user'
import DateRangeFilter from '../../helper/DateRangeFilter'
import InfiniteScrollWrapper from '../../helper/InfiniteScroll'
import SearchDropdown from '../../helper/searchDropdown/SearchDropdown'
import styles from './info.module.scss'
import UserBalanceHistoryItem from './UserBalanceHistoryItem'

const { Option } = Select
function UserBalanceHistory({ user, ...props }) {
  const lang = useIntl()
  const [filter, setFilter] = useState({
    fromUser: '',
    start: moment().startOf('month').valueOf(),
    end: moment().valueOf(),
    type: '',
  })

  const types = [
    'admin_user_balance',
    'admin_make_order',
    'manager_make_order',
    'manager_return_order',
    'postman_finish_order',
    'archive_finished_order',
    'archive_returned_order',
    'transaction',
  ]

  const getFromUserUrl = () => {
    let baseUrl = '/user/v2?roles='
    if (isManager(user)) {
      baseUrl += 'client,admin'
    } else if (isUserClient(user)) {
      baseUrl += 'manager,admin'
    } else if (isPostman(user)) {
      baseUrl += 'client'
    }

    baseUrl += '&blocked=false'

    return baseUrl
  }

  const { current: fromFilterUrl } = useRef(getFromUserUrl())

  const onDateChange = ({ start, end }) => {
    setFilter({
      ...filter,
      start: start,
      end: end,
    })
  }

  return (
    <div {...props}>
        <div className={styles.filterContainer}>
          <DateRangeFilter
            style={{ width: 200, marginBottom: 10 }}
            placeholder={lang.formatMessage({ id: 'time' })}
            onChange={onDateChange}
            defaultValue={moment().format('MMMM')}
            intervals={[3, 6, 12]}
          />
          {!isUserClient(user) && (
            <SearchDropdown
              placeholder={lang.formatMessage({ id: 'from' })}
              width={200}
              url={fromFilterUrl}
              searchUrl={`${fromFilterUrl}&name=`}
              onSelect={(selectedValue) =>
                setFilter({
                  ...filter,
                  fromUser: selectedValue ? selectedValue : '',
                })
              }
              renderItem={(item) => ({
                key: item._id,
                value: item._id,
                name: item.fullname,
              })}
            />
          )}
          <Select
            placeholder={lang.formatMessage({ id: 'status' })}
            allowClear={true}
            style={{ width: 200, marginBottom: 10 }}
            onChange={(e) => {
              setFilter({
                ...filter,
                type: e ? e : '',
              })
            }}
          >
            {types.map((type) => {
              return (
                <Option key={type} value={type}>
                  {lang.formatMessage({ id: type })}
                </Option>
              )
            })}
          </Select>
        </div>
      <InfiniteScrollWrapper
        url={`/user/balance/${user._id}?start=${filter.start}&end=${filter.end}&fromUser=${filter.fromUser}&type=${filter.type}`}
        render={(item) => <UserBalanceHistoryItem item={item} user={user} />}
      />
    </div>
  )
}

export default UserBalanceHistory
