import React, {useState, useEffect} from 'react'
import {Table, message} from 'antd'
import moment from 'moment'
import SearchDropdown from '../helper/searchDropdown/SearchDropdown'
import SContent from '../helper/SidebarContent/'
import styles from './map.module.scss'
import {useLoading} from '../helper/Hooks'
import Map from './Map'
import API from '../../api'
import {useIntl} from "react-intl";

export default function ClientMap() {
  const lang = useIntl()
  const [filter, setFilter] = useState({
    managerId: '',
    groupId: '',
    clientId: '',
  })
  const {loading, startLoading} = useLoading()
  const [orders, setOrders] = useState([])
  const [markers, setMarkers] = useState([])
  const [center, setCenter] = useState({})

  useEffect(() => {
    getOrders()
  }, [filter.managerId, filter.groupId])

  useEffect(() => {
    if (filter.clientId) {
      filterOrders(filter.clientId)
    } else {
      getOrders()
    }
  }, [filter.clientId])

  const getOrders = () => {
    if (filter.managerId && filter.groupId) {
      startLoading(async () => {
        const response = await API.get(`/clientGroup/${filter.groupId}`)
        const result = response.data.result.clients
        setOrders(result)
        setMarkers(mapOrdersToMarkers(result))
      })
    } else {
      setOrders([])
      setMarkers([])
    }
  }

  const filterOrders = (clientId) => {
    const filteredOrders = orders.filter((order) => order._id == clientId)
    setOrders(filteredOrders)
    setMarkers(mapOrdersToMarkers(filteredOrders))
  }

  const mapOrdersToMarkers = (orders) => {
    return orders.map((e) => {
      return {
        type: 'Feature',
        properties: {
          title: e.fullname,
          date: e.latest_order_date,
          placeData: e,
          icon: 'shop-15-yellow',
        },
        geometry: {
          type: 'Point',
          coordinates: [e.location.long, e.location.lat],
        },
      }
    })
  }

  return (
    <SContent title={lang.formatMessage({id: 'client_map'})} applyCss={false}>
      <div className={styles.managerMapContainer}>
        <div className={styles.managerMapFilter}>
          <SearchDropdown
            placeholder={lang.formatMessage({id: 'select_manager'})}
            width={240}
            url='/user/v2?roles=manager&blocked=false'
            searchUrl='/user/v2?roles=manager&blocked=false&name='
            onSelect={(selectedValue) => {
              setFilter({
                managerId: selectedValue,
                groupId: '',
                clientId: '',
              })
            }}
            renderItem={(item) => ({
              key: item._id,
              value: item._id,
              name: item.fullname,
            })}
          />
          <SearchDropdown
            placeholder={lang.formatMessage({id: 'select_group'})}
            value={filter.groupId}
            width={240}
            url={`/managerGroup/manager?manager=${filter.managerId}&blocked=false`}
            onSelect={(selectedValue) => {
              setFilter({
                ...filter,
                groupId: selectedValue,
                clientId: '',
              })
            }}
            renderItem={(item) => ({
              key: item._id,
              value: item._id,
              name: item.name,
            })}
            enabled={!!filter.managerId}
          />
          <SearchDropdown
            placeholder={lang.formatMessage({id: 'select_client'})}
            width={240}
            value={filter.clientId}
            url={`/clientGroup/${filter.groupId}`}
            onSelect={(selectedValue) => {
              setFilter({
                ...filter,
                clientId: selectedValue,
              })
            }}
            renderItem={(item) => ({
              key: item._id,
              value: item._id,
              name: item.fullname,
            })}
            processResult={(response) => response.clients}
            enabled={!!filter.groupId}
          />
          <Table
            showSorterTooltip={false}
            bordered={false}
            className={styles.clientCell}
            columns={[
              {
                title: lang.formatMessage({id: 'shop'}),
                dataIndex: 'fullname',
                width: 180,
                render: (fullname) => (
                  <div
                    style={{display: 'flex', justifyContent: 'space-between'}}
                    title={fullname}>
                    <div style={{
                      width: 200,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                      {fullname}
                    </div>
                  </div>
                ),
                sorter: (a, b) => a.fullname.localeCompare(b.fullname),
                sortDirections: ['descend', 'ascend'],
              },
              {
                title: lang.formatMessage({id: 'date'}),
                width: 90,
                dataIndex: 'latest_order_date',
                render: (date) => date ? moment(date).format('MMM-DD HH:mm:ss') : '-',
                sorter: (a, b) => {
                  if (!a.date) {
                    return -1
                  } else if (!b.date) {
                    return 1
                  }
                  return a.date - b.date
                },
                sortOrder: 'descend',
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
              },
            ]}
            dataSource={orders}
            loading={loading}
            pagination={false}
            scroll={{y: '65vh'}}
            onRow={(element) => {
              return {
                onClick: () => {
                  if (element.location.long !== 0 || element.location.lat !== 0) {
                    setCenter(element.location)
                  } else {
                    message.error(
                      lang.formatMessage({id: 'client_location_missing'})
                    )
                  }
                },
              }
            }}
            rowClassName={styles.rowHoverPointer}
          />
        </div>
        <Map markers={markers} center={center} isMarkerClickable={false} className={styles.managerLocationMapContainer}/>
      </div>
    </SContent>
  )
}
