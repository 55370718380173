import { Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useSettings } from '../../helper/context/SettingsContext'
import { IntTextFormatter } from '../../helper/Integer/IntegerFormatter'
import styles from '../statistics.module.css'

export default function TransactionsByMonth ({ dataSet, loading }) {
  const [data, setData] = useState({
    items: [],
    totalProfit: 0,
    transactionTotal: 0,
    totalExpense: 0,
    balance: { currency: '', currency_text: '' }
  })
  const [columns, setColumns] = useState([])
  const history = useHistory()
  const lang = useIntl()
  const { returnEnabled } = useSettings()

  const parseData = () => {
    const {
      balance,
      items,
      totalExpense,
      totalProfit,
      transactionTotal
    } = dataSet
    const { graphItems } = items.reduce(
      (data, el) => {
        const dataItem = {
          time: el.date,
          profit: el.profit,
          transaction: el.transaction,
          profitPercentage: el.profitPercentage,
          expense: el.expense
        }
        if (returnEnabled) {
          data.graphItems.push({
            ...dataItem,
            returnTransaction: el.returnTransaction
          })
        } else {
          data.graphItems.push(dataItem)
        }
        return data
      },
      { graphItems: [] }
    )
    setData({
      items: graphItems,
      totalProfit: totalProfit,
      transactionTotal: transactionTotal,
      totalExpense: totalExpense,
      balance: balance
    })
    setupTableColumns(balance.currency_text)
  }

  const setupTableColumns = currency => {
    const columns = [
      {
        title: lang.formatMessage({ id: 'month' }),
        dataIndex: 'time',
        render: time => {
          let currentYear = moment().year()
          let itemTime = moment(time)
          return itemTime.year() !== currentYear
            ? itemTime.format('MMMM (YYYY)')
            : itemTime.format('MMMM')
        },
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.time - b.time,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: lang.formatMessage({ id: 'transaction' }),
        dataIndex: 'transaction',
        render: transaction => (
          <IntTextFormatter currencyText={currency} number={transaction} />
        ),
        sorter: (a, b) => a.transaction - b.transaction,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: lang.formatMessage({ id: 'profit' }),
        dataIndex: 'profit',
        render: profit => (
          <IntTextFormatter currencyText={currency} number={profit} />
        ),
        sorter: (a, b) => a.profit - b.profit,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: lang.formatMessage({ id: 'profit_percentage' }),
        dataIndex: 'profitPercentage',
        render: profitPercentage =>
          `${profitPercentage ? profitPercentage + '%' : '-'}`,
        sorter: (a, b) => {
          if (!a.profitPercentage) {
            return -1
          } else if (!b.profitPercentage) {
            return 1
          }
          return a.profitPercentage - b.profitPercentage
        },
        sortDirections: ['descend', 'ascend']
      },
    ]
    if (returnEnabled) {
      columns.push({
        title: lang.formatMessage({ id: 'return_transaction' }),
        dataIndex: 'returnTransaction',
        render: returnTransaction => (
          <IntTextFormatter
            currencyText={currency}
            number={returnTransaction ?? 0}
          />
        ),
        sorter: (a, b) => a.returnTransaction - b.returnTransaction,
        sortDirections: ['descend', 'ascend']
      })
    }
    columns.push({
      title: lang.formatMessage({ id: 'expenses' }),
      dataIndex: 'expense',
      render: expense => (
        <IntTextFormatter currencyText={currency} number={expense} />
      ),
      sorter: (a, b) => a.expense - b.expense,
      sortDirections: ['descend', 'ascend']
    })
    setColumns(columns)
  }

  useEffect(() => {
    if (dataSet) {
      parseData()
    }
  }, [dataSet])

  return columns.length > 0 ? (
    <Table
      title={() =>
        lang.formatMessage({ id: 'profit_calculation_expense_included' })
      }
      showSorterTooltip={false}
      size='small'
      bordered={true}
      loading={loading}
      className={styles.statisticsTables}
      columns={columns}
      rowKey={() => Math.random(0, data.items.length)}
      onRow={element => {
        return {
          onClick: () => {
            history.push(
              `/dashboard-managers?month=${moment(element.time).format(
                'MMMM_YYYY'
              )}`
            )
          }
        }
      }}
      rowClassName={styles.rowHoverPointer}
      pagination={{
        defaultPageSize: 9,
        total: data.items.length,
        position: ['bottomCenter']
      }}
      dataSource={data.items}
    />
  ) : ''
}
