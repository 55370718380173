import { useState } from "react"
import moment from "moment"
import { useEffect } from "react"

export default function useDashboardFilter(manager = "", category = "") {
  const [dateRange, setDateRange] = useState({
    start: "",
    end: "",
  })

  const [filter, setFilter] = useState({
    managerId: "",
    categoryId: "",
    groupId: undefined,
    productId: undefined,
    clientId: undefined,
  })

  useEffect(() => {
    setDateRange({
      start: moment().startOf("month").valueOf(),
      end: moment().valueOf(),
    })
    if (manager) {
      setFilter({ ...filter, managerId: manager })
    }
    if (category) {
      setFilter({ ...filter, categoryId: category })
    }
  }, [])

  return { dateRange, setDateRange, filter, setFilter }
}
