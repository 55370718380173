import { useLazyFetcher } from "../../helper/Fetcher"

export default function useGroups(managerId) {
  const { data, isLoading, refetch } = useLazyFetcher(
    `/managerGroup/manager?manager=${managerId}`
  )
  console.log("#art", data)
  return {
    groups: !isLoading && data ? data : [],
    isGroupsLoading: isLoading,
    getGroups: refetch,
  }
}
