import moment from 'moment'

const getDate = (timestamps, time) => {
  return time
    ? moment(timestamps).format(' DD/MM/YYYY HH:mm:ss')
    : moment(timestamps).format(' DD/MM/YYYY')
}

// function getDate(timestamps, time) {
//   const date = new Date(timestamps)
//   let dateString =
//     date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
//   if (time) {
//     const hours = ('0' + date.getHours()).substr(-2)
//     const minutes = ('0' + date.getMinutes()).substr(-2)
//     const seconds = ('0' + date.getSeconds()).substr(-2)
//     dateString += ' ' + hours + ':' + minutes + ':' + seconds
//   }

//   return dateString
// }

const getCurrencyStringAMD = (string) => string + '֏'

const getError = (err) => {
  if(err.response){
    return err.response.data.message
  } else {
    return err.message
  }
}

const isValidLocation = (location) => !!location && !!location.long && !!location.lat

export { getDate, getCurrencyStringAMD, getError, isValidLocation }
