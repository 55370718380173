import React, {useEffect, useState} from 'react'
import SBContent from '../helper/SidebarContent/'
import {Button, Form, Input, message, Select, Upload} from 'antd'
import Icon from '@ant-design/icons'
import API from '../../api'
import {useHistory} from 'react-router-dom'
import {FormattedMessage, useIntl} from 'react-intl'

const { Option } = Select

function ProductEdit(props) {
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState({})
  const [image, setImage] = useState(undefined)
  const [categories, setCategories] = useState([])
  const [form] = Form.useForm()
  const history = useHistory()
  const language = useIntl()

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    fetchProductInfo()
  }, [])
  useEffect(() => {
    if (product._id) {
      form.setFieldsValue({
        product_id: product.product_id,
        description: product.description,
        category: product.category._id,
      })
    }
  }, [product])

  async function fetchCategories() {
    const resposne = await API.get('/category')

    setCategories(resposne.data.result.data)
  }

  const fetchProductInfo = async () => {
    const response = await API.get(`product/${props.match.params.id}`)
    const product = response.data.result.product
    setProduct(product)
  }

  const updateProduct = async (data) => {
    const formData = new FormData()
    if (image) {
      formData.append('photo', image)
    }

    Object.keys(data).forEach((key, value) => {
      formData.append(key, data[key])
    })

    const result = await API.put(
      `product/${props.match.params.id}`,
      formData,
      {}
    )
  }

  const handleFinish = async (newProduct) => {
    try {
      setLoading(true)
      await updateProduct(newProduct)
      history.goBack()
    } catch (e) {
      message.error(e.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <SBContent title={language.formatMessage({id: "edit_product"})}>
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 12 }}
        onFinish={handleFinish}
      >
        <Form.Item label={<FormattedMessage id='upload' />}>
          <div>
            <Upload
              type
              name='logo'
              listType='picture'
              accept='jpg'
              showUploadList={false}
              beforeUpload={(file) => {
                setImage(file)
                return false
              }}
            >
              <Button>
                <Icon type='upload' /> <FormattedMessage id='click_to_upload' />
              </Button>
            </Upload>
            <img
              style={{ marginLeft: 20 }}
              src={image ? URL.createObjectURL(image) : product.image_url}
              width='100'
            />
          </div>
        </Form.Item>
        <Form.Item
          label={language.formatMessage({id: "id"})}
          name='product_id'
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (value.match(/[\s]/)) {
                  return Promise.reject(
                    language.formatMessage({ id: 'id_rule' })
                  )
                }
                return Promise.resolve()
              },
              message: language.formatMessage({ id: 'id_rule' }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={language.formatMessage({ id: 'name' })}
          name='description'
          rules={[
            {
              required: true,
              message: language.formatMessage({
                id: 'fill_product_description',
              }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={language.formatMessage({ id: 'category' })}
          name='category'
          rules={[
            {
              required: true,
              message: language.formatMessage({
                id: 'fill_product_category',
              }),
            },
          ]}
        >
          <Select>
            {categories.map((category) => (
              <Option value={category._id}>{category.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
          <Button type='primary' htmlType='submit' loading={loading}>
            <FormattedMessage id='save' />
          </Button>
        </Form.Item>
      </Form>
    </SBContent>
  )
}

export default ProductEdit
