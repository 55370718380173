import React, {useRef, useState} from 'react'
import {Input, message} from 'antd'
import {CheckOutlined, LoadingOutlined} from '@ant-design/icons'
import {FormattedMessage, useIntl} from 'react-intl'

export default function Balance({
  count,
  onSubmit,
  min = {},
  max = {},
  info: { name, suffix = '', title },
  canWithdraw,
  canDeposit,
}) {
  const [withdrawLoading, setWithDrawLoading] = useState(false)
  const [depositLoading, setDepositLoading] = useState(false)
  const currentBalance = useRef(count)
  const [total, setTotal] = useState(count)
  const [withdraw, setWithdraw] = useState(0)
  const [deposit, setDeposit] = useState(0)
  const lang = useIntl()

  const onWithDrawSubmit = async (amount, setLoading) => {
    if (amount === 0) return
    setLoading(true)
    try {
      await onSubmit(total, amount)
      currentBalance.current = total
      setDeposit(0)
      setWithdraw(0)
    } catch (err) {
      message.error(err.message)
    }
    setLoading(false)
  }

  return (
    <div
      style={{
        height: 100,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Input
        style={{ width: '100%' }}
        size='small'
        disabled={true}
        value={`${lang.formatMessage({id: "balance"})} ${total}${suffix} `}
      />
      {canWithdraw && (
        <div style={{ marginTop: 10 }}>
          <Input
            disabled={deposit > 0 || withdrawLoading}
            type='number'
            step={100}
            size='small'
            value={withdraw}
            addonBefore={
              <div style={{ textAlign: 'left', width: 140 }}>
                <FormattedMessage id='withdraw' />
              </div>
            }
            min={0}
            onChange={(e) => {
              const value = parseInt(e.target.value)
              setWithdraw(value)
              if (!Number.isNaN(value) || value < 0) {
                setTotal(currentBalance.current + value)
              } else {
                setTotal(currentBalance.current)
              }
            }}
            addonAfter={
              withdrawLoading ? (
                <LoadingOutlined />
              ) : (
                <CheckOutlined
                  style={
                    deposit > 0
                      ? { color: '#1a90ff' }
                      : { color: '#1a90ff', cursor: 'pointer' }
                  }
                  onClick={() => {
                    if (deposit > 0) {
                      return
                    }
                    if (withdraw > 0)
                      onWithDrawSubmit(withdraw, setWithDrawLoading)
                  }}
                />
              )
            }
          />
        </div>
      )}
      {canDeposit && (
        <div style={{ marginTop: 10 }}>
          <Input
            disabled={withdraw > 0 || depositLoading}
            type='number'
            step={100}
            size='small'
            min={0}
            addonBefore={
              <div style={{ textAlign: 'left', width: 140 }}>
                <FormattedMessage id='deposit' />
              </div>
            }
            addonAfter={
              depositLoading ? (
                <LoadingOutlined />
              ) : (
                <CheckOutlined
                  style={
                    withdraw > 0
                      ? { color: '#1a90ff' }
                      : { color: '#1a90ff', cursor: 'pointer' }
                  }
                  onClick={() => {
                    if (withdraw > 0) {
                      return
                    }
                    if (deposit > 0)
                      onWithDrawSubmit(-deposit, setDepositLoading)
                  }}
                />
              )
            }
            value={deposit}
            onChange={(e) => {
              let value = parseInt(e.target.value)
              setDeposit(value)
              if (!Number.isNaN(value) || value < 0) {
                setTotal(currentBalance.current - value)
              } else {
                setTotal(currentBalance.current)
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
