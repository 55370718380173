import React, { useEffect, useState } from 'react'
import SBContent from '../../helper/SidebarContent/'
import ProductCard from './ProductCard'
import { Skeleton, Tabs } from 'antd'
import Api from '../../../api'
import { useQuery } from '../../helper/Hooks'
import { useHistory } from 'react-router-dom'
import ProductHistoryTab from './ProductHistoryTab'
import { hasPermission } from '../../../api/user'
import { FormattedMessage, useIntl } from 'react-intl'
import styles from '../product.module.scss'
import ProductPriceLimits from './ProductPriceLimits'

const { TabPane } = Tabs

export default function Product(props) {
  const productId = props.match.params.id
  const tab = useQuery('tab')

  const [activeTab, setActiveTab] = useState('product_history')
  const [product, setProduct] = useState({})
  const [productLoading, setProductLoading] = useState(true)
  const lang = useIntl()

  let history = useHistory()

  useEffect(() => {
    getProduct()
  }, [])

  const getProduct = async () => {
    const response = await Api.get(`/product/${productId}`)
    setProduct(response.data.result.product)
    console.log(response.data.result.product)
    setProductLoading(false)
    selectTabIfHas()
  }

  const selectTabIfHas = () => {
    if (tab) {
      onTabChange(tab)
    }
  }

  const onTabChange = (tab) => {
    setActiveTab(tab)
    history.replace(`/view/product/${productId}?tab=${tab}`)
  }

  return (
    <SBContent title={lang.formatMessage({ id: 'product' })}>
      <div className={styles.productContainer}>
        <div>
          <ProductCard product={product} loading={productLoading} />
        </div>
        <div className={styles.productHistory}>
          <Skeleton loading={productLoading}>
            <Tabs activeKey={activeTab} onChange={onTabChange}>
              {hasPermission('product_history_view') && (
                <TabPane
                  tab={<FormattedMessage id="product_history" />}
                  key="product_history"
                >
                  <ProductHistoryTab productId={product._id} />
                </TabPane>
              )}
              {hasPermission('product_edit') && (
                <TabPane
                  tab={<FormattedMessage id="product_price" />}
                  key="product_price"
                >
                  <ProductPriceLimits productId={product._id} />
                </TabPane>
              )}
            </Tabs>
          </Skeleton>
        </div>
      </div>
    </SBContent>
  )
}
