import React, { useState, useEffect } from 'react'
import { Table, Button } from 'antd'
import { EnvironmentOutlined } from '@ant-design/icons'
import moment from 'moment'
import SContent from '../helper/SidebarContent/'
import styles from './map.module.scss'
import { useLoading } from '../helper/Hooks'
import Map from './Map'
import API from '../../api'
import {FormattedMessage, useIntl} from "react-intl";

const ShopFinder = () => {
  const lang = useIntl()
  const { loading, startLoading } = useLoading()
  const [shops, setShops] = useState([])
  const [markers, setMarkers] = useState([])
  const [radiusData, setRadiusData] = useState()
  const [center, setCenter] = useState({})

  const getOrders = () => {
    startLoading(async () => {
      const response = await API.get(
        `/user/inRadius?lng=${radiusData.lng}&lat=${radiusData.lat}&radius=${radiusData.radius}`
      )
      setShops(response.data.result)
    })
  }

  useEffect(() => {
    setMarkers(mapClientsToMarkers(shops))
  }, [shops])

  function mapClientsToMarkers(clients) {
    return clients.map((e) => {
      return {
        type: 'Feature',
        properties: {
          title: e.fullname,
          date: e.latest_order_date,
          placeData: e,
          icon: 'shop-15-yellow',
        },
        geometry: {
          type: 'Point',
          coordinates: [e.location.long, e.location.lat],
        },
      }
    })
  }

  return (
    <SContent title={lang.formatMessage({id: 'shop_finder'})} applyCss={false}>
      <div className={styles.managerMapContainer}>
        <div className={styles.managerMapFilter}>
          <Button
            type='primary'
            icon={<EnvironmentOutlined />}
            onClick={getOrders}
          >
           <FormattedMessage id='get_shops_by_radius' />
          </Button>
          <Table
            showSorterTooltip={false}
            bordered={false}
            className={styles.clientCell}
            columns={[
              {
                title: lang.formatMessage({id: 'shop'}),
                dataIndex: 'fullname',
                width: 180,
                render: (fullname) => (
                  <div
                    style={{display: 'flex', justifyContent: 'space-between'}}
                    title={fullname}>
                    <div style={{
                      width: 200,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                      {fullname}
                    </div>
                  </div>
                ),
                sorter: (a, b) => a.fullname.localeCompare(b.fullname),
                sortDirections: ['descend', 'ascend'],
              },
              {
                title: lang.formatMessage({id: 'date'}),
                width: 90,
                dataIndex: 'latest_order_date',
                render: (date) => date ? moment(date).format('HH:mm:ss') : '-',
                sorter: (a, b) => {
                  if (!a.date) {
                    return -1
                  } else if (!b.date) {
                    return 1
                  }
                  return a.date - b.date
                },
                sortOrder: 'descend',
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
              },
            ]}
            dataSource={shops}
            loading={loading}
            pagination={false}
            scroll={{y: '73vh'}}
            onRow={(element) => {
              return {
                onClick: () => {
                  setCenter(element.location)
                },
              }
            }}
            rowClassName={styles.rowHoverPointer}
          />
        </div>
        <Map
          markers={markers}
          center={center}
          isMarkerClickable={false}
          className={styles.managerLocationMapContainer}
          onRadiusChange={(radius, { lng, lat }) => {
            setRadiusData({
              lng: lng,
              lat: lat,
              radius: radius,
            })
          }}
        />
      </div>
    </SContent>
  )
}

export default ShopFinder
