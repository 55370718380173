import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSettings } from '../../helper/context/SettingsContext'
import { IntTextFormatter } from '../../helper/Integer/IntegerFormatter'
import styles from '../statistics.module.css'

export default function ProductTransactionTable ({ dataSet, loading }) {
  const lang = useIntl()
  const [data, setData] = useState({
    items: [],
    balance: { currency: '', currency_text: '' }
  })
  const [columns, setColumns] = useState([])
  const { returnEnabled } = useSettings()

  const parseData = () => {
    const {
      balance,
      chartData: { items }
    } = dataSet

    const { graphItems } = items.reduce(
      (data, el) => {
        const dataItem = {
          id: el.product._id,
          name: el.product.name,
          profit: el.profit,
          transaction: el.transaction,
          sold_count: el.sold_count,
          profitPercentage: el.profitPercentage
        }
        if (returnEnabled) {
          data.graphItems.push({
            ...dataItem,
            returnTransaction: el.returnTransaction
          })
        } else {
          data.graphItems.push(dataItem)
        }
        return data
      },
      { graphItems: [] }
    )
    setData({ ...data, items: graphItems, balance: balance })
    setupTableColumns(balance.currency_text)
  }

  const setupTableColumns = currency => {
    const columns = [
      {
        title: lang.formatMessage({ id: 'product' }),
        dataIndex: 'name',
        render: name => name,
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['descend', 'ascend']
      },
      {
        title: lang.formatMessage({ id: 'transaction' }),
        dataIndex: 'transaction',
        render: transaction => (
          <IntTextFormatter currencyText={currency} number={transaction} />
        ),
        sorter: (a, b) => a.transaction - b.transaction,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: lang.formatMessage({ id: 'profit' }),
        dataIndex: 'profit',
        render: profit => (
          <IntTextFormatter currencyText={currency} number={profit} />
        ),
        sorter: (a, b) => a.profit - b.profit,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: lang.formatMessage({ id: 'profit_percentage' }),
        dataIndex: 'profitPercentage',
        render: profitPercentage =>
          `${profitPercentage ? profitPercentage + '%' : '-'}`,
        sorter: (a, b) => {
          if (!a.profitPercentage) {
            return -1
          } else if (!b.profitPercentage) {
            return 1
          }
          return a.profitPercentage - b.profitPercentage
        },
        sortDirections: ['descend', 'ascend']
      },
      {
        title: lang.formatMessage({ id: 'sold_count' }),
        dataIndex: 'sold_count',
        render: count => <IntTextFormatter number={count} />,
        sorter: (a, b) => a.sold_count - b.sold_count,
        sortDirections: ['descend', 'ascend']
      }
    ]
    if (returnEnabled) {
      columns.push({
        title: lang.formatMessage({ id: 'return_transaction' }),
        dataIndex: 'returnTransaction',
        render: returnTransaction => (
          <IntTextFormatter
            currencyText={currency}
            number={returnTransaction ?? 0}
          />
        ),
        sorter: (a, b) => a.returnTransaction - b.returnTransaction,
        sortDirections: ['descend', 'ascend']
      })
    }
    setColumns(columns)
  }

  useEffect(() => {
    if (dataSet) {
      parseData()
    }
  }, [dataSet])

  return columns.length > 0 ? (
    <Table
      showSorterTooltip={false}
      loading={loading}
      size='small'
      bordered={true}
      className={styles.statisticsTables}
      columns={columns}
      rowKey={element => element._id}
      pagination={{
        defaultPageSize: 15,
        total: data.items.length,
        position: ['bottomCenter']
      }}
      dataSource={data.items}
    />
  ) : ''
}
