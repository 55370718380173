import React, { useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import UserContext from './helper/context/UserContext'
import { hasPermission } from '../api/user'

export default function Home(props) {
  const { user, isLoading } = useContext(UserContext)

  return (
    <Redirect
      to={{
        pathname: user
          ? hasPermission('statistics')
            ? '/dashboard-core'
            : '/users'
          : 'login',
      }}
    />
  )
}
