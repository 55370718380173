import React, { useEffect, useState } from 'react'
import API from '../api'
import SettingsContext from '../component/helper/context/SettingsContext'
import { useLoading } from '../component/helper/Hooks'
import { Spin } from 'antd'
import { useUser } from '../component/helper/context/UserContext'

const withSettings =
  (Component) =>
  ({ ...props }) => {
    const { user } = useUser()
    const [settings, setSettings] = useState({ limits: {} })
    const { loading, startLoading } = useLoading(user)

    useEffect(() => {
      if (user) {
        getSettings()
      }
    }, [user])

    const getSettings = () => {
      startLoading(async () => {
        const response = await API.get(`/settings`)
        setSettings(response.data.result)
      })
    }

    return (
      <SettingsContext.Provider
        value={{ ...settings, refreshSettings: getSettings }}
      >
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Spin size='large' />
          </div>
        ) : (
          <Component {...props} />
        )}
      </SettingsContext.Provider>
    )
  }

export default withSettings
