import React, { useEffect, useState } from 'react'
import SContent from '../helper/SidebarContent/'
import { message, Modal, Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { FormattedMessage, useIntl } from 'react-intl'
import AddExpense from './AddExpense'
import styles from './expense.module.scss'
import API from '../../api'
import { Link } from 'react-router-dom'
import { getDate } from '../helper/helper'
import { hasPermission } from '../../api/user'
import { useLoading } from '../helper/Hooks'
import SearchDropdown from '../helper/searchDropdown/SearchDropdown'
import moment from 'moment'
import { formatNumber } from '../helper/Integer/IntegerFormatter'
import DateRangeFilter from '../helper/DateRangeFilter'

const { confirm } = Modal

export default function Expenses() {
  const language = useIntl()
  const [expenses, setExpenses] = useState([])
  const [filter, setFilter] = useState({
    byUser: '',
    fromUser: '',
    type: '',
    start: moment().subtract(3, 'month').valueOf(),
    end: moment().endOf('month').valueOf(),
  })
  const [totals, setTotals] = useState({
    total_clients: 0,
    total_balance: '',
  })
  const { loading, startLoading } = useLoading(true)

  useEffect(() => {
    getExpenses()
  }, [filter])

  const getExpenses = () => {
    startLoading(async () => {
      const response = await API.get(
        `/expense?user=${filter.byUser}&fromUser=${filter.fromUser}&type=${filter.type}&start=${filter.start}&end=${filter.end}`
      )
      const expenses = response.data.result
      setExpenses(expenses)
      let totalBalance = 0

      expenses.forEach(({ price }) => {
        totalBalance += price
      })

      setTotals({
        total_clients: expenses.length,
        total_balance: totalBalance,
      })
    })
  }

  const archiveExpense = async (expenseId) => {
    confirm({
      title: language.formatMessage({ id: 'expense_archive_question' }),
      async onOk() {
        try {
          await API.put(`/expense/archive/${expenseId}`)
          getExpenses()
        } catch (err) {
          message.error(err.message)
        }
      },
    })
  }

  const onDateChange = ({ start, end }) => {
    setFilter({
      ...filter,
      start: start,
      end: end,
    })
  }

  return (
    <SContent title={<FormattedMessage id="expenses" />}>
      <div className={styles.expenseContainer}>
        <AddExpense onExpenseAdd={getExpenses} />
        {hasPermission('expense_history_view') && (
          <div className={styles.tableContainer}>
            <div className={styles.filterContainer}>
              <DateRangeFilter
                onChange={onDateChange}
                placeholder={language.formatMessage({ id: 'time' })}
                defaultValue="3"
                style={{ width: 200, marginBottom: 10 }}
                intervals={[3]}
              />
              <SearchDropdown
                placeholder={language.formatMessage({ id: 'type' })}
                width={200}
                url="/expense/type"
                onSelect={(selectedValue) =>
                  setFilter({
                    ...filter,
                    byUser: filter.byUser,
                    fromUser: filter.fromUser,
                    type: selectedValue ? selectedValue : '',
                  })
                }
                renderItem={(item) => ({
                  key: item._id,
                  value: item._id,
                  name: item.name,
                })}
              />
              <SearchDropdown
                placeholder={language.formatMessage({ id: 'name' })}
                style={{ marginBottom: 10 }}
                width={200}
                url="/user/v2?roles=manager,postman,admin,superadmin&blocked=false"
                searchUrl="/user/v2?roles=manager,postman,admin,superadmin&blocked=false&name="
                onSelect={(selectedValue) =>
                  setFilter({
                    ...filter,
                    byUser: selectedValue ? selectedValue : '',
                    fromUser: filter.fromUser,
                    type: filter.type,
                  })
                }
                renderItem={(item) => ({
                  key: item._id,
                  value: item._id,
                  name: `${item.fullname} (${language
                    .formatMessage({
                      id: item.role.name,
                    })
                    .toLocaleLowerCase()})`,
                })}
              />
              <SearchDropdown
                placeholder={language.formatMessage({ id: 'from' })}
                width={200}
                url="/user/v2?roles=manager,postman,admin,superadmin&blocked=false"
                searchUrl="/user/v2?roles=manager,postman,admin,superadmin&blocked=false&name="
                onSelect={(selectedValue) =>
                  setFilter({
                    ...filter,
                    byUser: filter.byUser,
                    fromUser: selectedValue ? selectedValue : '',
                    type: filter.type,
                  })
                }
                renderItem={(item) => ({
                  key: item._id,
                  value: item._id,
                  name: `${item.fullname} (${language
                    .formatMessage({
                      id: item.role.name,
                    })
                    .toLocaleLowerCase()})`,
                })}
              />
            </div>
            <Table
              showSorterTooltip={false}
              title={() => (
                <>
                  <strong>
                    <FormattedMessage id="expense_count" />:{' '}
                    {totals.total_clients.toLocaleString()}
                  </strong>{' '}
                  <br />{' '}
                  <strong>
                    <FormattedMessage id="total" />:{' '}
                    {formatNumber(totals.total_balance, '֏')}
                  </strong>{' '}
                </>
              )}
              bordered={true}
              columns={[
                {
                  title: language.formatMessage({ id: 'type' }),
                  dataIndex: 'type',
                  render: (type) => type.name,
                },
                {
                  title: language.formatMessage({ id: 'name' }),
                  dataIndex: 'user',
                  render: (user) =>
                    user ? (
                      <Link
                        to={{
                          pathname: `/view/user/${user._id}`,
                        }}
                      >
                        {user.fullname}
                      </Link>
                    ) : (
                      ''
                    ),
                },
                {
                  title: language.formatMessage({ id: 'from_name' }),
                  dataIndex: 'fromUser',
                  render: (user) =>
                    user ? (
                      <Link
                        to={{
                          pathname: `/view/user/${user._id}`,
                        }}
                      >
                        {user.fullname}
                      </Link>
                    ) : (
                      ''
                    ),
                },
                {
                  title: language.formatMessage({ id: 'price' }),
                  dataIndex: 'price',
                  render: (price) => formatNumber(price, '֏'),
                },
                {
                  title: language.formatMessage({ id: 'description' }),
                  dataIndex: 'description',
                  render: (text) => (
                    <div
                      style={{
                        height: 60,
                        overflowY: 'auto',
                      }}
                    >
                      {text}
                    </div>
                  ),
                  width: 300,
                },
                {
                  title: language.formatMessage({ id: 'expense_created_at' }),
                  dataIndex: 'created',
                  render: (text, record) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {getDate(text, true)}
                      {hasPermission('expense_archive') && (
                        <DeleteOutlined
                          style={{
                            color: 'red',
                            cursor: 'pointer',
                            padding: 5,
                          }}
                          onClick={() => archiveExpense(record._id)}
                        />
                      )}
                    </div>
                  ),
                },
              ]}
              pagination={{
                defaultPageSize: 9,
                total: expenses.length,
                position: ['bottomCenter'],
              }}
              dataSource={expenses}
              loading={loading}
            />
          </div>
        )}
      </div>
    </SContent>
  )
}
