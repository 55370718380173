import React, { useState, useEffect } from 'react'
import {Select, Form, Button, Row, Modal, message} from 'antd'
import { Link } from 'react-router-dom'
import api from '../../api'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLoading } from '../helper/Hooks'
import {getError} from "../helper/helper";
const { Option } = Select
const { confirm } = Modal

export default function UserGroups({ userId, group }) {
  const language = useIntl()
  const [loading, setLoading] = useState(false)
  const [managers, setManagers] = useState([])
  const {
    loading: managersLoading,
    startLoading: startManagerLoading,
  } = useLoading()
  const [groups, setGroups] = useState([])
  const {
    loading: groupsLoading,
    startLoading: startGroupLoading,
  } = useLoading()
  const [currentGroup, setCurrentGroup] = useState(group)
  const [form] = Form.useForm()

  useEffect(() => {
    fetchManagers()
  }, [group])

  const fetchManagers = () => {
    startManagerLoading(async () => {
      const response = await api.get(`manager/`)
      const managers = response.data.result
      setManagers(managers)
      if (group) {
        await onManagerChange(group.manager)
        form.setFieldsValue({ manager: group.manager, group: group.id })
      }
    })
  }

  const onManagerChange = async (id) => {
    // todo call manager groups
    await startGroupLoading(async () => {
      const response = await api.get(`managerGroup/manager?manager=${id}`)
      const groups = response.data.result
      setGroups(groups)
      form.setFieldsValue({ group: undefined })
    })
  }

  const onGroupUpdate = async (values) => {
    try {
      const isGroupChanged = group && values.group === currentGroup.id
      if (isGroupChanged) return
      const data = {user: userId, ...values}
      setLoading(true)
      await api.post('managerGroup/updateGroup', data)
      setCurrentGroup({id: values.group, manager: values.manager})
    } catch (e) {
      message.error(getError(e))
    } finally {
      setLoading(false)
    }
  }

  const unsetGroup = () => {
    confirm({
      title: language.formatMessage({id: 'remove_user_group'}),
      maskClosable: true,
      async onOk() {
        const data = { user: userId }
        await api.post('managerGroup/updateGroup', data)
        form.setFieldsValue({ manager: undefined, group: undefined })
        setCurrentGroup(undefined)
      },
    })
  }

  return (
    <Form form={form} onFinish={onGroupUpdate}>
      <Form.Item
        name='manager'
        rules={[
          {
            required: true,
            message: language.formatMessage({ id: 'select_manager' }),
          },
        ]}
      >
        <Select
          placeholder={<FormattedMessage id='select_manager' />}
          onChange={onManagerChange}
          loading={managersLoading}
        >
          {managers.map((user) => (
            <Option key={user._id} value={user._id}>
              {user.fullname}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name='group'
        rules={[
          {
            required: true,
            message: language.formatMessage({ id: 'select_group' }),
          },
        ]}
      >
        <Select
          placeholder={<FormattedMessage id='select_group' />}
          loading={groupsLoading}
        >
          {groups.map((group) => (
            <Option key={group._id} value={group._id}>
              {group.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Row>
          <Button type='primary' htmlType='submit' loading={loading}>
            <FormattedMessage id='save' />
          </Button>
          <Button style={{ marginLeft: 10 }} type='danger' onClick={unsetGroup}>
            <FormattedMessage id='remove_from_group' />
          </Button>
        </Row>
      </Form.Item>
    </Form>
  )
}
