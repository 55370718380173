import React, {useEffect, useState} from 'react'
import Api from '../../api'
import SBContent from '../helper/SidebarContent/'
import {Alert, Button, Col, Form, Input, Row, Select} from 'antd'
import {useHistory, useParams} from 'react-router-dom'
import GroupUsers from './GroupUsersCreate'
import {FormattedMessage, useIntl} from 'react-intl'
import {useLoading} from "../helper/Hooks";

let users = []

function GroupCreate() {
  let history = useHistory()
  const { manager } = useParams()
  const [errorToSave, setErrorToSave] = useState(undefined)
  const language = useIntl()
  const {loading, startLoading} = useLoading(false)
  const [managerName, setManagerName] = useState('')


  useEffect(() => {
    getManagerName()
  }, [])

  const getManagerName = async () => {
    const response = await Api.get(`/user/withPerms/${manager}`)
    setManagerName(response.data.result.fullname)
  }
  const handleFinish = (values) => {
    startLoading(async () => {
      try {
        await Api.post('/clientGroup', {
          ...values,
          manager: manager,
          clients: users,
        })
        history.goBack()
      } catch (ex) {
        setErrorToSave(ex.response.data.message)
      }
    })
  }

  return (
    <SBContent title={`${language.formatMessage({id:'create_group'})} (${managerName})`}>
      {errorToSave && <Alert message={errorToSave} type='error' />}
      <Row>
        <Col span={4}>
          <Form onFinish={handleFinish}>
            <Form.Item
              name='name'
              rules={[
                {
                  required: true,
                  message: language.formatMessage({ id: 'fill_group_name' }),
                },
              ]}
            >
              <Input placeholder={language.formatMessage({ id: 'name' })} />
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit' loading={loading}>
                <FormattedMessage id='create' />
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={6} offset={1}>
          <GroupUsers onUserAdd={(selectedUsers) => (users = selectedUsers)} />
        </Col>
      </Row>
    </SBContent>
  )
}

export default GroupCreate
