import React, {useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import {useIntl} from 'react-intl'
import TotalCard from './TotalCard'
import moment from 'moment'
import {Spin} from 'antd'
import styles from './statistics.module.css'
import {formatNumber} from "../helper/Integer/IntegerFormatter";

export default function ProfitTransactionChart({
  dataSet,
  loading,
}) {
  const lang = useIntl()
  const [data, setData] = useState({
    profit: { data: [], total: 0 },
    transaction: { data: [], total: 0 },
    expense: { data: [], total: 0 },
    balance: { currency: '', currency_text: '' },
  })

  useEffect(() => {
    if (dataSet) {
      parseData()
    }
  }, [dataSet])

  const parseData = () => {
    const {
      balance,
      items,
      totalProfit,
      transactionTotal,
      totalExpense,
    } = dataSet
    const { profit, transaction, expense } = items.reduce(
      (data, el) => {
        data.profit.push([el.date, el.profit])
        data.transaction.push([el.date, el.transaction])
        data.expense.push([el.date, el.expense])
        return data
      },
      { profit: [], transaction: [], expense: [] }
    )
    setData({
      profit: { data: profit, total: totalProfit },
      transaction: { data: transaction, total: transactionTotal },
      expense: { data: expense, total: totalExpense },
      balance: balance,
    })
  }

  const dataSeries = [
    {
      name: lang.formatMessage({id: 'profits'}),
      data: data.profit.data,
    },
    {
      name: lang.formatMessage({id: 'transactions'}),
      data: data.transaction.data,
    },
    {
      name: lang.formatMessage({id: 'expenses'}),
      data: data.expense.data,
    },
  ]

  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    title: {
      text: lang.formatMessage({id: 'profits_transactions_date'}),
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      type: 'category',
      tickPlacement: 'on',
      labels: {
        showDuplicates: false,
        formatter: (val) => {
          let currentYear = moment().year()
          let itemTime = moment(val)
          return itemTime.year() !== currentYear ? itemTime.format('MMM \'YY'): itemTime.format('MMM')
        },
      },
    },
    legend: {
      position: 'top',
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return formatNumber(value, data.balance.currency_text)
        },
      },
    },
  }

  return (
    <Spin spinning={loading}>
      <TotalCard
        totalProfit={data.profit.total}
        totalTransactions={data.transaction.total}
        totalExpense={data.expense.total}
        balance={data.balance}
        className={styles.totalsCard}
      />
      <ReactApexChart
        className={styles.statisticsCharts}
        options={options}
        series={dataSeries}
        type='line'
        height={300}
      />
    </Spin>
  )
}
