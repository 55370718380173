import React, { useEffect, useState } from "react"
import { useLoading, useQuery } from "../helper/Hooks"
import moment from "moment"
import { Select } from "antd"
import API from "../../api"
import SBContent from "../helper/SidebarContent/"
import CategoryTransactionTable from "../statistics/tables/CategoryTransactionTable"
import CategoryChart from "../statistics/CategoryChart"
import styles from "./dashboardCategories.module.scss"
import SearchDropdown from "../helper/searchDropdown/SearchDropdown"
import DateRangeFilter from "../helper/DateRangeFilter"
import { useIntl } from "react-intl"

const { Option } = Select

export default function DashboardCategories() {
  const lang = useIntl()
  const manager = useQuery("manager")
  const time = useQuery("month")
  const [dateRange, setDateRange] = useState({
    start: "",
    end: "",
  })

  const [categories, setCategories] = useState([])
  const { loading: categoriesLoading, startLoading: startCategoryLoading } =
    useLoading()

  const [groups, setGroups] = useState([])
  const { loading: groupLoading, startLoading: startGroupLoading } =
    useLoading()

  const [filter, setFilter] = useState({
    managerId: manager ? manager : "",
    groupId: "",
    categoryId: "",
  })

  const [data, setData] = useState()
  const { loading: dataLoading, startLoading: startDataLoading } = useLoading()

  useEffect(() => {
    if (time) {
      const [month, year] = time.split("_")
      setDateRange({
        start: moment().month(month).year(year).startOf("month").valueOf(),
        end: moment().month(month).year(year).endOf("month").valueOf(),
      })
    } else {
      setDateRange({
        start: moment().startOf("month").valueOf(),
        end: moment().endOf("month").valueOf(),
      })
    }

    getCategories()
  }, [])

  useEffect(() => {
    if (dateRange.start && dateRange.end) {
      getData()
    }
  }, [dateRange, filter])

  useEffect(() => {
    if (filter.managerId) {
      getGroups()
    }
  }, [filter.managerId])

  const getCategories = () => {
    startCategoryLoading(async () => {
      const response = await API.get("/category")
      setCategories(response.data.result.data)
    })
  }
  const getGroups = () => {
    startGroupLoading(async () => {
      const response = await API.get(
        `/managerGroup/manager?manager=${filter.managerId}`
      )
      setGroups(response.data.result)
    })
  }

  const getData = () => {
    startDataLoading(async () => {
      const response = await API.get(
        `/statistics/profitwithTransactions/category?start=${
          dateRange.start
        }&end=${dateRange.end}&manager=${
          filter.managerId ? filter.managerId : ""
        }&group=${filter.groupId ? filter.groupId : ""}&category=${
          filter.categoryId ? filter.categoryId : ""
        }`
      )
      setData(response.data.result)
    })
  }

  return (
    <SBContent title={lang.formatMessage({ id: "category_statistics" })}>
      <div className={styles.filterContainer}>
        <DateRangeFilter
          className={styles.dateFilter}
          onChange={setDateRange}
          defaultValue={
            time ? moment().month(time).format("MMMM") : moment().format("MMMM")
          }
          intervals={[6, 12]}
        />
        <SearchDropdown
          placeholder={lang.formatMessage({ id: "select_manager" })}
          url="/user/v2?roles=manager&blocked=false"
          searchUrl="/user/v2?roles=manager&blocked=false&name="
          onSelect={(selectedValue) =>
            setFilter({ ...filter, managerId: selectedValue })
          }
          renderItem={(item) => ({
            key: item._id,
            value: item._id,
            name: item.fullname,
          })}
          className={styles.filter}
        />
        <Select
          allowClear={true}
          loading={groupLoading}
          className={styles.filter}
          placeholder={lang.formatMessage({ id: "select_group" })}
          onChange={(e) => {
            setFilter({ ...filter, groupId: e })
          }}
          disabled={!filter.managerId}
        >
          {groups.map((group) => (
            <Option key={group._id} value={group._id}>
              {group.name}
            </Option>
          ))}
        </Select>
        <Select
          allowClear={true}
          loading={categoriesLoading}
          className={styles.filter}
          placeholder={lang.formatMessage({ id: "select_category" })}
          showSearch
          onChange={(e) => {
            setFilter({ ...filter, categoryId: e })
          }}
          optionFilterProp="children"
          filterOption={(input, option) => {
            if (!option.children) {
              return true
            }
            return option.children.toLowerCase().includes(input.toLowerCase())
          }}
        >
          {categories.map((category) => (
            <Option key={category._id} value={category._id}>
              {category.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.dataContainer}>
        <CategoryTransactionTable
          dataSet={data}
          loading={dataLoading}
          managerId={filter.managerId}
          date={moment(dateRange.start).format("MMMM_YYYY")}
        />
        <CategoryChart dataSet={data} loading={dataLoading} />
      </div>
    </SBContent>
  )
}
