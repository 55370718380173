import { Card, Result } from 'antd'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Redirect } from 'react-router'
import API from '../../api'
import { hasPermission } from '../../api/user'
import { useSettings } from '../helper/context/SettingsContext'
import { useLoading } from '../helper/Hooks'
import { IntTextFormatter } from '../helper/Integer/IntegerFormatter'
import SBContent from '../helper/SidebarContent/'

const gridStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: 'none',
  padding: 6,
  marginBottom: 10,
  width: `33%`,
  height: 40,
}
const gridTitleStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxShadow: 'none',
  padding: 6,
  marginBottom: 10,
  width: `33%`,
  height: 40,
}

const doubleGridStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: 'none',
  padding: 6,
  marginBottom: 10,
  width: `45%`,
  height: 40,
}
const doubleGridTitleStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxShadow: 'none',
  padding: 6,
  marginBottom: 10,
  width: `45%`,
  height: 40,
}

const spanStyle = {
  fontSize: '100%',
  fontWeight: 'bold',
}

const Limits = () => {
  const lang = useIntl()
  const [data, setData] = useState({
    managers: 0,
    managerLimit: 0,
    postmen: 0,
    postmenLimit: 0,
    dueDate: moment().startOf('month').add(4, 'day').add(1, 'month'),
    totalPrice: 0,
    hasPaid: true,
  })
  const { loading, startLoading } = useLoading()
  const { limits } = useSettings()
  const canView = useMemo(() => hasPermission('admin_view_subscription'), [])

  useEffect(() => {
    if (!canView) {
      return
    }
    startLoading(async () => {
      const response = await API.get(`/settings/accountInfo`)
      const result = response.data.result
      setData({
        ...data,
        managers: result.managerCount,
        postmen: result.postmanCount,
        totalPrice: result.fee,
        hasPaid: !limits.hasExpired,
        managerLimit: limits.managerLimit,
        postmenLimit: limits.postmanLimit,
      })
    })
  }, [])

  if (!canView && !limits.hasExpired) {
    return <Redirect to={'/'} />
  }

  return canView ? (
    <SBContent title={lang.formatMessage({ id: 'subscription' })}>
      <div style={{ margin: 'auto', width: 650, marginTop: 100 }}>
        <Card style={{ padding: 10 }} loading={loading}>
          <Card.Grid style={gridTitleStyle} hoverable={false}></Card.Grid>
          <Card.Grid style={gridStyle} hoverable={false}>
            <span style={spanStyle}>
              <FormattedMessage id='current' />
            </span>
          </Card.Grid>
          <Card.Grid style={gridStyle} hoverable={false}>
            <span style={spanStyle}>
              <FormattedMessage id='limit' />
            </span>
          </Card.Grid>

          <Card.Grid style={gridTitleStyle} hoverable={false}>
            <span style={spanStyle}>
              <FormattedMessage id='managers' />
            </span>
          </Card.Grid>
          <Card.Grid style={gridStyle} hoverable={false}>
            <span style={spanStyle}>
              <IntTextFormatter number={data.managers} />
            </span>
          </Card.Grid>
          <Card.Grid style={gridStyle} hoverable={false}>
            <span style={spanStyle}>
              <IntTextFormatter number={data.managerLimit} />
            </span>
          </Card.Grid>
          <Card.Grid style={gridTitleStyle} hoverable={false}>
            <span style={spanStyle}>
              <FormattedMessage id='postmen' />
            </span>
          </Card.Grid>
          <Card.Grid style={gridStyle} hoverable={false}>
            <span style={spanStyle}>
              <IntTextFormatter number={data.postmen} />
            </span>
          </Card.Grid>
          <Card.Grid style={gridStyle} hoverable={false}>
            <span style={spanStyle}>
              <IntTextFormatter number={data.postmenLimit} />
            </span>
          </Card.Grid>
          <Card.Grid style={doubleGridTitleStyle} hoverable={false}>
            <span style={spanStyle}>
              <FormattedMessage id='due_date' />
            </span>
          </Card.Grid>
          <Card.Grid
            style={{
              ...doubleGridStyle,
              backgroundColor: data.hasPaid ? '#39DB80' : 'red',
              borderRadius: 13,
            }}
            hoverable={false}
          >
            <span style={spanStyle}>
              {moment(data.dueDate).format('DD-MM-YYYY')}
            </span>
          </Card.Grid>
          <Card.Grid style={doubleGridTitleStyle} hoverable={false}>
            <span style={spanStyle}>
              <FormattedMessage id='total_price' />
            </span>
          </Card.Grid>
          <Card.Grid style={doubleGridStyle} hoverable={false}>
            <span style={spanStyle}>
              <IntTextFormatter number={data.totalPrice} currencyText={'֏'} />
            </span>
          </Card.Grid>
        </Card>
      </div>
    </SBContent>
  ) : (
    <SBContent>
      <Result title={lang.formatMessage({ id: 'app_temp_disabled' })} />
    </SBContent>
  )
}

export default Limits
