import {Skeleton, Tabs} from 'antd'
import moment from 'moment'
import React, {Fragment, useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'
import Api from '../../../api'
import {hasPermission, isManager, isPostman, isSuperAdmin, isUserAdmin, isUserClient} from '../../../api/user'
import {useUser} from '../../helper/context/UserContext'
import {useQuery} from '../../helper/Hooks'
import SBContent from '../../helper/SidebarContent/'
import UserGroups from '../UserGroups'
import OrderNotifications from './OrderNotifications'
import PostmanProducts from './PostmanProducts'
import UserBalanceHistory from './UserBalanceHistory'
import UserClients from './userClients/UserClients'
import UserInfo from './UserInfo/'
import UserOrderHistory from './UserOrderHistory'
import UserPermission from './UserPermission'
import UserProduct from './UserProduct'
import UserProductHistory from './UserProductHistory'
import UserLimits from "./UserLimits";
import DashboardClients from "../../dashboard/clients/DashboardClients";
import DashboardManagers from "../../dashboard/DashboardManagers";
import DashboardPostmen from "../../dashboard/DashboardPostmen";

const { TabPane } = Tabs

const User = props => {
  const lang = useIntl()
  const userId = props.match.params.id
  const { user: loggedInUser } = useUser()

  const queryTab = useQuery('tab')
  const tab = queryTab ? queryTab : 'info'

  const [activeTab, setActiveTab] = useState(tab)
  const [tabKeys, setTabKeys] = useState({
    balanceKey: moment().valueOf(),
    limitsKey: moment().valueOf() + 1000,
    infoKey: moment().valueOf() + 2000,
  })
  const [user, setUser] = useState({ role: {}, balance: {}, location: {} })
  const [userLoading, setUserLoading] = useState(true)

  const history = useHistory()

  useEffect(() => {
    getUser()
  }, [userId])

  const getUser = async () => {
    setUserLoading(true)
    const response = await Api.get(`/user/withPerms/${userId}`)
    setUser(response.data.result)
    setUserLoading(false)
    selectTabIfHas()
  }

  const selectTabIfHas = () => {
    if (tab) {
      onTabChange(tab)
    }
  }

  const onTabChange = tab => {
    updateTabKey(tab)
    setActiveTab(tab)
    history.replace(`/view/user/${userId}?tab=${tab}`)
  }

  const updateTabKey = tab => {
    if (tab === 'balance_history') {
      setTabKeys({...tabKeys, balanceKey: tab + moment().valueOf()})
    }
    if(tab === 'user_limits') {
      setTabKeys({...tabKeys, limitsKey: tab + moment().valueOf()})
    }
    if(tab === 'info') {
      setTabKeys({...tabKeys, infoKey: tab + moment().valueOf()})
    }
  }

  const isClient = isUserClient(user)
  const isAdmin = isUserAdmin(user)

  return (
    <SBContent
      title={`${user.fullname} (${user.username})`}
      avatar={{ src: user.image }}
    >
      <Skeleton loading={userLoading}>
        <Tabs activeKey={activeTab} onChange={onTabChange}>
          <TabPane tab={lang.formatMessage({ id: 'info' })} key='info'>
            <UserInfo
              user={user}
              userLoading={userLoading}
              getUser={getUser}
              isManager={isManager}
              hasPermission={hasPermission}
              isLoggedInUser={userId === loggedInUser._id}
              key={tabKeys.infoKey}
            />
          </TabPane>
          {(isPostman(user) || isManager(user)) && (
            <Fragment>
              <TabPane tab={<FormattedMessage id='products' />} key='products'>
                <PostmanProducts user={userId} loading={userLoading} />
              </TabPane>
              {isManager(user) && (
                <TabPane
                  tab={lang.formatMessage({ id: 'clients' })}
                  key='clients'
                >
                  <UserClients user={userId} />
                </TabPane>
              )}
              {hasPermission('product_history_view') && (
                <TabPane
                  tab={<FormattedMessage id='product_history' />}
                  key='product_history'
                >
                  <UserProductHistory user={user} loading={userLoading} />
                </TabPane>
              )}
            </Fragment>
          )}
          {isClient && hasPermission('client_product_view') && (
            <TabPane tab={<FormattedMessage id='products' />} key='products'>
              <UserProduct
                userId={userId}
                loading={userLoading}
                userDiscount={user.discount}
              />
            </TabPane>
          )}
          {isSuperAdmin() && isAdmin && (
            <TabPane
              tab={<FormattedMessage id='permissions' />}
              key='permission'
            >
              <Skeleton loading={userLoading} active>
                <UserPermission user={user} onUserUpdate={getUser} />
              </Skeleton>
            </TabPane>
          )}
          <TabPane tab={<FormattedMessage id='orders' />} key='orders'>
            <UserOrderHistory user={user} />
          </TabPane>
          {isClient && (
            <TabPane tab={<FormattedMessage id='group' />} key='group'>
              <UserGroups userId={user._id} group={user.group} />
            </TabPane>
          )}
          <TabPane
            tab={<FormattedMessage id='balance_history' />}
            key='balance_history'
          >
            <UserBalanceHistory key={tabKeys.balanceKey} user={user} />
          </TabPane>
          {isAdmin && (
            <TabPane
              tab={lang.formatMessage({ id: 'order_notifications' })}
              key='order_notifications'
            >
              <OrderNotifications userId={userId} />
            </TabPane>
          )}
          {(isClient || isManager(user)) && hasPermission("admin_user_limits") && (
            <TabPane
              tab={lang.formatMessage({ id: 'user_limits' })}
              key='user_limits'
            >
              <UserLimits user={user} key={tabKeys.limitsKey} getUser={getUser}/>
            </TabPane>
          )}
          {hasPermission('statistics') && (
            <TabPane
              tab={lang.formatMessage({ id: 'statistics' })}
              key='statistics'
            >
              {isClient && (
                <DashboardClients clientId={userId} showClientFilter={false} showTitle={false}/>
              )}
              {isManager(user) && (
                <DashboardManagers managerId={userId} showManagerFilter={false} showTitle={false}/>
              )}
              {isPostman(user) && (
                <DashboardPostmen postmanId={userId} showPostmanFilter={false} showTitle={false}/>
              )}
            </TabPane>
          )}
        </Tabs>
      </Skeleton>
    </SBContent>
  )
}

export default User
