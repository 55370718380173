import React from 'react'
import { Skeleton } from 'antd'
import api from '../../../api'
import { useIntl } from 'react-intl'
import { hasPermission, isSuperAdmin } from '../../../api/user'
import Balance from './Balance'

export default function UserCard({ user, loading, isLoggedInUser }) {
  const language = useIntl()

  const onBalanceChange = async (total, amount) => {
    await api.put(`/user/balance/${user._id}`, {
      amount: parseInt(amount) !== 0 ? -1 * amount : 0,
      total: -total + amount,
      updateRole: user.role.name,
    })
  }

  const canChangeBalance = (perm, managerPerm, adminPerm, postmanPerm) =>
    (!isLoggedInUser &&
      ((user.role.name === 'manager' && hasPermission(managerPerm)) ||
        (user.role.name === 'client' && hasPermission(perm)) ||
        (user.role.name === 'admin' && hasPermission(adminPerm)) ||
        (user.role.name === 'postman' && hasPermission(postmanPerm)))) ||
    isSuperAdmin()

  return (
    <Skeleton loading={loading} avatar active>
      <div
        style={{
          width: 360,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Balance
          canDeposit={canChangeBalance(
            'client_balance_plus',
            'manager_balance_plus',
            'admin_balance_plus',
            'postman_balance_plus'
          )}
          canWithdraw={canChangeBalance(
            'client_balance_minus',
            'manager_balance_minus',
            'admin_balance_minus',
            'postman_balance_minus'
          )}
          count={
            parseInt(user.balance.total) !== 0 ? -1 * user.balance.total : 0
          }
          info={{
            name: 'Balance',
            title: 'Balance',
            suffix: user.balance.currency_text,
          }}
          onSubmit={onBalanceChange}
          isLoggedInUser={isLoggedInUser}
        />
        {user.location.address
          ? `${language.formatMessage({ id: 'address' })}: ${
              user.location.address
            }`
          : ''}
      </div>
    </Skeleton>
  )
}
