import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CommentOutlined,
} from '@ant-design/icons'
import { Button, Form, Input, message, Modal, Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import API from '../../api'
import { useLoading } from '../helper/Hooks'
import { formatNumber } from '../helper/Integer/IntegerFormatter'
import SearchDropdown from '../helper/searchDropdown/SearchDropdown'
import SBContent from '../helper/SidebarContent/'
import ClientOrders from './ClientOrders'
import styles from './order.module.scss'
import { hasPermission } from "../../api/user";

const { confirm } = Modal
const { TextArea } = Input

const PendingOrders = () => {
  const [postman, setPostman] = useState({ id: undefined, name: '' })
  const [selectedOrders, setSelectedOrders] = useState([])
  const [selectedOrder, setSelectedOrder] = useState()
  const [filter, setFilter] = useState({
    managerId: undefined,
  })
  const [data, setData] = useState({ totalPrice: 0, totalProfit: 0, totalPaid: 0, orders: [] })
  const { loading, startLoading } = useLoading()
  const { loading: assignLoading, startLoading: startAssignLoading } =
    useLoading()
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const [editCommentForm] = Form.useForm()
  const lang = useIntl()
  const [columns, setColumns] = useState([])

  useEffect(() => {
    getOrders()
  }, [filter])

  const updateOrders = async (data) => {
    await API.post(`delivery/assignOrders`, {
      postman: postman.id,
      products: data.map(({ description, ...rest }) => rest),
      orders: selectedOrders,
    })
    setPostman({ id: undefined, name: '' })
    setData({ totalPrice: 0, totalProfit: 0, totalPaid: 0, orders: [] })
    getOrders()
  }

  const updateOrder = async (orderId, comment) => {
    await API.put('/order/updateComment', { order: orderId, comment: comment })
    getOrders()
  }

  const onCommentEdit = (orderId, comment) => {
    confirm({
      title: lang.formatMessage({ id: 'edit_order_comment' }),
      content: (
        <Form form={editCommentForm} name='editCommentForm' preserve={false}>
          <Form.Item
            name='comment'
            label={`${lang.formatMessage({ id: 'comment' })}:`}
            className={styles.popupTextArea}
            initialValue={comment}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      ),
      async onOk() {
        try {
          await updateOrder(orderId, editCommentForm.getFieldValue('comment'))
        } catch (err) {
          message.error(err.message)
        }
      },
    })
  }

  const getOrders = () => {
    startLoading(async () => {
      const response = await API.get(
        `/order/managerPending?manager=${filter.managerId ? filter.managerId : ''}`
      )
      setData(response.data.result)
      setupTableColumns('֏')
    })
  }

  const setupTableColumns = currency => {
    const columns = [
      {
        title: lang.formatMessage({ id: 'client' }),
        dataIndex: 'client',
        render: (user) =>
          user ? (
            <Link
              to={{
                pathname: `/view/user/${user._id}`,
              }}
            >
              {user.fullname}
            </Link>
          ) : (
            ''
          ),
      },
      {
        title: lang.formatMessage({ id: 'ordered_by' }),
        dataIndex: 'ordered_user',
        render: (user) =>
          user ? (
            <Link
              to={{
                pathname: `/view/user/${user._id}`,
              }}
            >
              {user.fullname}
            </Link>
          ) : (
            ''
          ),
      },
      {
        title: lang.formatMessage({ id: 'total_price' }),
        dataIndex: 'total_price',
        render: (price) => formatNumber(price, '֏'),
      }
    ]
    if (hasPermission('admin_pending_profit')) {
      columns.push(
        {
          title: lang.formatMessage({ id: 'profit' }),
          dataIndex: 'profit',
          render: (profit) => formatNumber(profit, currency)
        }
      )
    }
    columns.push(
      {
        title: lang.formatMessage({ id: 'invoice' }),
        dataIndex: 'order_type',
        render: (invoice) => (
          <div style={{ textAlign: 'center' }}>
            {invoice !== 'STANDARD' ? (
              <CheckCircleOutlined style={{ fontSize: 30 }} />
            ) : (
              <CloseCircleOutlined style={{ fontSize: 30 }} />
            )}
          </div>
        ),
      },
      {
        title: lang.formatMessage({ id: 'date' }),
        dataIndex: 'ordered_date',
        render: (date) => moment(date).format('DD/MM/YYYY HH:mm:ss'),
      },
      {
        title: lang.formatMessage({ id: 'comment' }),
        dataIndex: 'comment',
        render: (text, record) => (
          <div
            style={{ display: 'flex', justifyContent: 'space-around' }}
          >
            <div
              style={{
                height: 30,
                overflowY: 'auto',
              }}
            >
              {text ? text : '-'}
            </div>
            <div>
              <CommentOutlined
                style={{ cursor: 'pointer', fontSize: 20 }}
                onClick={(e) => {
                  e.stopPropagation()
                  onCommentEdit(record._id, text)
                }}
              />
            </div>
          </div>
        ),
      })
    setColumns(columns)
  }

  const onAssign = (data) => {
    confirm({
      title: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            {lang.formatMessage({ id: 'assign_to_postman_question' })}
          </span>
          {data.missedProducts.length !== 0 && (
            <Button
              type={'primary'}
              onClick={handlePrint}
            >{`${lang.formatMessage({ id: 'print' })}`}</Button>
          )}
        </div>
      ),
      content: (
        <div className={styles.orderPostmanChangeContainer} ref={componentRef}>
          <div className={styles.postmanInfo}>
            <span>{postman.name}</span>
            <span>{moment().format('YYYY-MM-DD HH:mm:ss')}</span>
          </div>
          <br />
          {data.missedProducts &&
            data.missedProducts.map((product) => (
              <span className={styles.addProductSpan}>
                {product.description}: {product.total}
              </span>
            ))}
          {data.missedProducts.length === 0 && (
            <span className={styles.addProductSpan}>
              {lang.formatMessage({ id: 'postman_no_need_products' })}
            </span>
          )}
          <span className={styles.onHand}>
            {lang.formatMessage({ id: 'on_hand_after_assign' })}
          </span>
          {data.onHandProducts &&
            data.onHandProducts.map((product) => (
              <span className={styles.addProductSpan}>
                {product.description}: {product.total}
              </span>
            ))}
          {data.missedProducts.length === 0 && (
            <span className={styles.addProductSpan}>-</span>
          )}
        </div>
      ),
      okText: lang.formatMessage({ id: 'add_assign' }),
      cancelText: lang.formatMessage({ id: 'cancel' }),
      async onOk() {
        try {
          await updateOrders(data.missedProducts)
        } catch (err) {
          message.error(err.message)
        }
      },
      width: 550,
    })
  }

  const canPickUp = () => {
    startAssignLoading(async () => {
      const response = await API.post('/order/canPickUp', {
        postman: postman.id,
        orders: selectedOrders,
      })
      onAssign(response.data.result)
    })
  }

  return (
    <SBContent title={lang.formatMessage({ id: 'pending_orders' })}>
      <div className={styles.orderContainer}>
        <div className={styles.orderFilterContainer}>
          <SearchDropdown
            value={postman.id}
            placeholder={lang.formatMessage({ id: 'select_postman' })}
            style={{ marginBottom: 10 }}
            width={200}
            url='/user/v2?roles=postman&blocked=false'
            searchUrl='/user/v2?roles=postman&blocked=false&name='
            onSelect={(selectedValue, option) => {
              setPostman(
                selectedValue
                  ? { id: selectedValue, name: option.children }
                  : { id: undefined, name: '' }
              )
            }}
            renderItem={(item) => ({
              key: item._id,
              value: item._id,
              name: item.fullname,
            })}
          />
          <Button
            type='primary'
            disabled={!(postman.id && selectedOrders.length !== 0)}
            onClick={canPickUp}
          >
            <FormattedMessage id={'assign_to_postman'} />
          </Button>
          <SearchDropdown
            value={filter.managerId}
            placeholder={lang.formatMessage({ id: 'select_manager' })}
            style={{ marginBottom: 10 }}
            width={200}
            url='/user/v2?roles=manager&blocked=false'
            searchUrl='/user/v2?roles=manager&blocked=false&name='
            onSelect={(selectedValue) => {
              setFilter({
                ...filter,
                managerId: selectedValue,
              })
            }}
            renderItem={(item) => ({
              key: item._id,
              value: item._id,
              name: item.fullname,
            })}
          />
        </div>
        <div>
          <Table
            title={() => (
              <div className={styles.deliveredOrdersTotals}>
                <div>
                  <strong>
                    <FormattedMessage id={'total_price'} />:{' '}
                    {formatNumber(data.totalPrice, '֏')}
                  </strong>
                  <strong>
                    <FormattedMessage id={'total_profit'} />:{' '}
                    {formatNumber(data.totalProfit, '֏')}
                  </strong>
                </div>
              </div>
            )}
            key={filter.managerId}
            showSorterTooltip={false}
            bordered={true}
            loading={loading}
            rowSelection={{
              preserveSelectedRowKeys: true,
              selectedRowKeys: selectedOrders,
              onChange: (selectedRowKeys) => {
                setSelectedOrders(selectedRowKeys)
              },
            }}
            columns={columns}
            dataSource={data.orders}
            rowKey={(element) => element._id}
            onRow={(element) => {
              return {
                onClick: () => {
                  setSelectedOrder(element)
                },
              }
            }}
            rowClassName={styles.rowHoverPointer}
            pagination={{
              defaultPageSize: 10,
              total: data.orders.length,
              position: ['bottomCenter'],
            }}
          />
        </div>
        <ClientOrders
          visible={selectedOrder}
          selectedOrder={selectedOrder}
          onCancel={(shouldRefresh) => {
            setSelectedOrder(undefined)
            if (shouldRefresh) {
              setPostman({ id: undefined, name: '' })
              getOrders()
            }
          }}
        />
      </div>
    </SBContent>
  )
}

export default PendingOrders
