import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { message } from 'antd'

export function useQuery(key) {
  return new URLSearchParams(useLocation().search).get(key)
}

export const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = url
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

export function useLoading(initialValue = false) {
  const [loading, setLoading] = useState(initialValue)
  const startLoading = async (block) => {
    try {
      setLoading(true)
      await block()
    } finally {
      setLoading(false)
    }
  }
  return { loading, startLoading }
}
