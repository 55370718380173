import React, { useState } from "react"
import { Button, message, Modal, Select } from "antd"
import { FormattedMessage, useIntl } from "react-intl"
import API from "../../../../api"
import ProductGroupPriceModal from "./ProductGroupPriceModal"

const ProductGroupPrice = ({ product, style }) => {
  const lang = useIntl()
  const [modalOpened, setModalOpened] = useState()

  const changePrice = async ({ price, group }) => {
    const requestData = {
      group: group,
      price: price,
      product: product,
    }

    try {
      await API.post("/managerGroup/product-price", requestData)
      message.success(lang.formatMessage({ id: "group_price_udpate_succes" }))
      setModalOpened(false)
    } catch (e) {
      message.error(lang.formatMessage({ id: "group_price_udpate_error" }))
    }
  }

  return (
    <>
      <Button style={style} type="primary" onClick={() => setModalOpened(true)}>
        <FormattedMessage id="set_group_price" />
      </Button>
      <ProductGroupPriceModal
        visible={modalOpened}
        onCancel={() => setModalOpened(false)}
        onConfirm={changePrice}
      />
    </>
  )
}

export default ProductGroupPrice
