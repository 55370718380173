import React, { useState, useContext } from 'react'

import MakeOrderProductsContext from '../component/helper/context/MakeOrderProductsContext'

const withMakeOrderBundle = (Component) => ({ ...props }) => {
  const [bundle, setBundle] = useState({
    products: {},
    total: 0,
  })
  const [paidAmount, setPaidAmount] = useState(0)

  const updateBundle = (product) => {
    const item = bundle.products[product._id]
    if (!product.count) {
      const bundleCopy = { ...bundle }
      const price = item ? item.price : 0
      const count = item ? item.count : 0
      bundleCopy.total -= price * count
      delete bundleCopy.products[product._id]
      setBundle(bundleCopy)
    } else {
      const count = item ? product.count - item.count : product.count
      const total = bundle.total + count * product.price
      setBundle({
        products: { ...bundle.products, [product._id]: product },
        total: total,
      })
    }
  }

  return (
    <MakeOrderProductsContext.Provider
      value={{
        bundle: bundle.products,
        bundleTotal: bundle.total,
        paidAmount: paidAmount,
        updateBundle,
        updatePaidAmount: setPaidAmount,
      }}
    >
      <Component {...props} />
    </MakeOrderProductsContext.Provider>
  )
}

export const useBundle = () => {
  return useContext(MakeOrderProductsContext)
}

export default withMakeOrderBundle
