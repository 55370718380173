import { useState, useEffect } from 'react'
import { useLoading } from '../Hooks'
import API from '../../../api'

export default function useSearchDropdownUseCase(url, processResult, enabled) {
  const [data, setData] = useState({
    data: [],
    nextPage: '',
    hasMore: false,
  })
  const { loading, startLoading } = useLoading()

  useEffect(() => {
    if (enabled) {
      getItems(url, false)
    }
  }, [enabled, url])

  const getItems = async (url, shouldAppend = true) => {
    startLoading(async () => {
      const response = await API.get(url)
      if (shouldAppend) {
        appendData(
          processResult(response.data.result),
          response.data.nextPageUrl
        )
      } else {
        updateData(
          processResult(response.data.result),
          response.data.nextPageUrl
        )
      }
    })
  }

  const appendData = (newData, nextPageUrl) => {
    setData({
      data: [...data.data, ...newData],
      nextPage: nextPageUrl,
      hasMore: newData.length !== 0 && nextPageUrl,
    })
  }

  const updateData = (newData, nextPageUrl) => {
    setData({
      data: [...newData],
      nextPage: nextPageUrl,
      hasMore: newData.length >= 10,
    })
  }

  return { data, loading, getItems }
}
