import React from 'react'
import { Button, Modal } from 'antd'

const { confirm } = Modal

const ConfirmButton = ({
  onClick,
  confirmTitle,
  confirmContent = '',
  ...props
}) => {
  const onConfirm = () => {
    confirm({
      title: confirmTitle,
      content: confirmContent,
      async onOk () {
        await onClick()
      }
    })
  }

  return (
    <Button {...props} onClick={onConfirm}>
      {props.children}
    </Button>
  )
}

export default ConfirmButton
