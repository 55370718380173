import React, {useState} from 'react'
import InfiniteScrollWrapper from '../helper/InfiniteScroll'
import moment from 'moment'
import {isManager, isPostman} from '../../api/user'
import SearchDropdown from '../helper/searchDropdown/SearchDropdown'
import styles from './product.module.scss'
import DateRangeFilter from "../helper/DateRangeFilter";
import ProductHistoryListItem from "./ProductHistoryListItem";
import {useIntl} from "react-intl";
import {Select} from "antd";

const productHistoryItemTypes = [
  'warehouse_count',
  'warehouse_price',
  'admin_make_order',
  'admin_postman_order',
  'admin_user_product',
  'admin_user_returned_product',
  'admin_assign_postman',
  'manager_make_order',
  'manager_postman_order',
  'manager_return_order',
  'postman_finish_order',
  'archive_pending_order',
  'archive_picked_order',
  'archive_finished_order',
  'archive_returned_order'
]

const {Option} = Select
const ProductHistoryList = ({currentProduct, user}) => {
  const lang = useIntl()
  const [filter, setFilter] = useState({
    user: user ? user._id : '',
    filterUser: '',
    product: currentProduct ? currentProduct : '',
    type: undefined,
    start: moment().startOf('month').valueOf(),
    end: moment().valueOf(),
  })

  const userFilterUrl = user
    ? `/user/v2?roles=${
      isManager(user)
        ? 'client,admin,superadmin'
        : isPostman(user)
        ? 'admin,superadmin'
        : ''
    }&blocked=false`
    : '/user?'

  const onDateChange = ({start, end}) => {
    setFilter({
      ...filter,
      start: start,
      end: end
    })
  }

  return (
    <div>
      <div className={styles.filterContainer}>
        <DateRangeFilter className={styles.dateFilter}
                         onChange={onDateChange}
                         placeholder={lang.formatMessage({id: 'time'})}
                         defaultValue={moment().format('MMMM')}
                         intervals={[3, 6, 12]}/>
        <SearchDropdown
          placeholder={
            user
              ? isManager(user)
              ? lang.formatMessage({id: 'to'})
              : isPostman(user)
                ? lang.formatMessage({id: 'from'})
                : ''
              : lang.formatMessage({id: 'from'})
          }
          className={styles.filter}
          url={userFilterUrl}
          searchUrl={`${userFilterUrl}&name=`}
          onSelect={(selectedValue) => {
            setFilter({
              ...filter,
              [user ? 'filterUser' : 'user']: selectedValue ? selectedValue : '',
            })
          }}
          renderItem={(item) => ({
            key: item._id,
            value: item._id,
            name: item.fullname,
          })}
        />
        {!currentProduct && (
          <SearchDropdown
            placeholder={lang.formatMessage({id: 'product'})}
            className={styles.filter}
            url={'product/getAll'}
            onSelect={(selectedValue) =>
              setFilter({
                ...filter,
                product: selectedValue ? selectedValue : '',
              })
            }
            renderItem={(item) => ({
              key: item._id,
              value: item._id,
              name: `(${item.product_id}) ${item.description}`,
            })}
            processResult={(response) => response.products}
          />
        )}
        <Select
          placeholder={lang.formatMessage({id: 'type'})}
          allowClear={true}
          className={styles.filter}
          showSearch
          optionFilterProp='children'
          filterOption={(input, option) => {
            if (!option.children) {
              return true
            }
            return option.children.toLowerCase().includes(input.toLowerCase())
          }}
          onChange={(e) => {
            setFilter({
              ...filter,
              type: e
            })
          }}
        >
          {productHistoryItemTypes.map((item) => (
            <Option key={item} value={item}>
              {lang.formatMessage({id: item})}
            </Option>
          ))}
        </Select>
      </div>
      <InfiniteScrollWrapper
        url={`/product/history/${filter.product ? filter.product : 'non'}?user=${filter.user ? filter.user : ''}&filterUser=${filter.filterUser ? filter.filterUser : ''}&start=${filter.start}&end=${filter.end}&type=${filter.type ? filter.type : ''}`}
        render={(item) => (
          <ProductHistoryListItem item={item} currentProduct={currentProduct}/>
        )}
      />
    </div>
  )
}

export default ProductHistoryList
