import React from 'react'
import InputConfirm from '../../helper/InputConfirm'
import { message } from 'antd'
import { useIntl } from 'react-intl'
import API from '../../../api'

const ProductPriceLimits = ({ productId }) => {
  const lang = useIntl()

  const setProductMinPrice = async (value) =>
    await API.post(`/product/set-min-price`, { id: productId, price: value })

  const setProductMaxPrice = async (value) =>
    await API.post(`/product/set-max-price`, { id: productId, price: value })

  const changePriceBy = async (value) =>
    await API.put(`/product/change-price-by/`, {
      id: productId,
      updatePrice: value,
    })

  return (
    <div>
      <InputConfirm
        onConfirm={(value) => changePriceBy(value)}
        onError={(error) => {
          if (error.response) {
            message.error(error.response.data.message)
          } else {
            message.error(lang.formatMessage({ id: 'something_wrong' }))
          }
        }}
        onSuccess={() => {
          message.success(
            lang.formatMessage({ id: 'product_value_changed_by_success' })
          )
        }}
        buttonTitle={lang.formatMessage({ id: 'change_by' })}
        inputPlaceholder={lang.formatMessage({
          id: 'increase_or_decrease_product_price',
        })}
        onConfirmDescription={lang.formatMessage({
          id: 'product_price_change_by_description',
        })}
        onConfirmTitle={lang.formatMessage({
          id: 'product_price_change_by_title',
        })}
        width={200}
      />
      <InputConfirm
        onConfirm={(value) => setProductMinPrice(value)}
        onError={(error) => {
          if (error.response) {
            message.error(error.response.data.message)
          } else {
            message.error(
              lang.formatMessage({ id: 'product_min_price_update_error' })
            )
          }
        }}
        onSuccess={() => {
          message.success(
            lang.formatMessage({ id: 'product_min_price_update_success' })
          )
        }}
        buttonTitle={lang.formatMessage({ id: 'set_min_price' })}
        inputPlaceholder={lang.formatMessage({ id: 'product_min_price' })}
        onConfirmDescription={lang.formatMessage({
          id: 'product_min_price_update_desc',
        })}
        onConfirmTitle={lang.formatMessage({
          id: 'product_min_price_update_title',
        })}
        width={200}
      />
      <InputConfirm
        onConfirm={(value) => setProductMaxPrice(value)}
        onError={(error) => {
          if (error.response) {
            message.error(error.response.data.message)
          } else {
            message.error(
              lang.formatMessage({ id: 'product_max_price_update_error' })
            )
          }
        }}
        onSuccess={() => {
          message.success(
            lang.formatMessage({ id: 'product_max_price_update_success' })
          )
        }}
        buttonTitle={lang.formatMessage({ id: 'set_max_price' })}
        inputPlaceholder={lang.formatMessage({ id: 'product_max_price' })}
        onConfirmDescription={lang.formatMessage({
          id: 'product_max_price_update_desc',
        })}
        onConfirmTitle={lang.formatMessage({
          id: 'product_max_price_update_title',
        })}
        width={200}
      />
    </div>
  )
}

export default ProductPriceLimits
