import React from 'react'
import SContent from '../helper/SidebarContent/'
import ProductHistoryList from './ProductHistoryList'

export default function ProductHistory() {
  return (
    <SContent title='Products history'>
      <div style={{ width: 670 }}>
        {/* Here lies the grave of search */}
        <ProductHistoryList />
      </div>
    </SContent>
  )
}
