import {Button, Form, Input, Modal, Select, Table} from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {Link, useHistory} from 'react-router-dom'
import {useReactToPrint} from 'react-to-print'
import { useSettings } from '../../../helper/context/SettingsContext'
import styles from '../info.module.scss'
import {useUserClients} from './UserClientUseCase'

const {Option} = Select

export default function UserClients({user}) {
  const {
    groups,
    selectedGroup,
    setSelectedGroup,
    clients,
    totals,
    loading,
    editGroupVisibility,
    setEditGroupVisibility,
    editGroupForm,
    isGroupUpdating,
    updateGroup,
    removeGroup,
    getGroupName,
    managers,
    managersLoading,
    setSelectedUser,
    selectedUser,
    changeManagerVisibility,
    setChangeManagerVisibility,
    newManager,
    setNewManager,
    changeManager
  } = useUserClients(user)
  const [columns, setColumns] = useState([])
  const settings = useSettings()
  const history = useHistory()
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
  const lang = useIntl()

  useEffect(()=> {
    if(clients.length > 0) {
      const cols =  [

        {
          title: lang.formatMessage({id: 'name'}),
          dataIndex: 'fullname',
          render: (fullname, user) => (
            <Link
              to={{
                pathname: `/view/user/${user._id}`
              }}
            >
              {fullname}
            </Link>
          ),
          sorter: (a, b) => a.fullname.localeCompare(b.fullname),
          sortDirections: ['descend', 'ascend']
        },
        {
          title: lang.formatMessage({id: 'balance'}),
          dataIndex: 'balance',
          width: 150,
          render: balance => `${-balance.total}${balance.currency_text}`,
          sorter: (a, b) => a.balance.total - b.balance.total,
          sortDirections: ['descend', 'ascend']
        },
        {
          title: lang.formatMessage({id: 'last_order_date'}),
          width: 150,
          dataIndex: 'latest_order_date',
          render: lastTime =>
            lastTime
              ? moment(lastTime).format('DD-MM-YYYY HH:mm')
              : 'UNAVAILABLE',
          sorter: (a, b) => {
            if (!a.latest_order_date) {
              return -1
            } else if (!b.latest_order_date) {
              return 1
            }
            return a.latest_order_date - b.latest_order_date
          },
          sortDirections: ['descend', 'ascend']
        },
        {
          title: lang.formatMessage({id: 'checkout_date'}),
          width: 150,
          dataIndex: 'checkoutDate',
          render: checkoutDate =>
            checkoutDate
              ? moment(checkoutDate).format('DD-MM-YYYY HH:mm')
              : '-',
          sorter: (a, b) => {
            if (!a.checkoutDate) {
              return -1
            } else if (!b.checkoutDate) {
              return 1
            }
            return a.checkoutDate - b.checkoutDate
          },
          sortDirections: ['descend', 'ascend']
        },
        {
          title: lang.formatMessage({id: 'checkout_status'}),
          width: 150,
          dataIndex: 'checkoutStatus',
          render: status =>
            status ? lang.formatMessage({id: status}) : '-',
          sorter: (a, b) =>
            a.checkoutStatus.localeCompare(b.checkoutStatus),
          sortDirections: ['descend', 'ascend']
        },
      ]
      
      if (settings.calculateClientRank) {
        cols.push(
          {
            title: lang.formatMessage({id: 'rank'}),
            width: 150,
            dataIndex: 'rank',
            render: rank =>
            (rank && rank !== "unknown") ? lang.formatMessage({id: rank}) : '-',
            sorter: (a, b) =>
              a.checkoutStatus.localeCompare(b.checkoutStatus),
            sortDirections: ['descend', 'ascend']
          }
        )
      }
      setColumns(cols)
    }
  }, [clients])

  return (
    <>
      <div style={{paddingLeft: 20, paddingRight: 20}}>
        {!user && (
          <Select
            loading={managersLoading}
            allowClear={false}
            style={{width: 200, marginRight: 20}}
            placeholder={lang.formatMessage({id: 'select_manager'})}
            onChange={e => {
              setSelectedUser(e)
            }}
            value={selectedUser}
          >
            {managers.map(manager => (
              <Option key={manager._id} value={manager._id}>
                {manager.name}
              </Option>
            ))}
          </Select>
        )}
        <Select
          loading={loading}
          allowClear={false}
          style={{width: 200, marginRight: 20}}
          placeholder={lang.formatMessage({id: 'select_group'})}
          onChange={e => {
            setSelectedGroup(e)
          }}
          value={selectedGroup}
        >
          {groups.map(group => (
            <Option key={group._id} value={group._id}>
              {group.name}
            </Option>
          ))}
        </Select>
        <Button onClick={handlePrint} type='primary' style={{marginRight: 20}}>
          <FormattedMessage id='print'/>
        </Button>
        <Button
          type='primary'
          onClick={() => {
            history.push(`/create/group/${user ? user: selectedUser}`)
          }}
          style={{marginRight: 20}}
          disabled={!selectedUser}
        >
          <FormattedMessage id='create_group'/>
        </Button>
        <Button
          onClick={() => setEditGroupVisibility(true)}
          type='primary'
          style={{marginRight: 20}}
          disabled={!selectedGroup}
        >
          <FormattedMessage id='edit_group'/>
        </Button>
        <Button
          style={{marginRight: 20}}
          type='primary'
          onClick={() => {
            setChangeManagerVisibility(true)
          }}
          disabled={!selectedGroup}
        >
          <FormattedMessage id='change_manager'/>
        </Button>
        <Button
          type='primary'
          onClick={() => {
            removeGroup()
          }}
          danger
          disabled={!selectedGroup}
        >
          <FormattedMessage id='remove_group'/>
        </Button>
      </div>
      <div
        id='list'
        style={{marginTop: 20, paddingLeft: 20, paddingRight: 20}}
        ref={componentRef}
      >
        <div className={styles.groupName}>
          <strong style={{fontSize: 16}}>
            {selectedGroup ? getGroupName(selectedGroup) : ''}
          </strong>
        </div>
        <Table
          showSorterTooltip={false}
          size='small'
          title={() => (
            <>
              <strong>
                <FormattedMessage id='client_count'/>: {totals.total_clients}
              </strong>{' '}
              <br/>{' '}
              <strong>
                <FormattedMessage id='total'/>: {totals.total_balance}
              </strong>{' '}
            </>
          )}
          bordered={true}
          columns={columns}
          pagination={false}
          rowKey={element => element._id}
          dataSource={clients}
          className={'tableClass'}
        />
      </div>
      <Modal
        visible={editGroupVisibility}
        title={lang.formatMessage({id: 'edit_group'})}
        okText={lang.formatMessage({id: 'confirm'})}
        cancelText={lang.formatMessage({id: 'cancel'})}
        onCancel={() => setEditGroupVisibility(false)}
        confirmLoading={isGroupUpdating}
        onOk={() => {
          updateGroup(editGroupForm.getFieldValue('name'))
        }}
      >
        <Form form={editGroupForm} layout='vertical' name='edit_group'>
          <Form.Item
            name='name'
            label={lang.formatMessage({id: 'group_name'})}
          >
            <Input/>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={changeManagerVisibility}
        title={lang.formatMessage({id: 'change_manager'})}
        okText={lang.formatMessage({id: 'confirm'})}
        cancelText={lang.formatMessage({id: 'cancel'})}
        onCancel={() => {
          setNewManager()
          setChangeManagerVisibility(false)
        }}
        confirmLoading={isGroupUpdating}
        onOk={() => {
          changeManager()
        }}
      >
        <Select
          loading={managersLoading}
          allowClear={false}
          style={{width: 200, marginRight: 20}}
          placeholder={lang.formatMessage({id: 'select_manager'})}
          onChange={e => {
            setNewManager(e)
          }}
          value={newManager}
        >
          {managers.map(manager => (
            <Option key={manager._id} value={manager._id}>
              {manager.name}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  )
}
