import React, { useState, useEffect } from "react"
import { IntlProvider } from "react-intl"
import "moment/locale/hy-am"
import "moment/locale/ru"

import messages_hy from "../translations/hy.json"
import messages_en from "../translations/en.json"
import messages_ru from "../translations/ru.json"
import LanguageContext from "../component/helper/context/LanguageContext"
import moment from "moment"

const messages = {
  hy: messages_hy,
  en: messages_en,
  ru: messages_ru,
}

const languages = () => [
  {
    lang_key: "hy",
    value: "Հայ",
  },
  {
    lang_key: "en",
    value: "Eng",
  },
  {
    lang_key: "ru",
    value: "Рус",
  },
]

const withLanguage =
  (Component) =>
  ({ ...props }) => {
    const lsLang = localStorage.getItem("language")
    const [language, setLanguage] = useState(lsLang ? lsLang : "hy")

    const setCurrentLanguage = (lang) => {
      localStorage.setItem("language", lang)
      setLanguage(lang)
      window.location.reload(false)
    }

    useEffect(() => {
      console.log("#art", language)
      if (language === "hy") {
        moment.locale("hy-am")
      } else {
        moment.locale(language)
      }
    }, [language])

    return (
      <LanguageContext.Provider
        value={{ language, setLanguage: setCurrentLanguage }}
      >
        <IntlProvider locale={language} messages={messages[language]}>
          <Component {...props} />
        </IntlProvider>
      </LanguageContext.Provider>
    )
  }

export default withLanguage
export { languages }
