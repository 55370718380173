import React, { useEffect, useState } from "react"
import SBContent from "../../helper/SidebarContent"
import { useLoading } from "../../helper/Hooks"
import moment from "moment"
import { Select } from "antd"
import useDashboardFilter from "../hooks/DashboardFilterHook"
import useClientCategories from "./ClientCategoriesHook"
import ClientTransactionTable from "../../statistics/tables/ClientTransactionTable"
import ClientProfitTransactionChart from "../../statistics/ClientProfitTransactionChart"
import useClientProducts from "./ClientProductsHook"
import styles from "./dashboardClients.module.scss"
import API from "../../../api"
import SearchDropdown from "../../helper/searchDropdown/SearchDropdown"
import DateRangeFilter from "../../helper/DateRangeFilter"
import { useIntl } from "react-intl"
import useGroups from "./GroupHook"

const { Option } = Select

export default function DashboardClients({
  clientId,
  showClientFilter = true,
  showTitle = true,
}) {
  const lang = useIntl()
  const { dateRange, setDateRange, filter, setFilter } = useDashboardFilter()

  const { categories, isCategoriesLoading } = useClientCategories("/category")
  const { groups, isGroupsLoading, getGroups } = useGroups(filter.managerId)

  const { products, isProductsLoading, getProducts } = useClientProducts(
    `/product/filter?category=${filter.categoryId ? filter.categoryId : ""}`
  )
  const [data, setData] = useState()
  const { loading: dataLoading, startLoading: startDataLoading } = useLoading()

  const getData = () => {
    startDataLoading(async () => {
      const response = await API.get(
        `/statistics/profitwithTransactions/clients?start=${
          dateRange.start
        }&end=${dateRange.end}&manager=${
          filter.managerId ? filter.managerId : ""
        }&group=${filter.groupId ? filter.groupId : ""}&category=${
          filter.categoryId ? filter.categoryId : ""
        }&product=${filter.productId ? filter.productId : ""}&client=${
          filter.clientId ? filter.clientId : ""
        }`
      )
      setData(response.data.result)
    })
  }

  useEffect(() => {
    setFilter({ ...filter, clientId: clientId })
  }, [])

  useEffect(() => {
    if (dateRange.start && dateRange.end) {
      getData()
    }
  }, [dateRange, filter])
  useEffect(() => {
    if (filter.categoryId) {
      getProducts()
    }
  }, [filter.categoryId])
  useEffect(() => {
    if (filter.managerId) {
      getGroups()
    }
  }, [filter.managerId])

  const content = (
    <div>
      <div className={styles.filterContainer}>
        <DateRangeFilter
          className={styles.dateFilter}
          onChange={setDateRange}
          defaultValue={moment().format("MMMM")}
          intervals={[6, 12]}
        />
        {showClientFilter && (
          <SearchDropdown
            placeholder={lang.formatMessage({ id: "select_client" })}
            url={`/user/v2?roles=client&blocked=false&manager=${
              filter.managerId ? filter.managerId : ""
            }`}
            searchUrl={`/user/v2?roles=client&blocked=false&manager=${
              filter.managerId ? filter.managerId : ""
            }&name=`}
            onSelect={(selectedValue) =>
              setFilter({ ...filter, clientId: selectedValue })
            }
            renderItem={(item) => ({
              key: item._id,
              value: item._id,
              name: item.fullname,
            })}
            className={styles.filter}
          />
        )}
        <SearchDropdown
          placeholder={lang.formatMessage({ id: "select_manager" })}
          url="/user/v2?roles=manager&blocked=false"
          searchUrl="/user/v2?roles=manager&blocked=false&name="
          onSelect={(selectedValue) =>
            setFilter({ ...filter, managerId: selectedValue })
          }
          renderItem={(item) => ({
            key: item._id,
            value: item._id,
            name: item.fullname,
          })}
          className={styles.filter}
        />
        <Select
          allowClear={true}
          loading={isGroupsLoading}
          className={styles.filter}
          placeholder={lang.formatMessage({ id: "select_group" })}
          onChange={(e) => {
            setFilter({ ...filter, groupId: e })
          }}
          disabled={!filter.managerId}
        >
          {groups.map((group) => (
            <Option key={group._id} value={group._id}>
              {group.name}
            </Option>
          ))}
        </Select>
        <Select
          loading={isCategoriesLoading}
          allowClear={true}
          className={styles.filter}
          placeholder={lang.formatMessage({ id: "select_category" })}
          showSearch
          onChange={(e) => {
            setFilter({ ...filter, categoryId: e, productId: undefined })
          }}
          optionFilterProp="children"
          filterOption={(input, option) => {
            if (!option.children) {
              return true
            }
            return option.children.toLowerCase().includes(input.toLowerCase())
          }}
        >
          {!isCategoriesLoading &&
            categories.map((category) => (
              <Option key={category._id} value={category._id}>
                {category.name}
              </Option>
            ))}
        </Select>
        <Select
          loading={isProductsLoading}
          allowClear={true}
          value={filter.productId}
          className={styles.productFilter}
          placeholder={lang.formatMessage({ id: "select_product" })}
          showSearch
          onChange={(e) => {
            setFilter({ ...filter, productId: e })
          }}
          optionFilterProp="children"
          filterOption={(input, option) => {
            if (!option.children) {
              return true
            }
            return option.children.toLowerCase().includes(input.toLowerCase())
          }}
        >
          {!isProductsLoading &&
            products.map((product) => (
              <Option key={product._id} value={product._id}>
                {`(${product.product_id}) ${product.description}`}
              </Option>
            ))}
        </Select>
      </div>
      <div className={styles.dataContainer}>
        <ClientTransactionTable dataSet={data} loading={dataLoading} />
        <ClientProfitTransactionChart dataSet={data} loading={dataLoading} />
      </div>
    </div>
  )

  const constructScreen = () => {
    return showTitle ? (
      <SBContent title={lang.formatMessage({ id: "client_statistics" })}>
        {content}
      </SBContent>
    ) : (
      content
    )
  }

  return constructScreen()
}
