import React, {useState} from 'react'
import {Button, Card, Divider, message} from "antd";
import styles from "../../user/info/info.module.scss";
import {FormattedMessage, useIntl} from "react-intl";
import {hasPermission} from "../../../api/user";
import {getDate} from "../../helper/helper";
import {formatNumber} from "../../helper/Integer/IntegerFormatter";
import ConfirmButton from "../../helper/ConfirmButton";
import API from "../../../api";
import ClientOrders from "../ClientOrders";

const RiskyOrderItem = ({ item, client, onMoreClick }) => {
  const [itemStatus, setItemStatus] = useState(item.status)
  const lang = useIntl()

  const archiveOrder = async () => {
    try {
      await API.delete(`order/${item._id}`)
      setItemStatus('ARCHIVED')
    } catch (err) {
      message.error(lang.formatMessage({id: 'order_archive_error'}))
    }
  }
  const hideArchiveBtn =
    (item.agent && itemStatus === 'FINISHED') ||
    itemStatus === 'ARCHIVED' || itemStatus === 'DELIVERED' || !hasPermission('admin_archive_finished_order')

  return (
    <Card style={{ margin: '30px' }} className={styles.orderItem}>
      <div className={styles.orderHeader}>
        <div>
          <FormattedMessage id='id' />: <b>{item._id.toUpperCase().slice(-5)}</b>
        </div>
        <div className={styles.orderStatus}>
          <div>
            <FormattedMessage id='status'/>: <b>{itemStatus === "FINISHED" && item.order_type && item.order_type === "RETURN" ? "RETURN" : itemStatus}</b>
          </div>
          {item.order_type === 'INVOICE' && (<b style={{backgroundColor: '#d4edda', width: 'auto', borderRadius: 10, textAlign: 'center'}}><FormattedMessage id='has_invoice'/></b>)}
        </div>
      </div>
      <div className={styles.orderContent}>
        <div>
            <div>
              <FormattedMessage id='ordered_user' />:{' '}
              <b>{item.ordered_user.fullname}</b>
            </div>
            <div>
              <FormattedMessage id='client' />: <b>{client.fullname}</b>
            </div>
          <div>
            <FormattedMessage id='order_date' />:{' '}
            <b>{item.finished_date ? getDate(item.finished_date, true): ""}</b>
          </div>
          {item.agent && (
            <div>
              <FormattedMessage id='postman' />: <b>{item.agent.fullname}</b>
            </div>
          )}
        </div>
        {item.status === 'FINISHED' && (
          <div>
            <div>
              <FormattedMessage id='paid_amount' />:{' '}
              <b>{formatNumber(item.payed_amount, '֏')}</b>
            </div>
            <div>
              <FormattedMessage id='user_current_balance' />:{' '}
              <b>{formatNumber(item.user_balance, '֏', '', -1)}</b>
            </div>
          </div>
        )}
      </div>
      <Divider />
      <div className={styles.moreInfo}>
        <div>
          <FormattedMessage id='total' />:
          <b>{formatNumber(item.total_price, '֏')}</b>
        </div>
        <div>
          <Button type='primary' onClick={() => onMoreClick(item)}>
            <FormattedMessage id='more' />
          </Button>
          {!hideArchiveBtn && (
            <ConfirmButton
              type='primary'
              confirmTitle={lang.formatMessage({id: 'archive_question'})}
              danger
              style={{ marginLeft: 10 }}
              onClick={archiveOrder}
            >
              <FormattedMessage id='archive' />
            </ConfirmButton>
          )}
        </div>
      </div>
    </Card>
  )
}

export default RiskyOrderItem