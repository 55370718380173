import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { hasPermission } from '../../api/user'
import { useSettings } from '../helper/context/SettingsContext'

const LimitationComponent = ({ component: Component, ...props }) => {
  const location = useLocation()
  const { limits } = useSettings()

  if (limits && limits.hasExpired && !location.pathname.includes('/limits')) {
    return <Redirect to={{ pathname: '/limits' }} />
  }

  return <Component {...props} />
}

export default LimitationComponent
