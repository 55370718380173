import React, {useState, useEffect, useReducer, useRef} from 'react'
import SContent from '../helper/SidebarContent/'
import styles from './user.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import { Select, Button, Divider, Table, Typography } from 'antd'
import API from '../../api'
import { Link } from 'react-router-dom'
import { useLoading } from '../helper/Hooks'
import {IntTextFormatter} from "../helper/Integer/IntegerFormatter";
import {useReactToPrint} from "react-to-print";

const { Option } = Select
const { Text } = Typography

function DebtUsers() {
  const [users, setUsers] = useState([])
  const [managers, setManagers] = useState([])
  const [query, set] = useReducer(
    (state, [field, value]) => ({ ...state, [field]: value }),
    {
      manager: undefined,
      filter: undefined,
      blocked: undefined,
    }
  )
  const language = useIntl()
  const { loading: dataLoading, startLoading: startDataLoading } = useLoading()
  const {
    loading: managersLoading,
    startLoading: startManagerLoading,
  } = useLoading()

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    getManagers()
  }, [])

  useEffect(() => {
    getData()
  }, [query])

  const getData = async () => {
    startDataLoading(async () => {
      const url = `/user/debt`
      const response = await API.get(url, {
        params: {
          manager: query.manager,
          filter: query.filter,
          blocked: query.blocked,
        },
      })
      console.log(response.data.result)
      setUsers(response.data.result)
    })
  }

  const getManagers = async () => {
    startManagerLoading(async () => {
      const response = await API.get('/user/managers')
      const managers = response.data.result.managers
      setManagers(managers)
    })
  }

  const calculateTotalBalance = () => {
    let total = 0
    users.forEach((user) => {
      total += user.balance.total
    })
    return total
  }

  return (
    <SContent title={language.formatMessage({id: 'debt_users'})}>
      <div className={styles.debtContainer}>
        <div className={styles.filterContainer}>
          <Select
            className={styles.filter}
            allowClear
            placeholder={language.formatMessage({ id: 'select_manager' })}
            onChange={(e) => set(['manager', e])}
            loading={managersLoading}
          >
            {managers.map((e) => (
              <Option key={e._id} value={e._id}>
                {e.fullname}
              </Option>
            ))}
          </Select>
          <Select
            className={styles.filter}
            onChange={(e) => set(['filter', e])}
            allowClear
            placeholder={language.formatMessage({ id: 'debt' })}
          >
            <Option value={'no_hvhh'}>
              {language.formatMessage({ id: 'no_hvhh' })}
            </Option>
            <Option value={'no_phone'}>
              {language.formatMessage({ id: 'no_phone' })}
            </Option>
            <Option value={'no_address'}>
              {language.formatMessage({ id: 'no_address' })}
            </Option>
          </Select>
          <Select
            className={styles.filter}
            onChange={(e) => set(['blocked', e])}
            allowClear
            placeholder={language.formatMessage({id: 'all_users'})}
          >
            <Option value={'true'}><FormattedMessage id='blocked' /></Option>
            <Option value={'false'}><FormattedMessage id='unblocked' /></Option>
          </Select>
          <Button onClick={handlePrint} type='primary' className={styles.printBtn}>
            <FormattedMessage id='print' />
          </Button>
        </div>
      </div>
      <div id='list'  ref={componentRef}>
        <Table
          showSorterTooltip={false}
          loading={dataLoading}
          className={'tableClass'}
          title={() => <strong>Total Count: {users.length}</strong>}
          columns={[
            {
              title: language.formatMessage({id: 'name'}),
              dataIndex: 'fullname',
              render: (text, record) => (
                <Link
                  to={{
                    pathname: `/view/user/${record._id}`,
                  }}
                >
                  {text}
                </Link>
              ),
            },
            {
              title: language.formatMessage({ id: 'hvhh' }),
              dataIndex: 'hvhh',
              render: (text) => text,
              sorter: (a, b) =>
                (a.hvhh ? a.hvhh.length : 0) - (b.hvhh ? b.hvhh.length : 0),
            },
            {
              title: language.formatMessage({ id: 'phone_number' }),
              dataIndex: 'phone_number',
              render: (text) => text,
              sorter: (a, b) =>
                (a.phone_number ? a.phone_number.length : 0) -
                (b.phone_number ? b.phone_number.length : 0),
            },
            {
              title: language.formatMessage({ id: 'balance' }),
              dataIndex: 'balance',
              sorter: (a, b) => b.balance.total - a.balance.total,
              render: (balance) => (
                <IntTextFormatter currencyText={balance.currency_text} number={balance.total} prefixNumber={-1}/>
                // <b>{-1 * balance.total + balance.currency_text}</b>
              ),
            },
            {
              title: language.formatMessage({ id: 'debt_amount' }),
              dataIndex: 'debtAmount',
              sorter: (a, b) => b.debtAmount - a.debtAmount,
              render: (debtAmount, record) => debtAmount >= 0 ? (
                <IntTextFormatter currencyText={record.balance.currency_text} number={debtAmount}/>
              ): '-',
            },
          ]}
          rowKey={(element) => element._id}
          dataSource={users}
          size='small'
          pagination={false}
          bordered={true}
          summary={() => {
            if (users.length > 0) {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell>
                      <b>
                        <FormattedMessage id='total' />
                      </b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell className={styles.cellFloatRight}>
                      <Text>{
                        <IntTextFormatter currencyText={'֏'} number={calculateTotalBalance()} prefixNumber={-1}/>
                      }
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )
            }
          }}
        />
      </div>
    </SContent>
  )
}

export default DebtUsers
