import React, {useEffect, useState} from 'react'
import RiskyOrder from "../../../api/orders/RiskyOrder";
import moment from "moment";
import {useLoading} from "../../helper/Hooks";
import styles from "../../user/info/info.module.scss";
import DateRangeFilter from "../../helper/DateRangeFilter";
import {useIntl} from "react-intl";
import {Collapse, Divider, List, Spin} from "antd";
import ClientOrders from "../ClientOrders";
import RiskyOrderItem from "./RiskyOrderItem";
import SBContent from '../../helper/SidebarContent/'

const {Panel} = Collapse;

const RiskyOrders = () => {

  const [dateRange, setDateRange] = useState({
    start: moment()
      .startOf('month')
      .valueOf(),
    end: moment().valueOf()
  })
  const {loading, startLoading} = useLoading()
  const lang = useIntl()
  const [data, setData] = useState([])
  const [selectedOrder, setSelectedOrder] = useState()

  useEffect(() => {
    startLoading(async () => {
      const response = await RiskyOrder.getOrders(dateRange.start, dateRange.end)
      console.log(response.data.result)
      setData(response.data.result)
    })
  }, [dateRange])

  return (
    <SBContent title={lang.formatMessage({id: 'risky_orders'})}>
      <div className={styles.filterContainer}>
        <DateRangeFilter
          style={{width: 200, marginBottom: 10}}
          placeholder={lang.formatMessage({id: 'time'})}
          onChange={setDateRange}
          defaultValue={moment().format('MMMM')}
        />
      </div>

      <div>
        <Spin spinning={loading}>
          <Collapse accordion>
            {data.map((riskyClient) => {
              return (<Panel
                key={riskyClient.client._id}
                header={<strong>({moment().dayOfYear(riskyClient.date.dayOfYear).year(riskyClient.date.year).format(
                  'DD/MM/YYYY')}) {riskyClient.client.fullname} </strong>}>
                <List
                  itemLayout='horizontal'
                  dataSource={riskyClient.orders}
                  renderItem={(item, index) => (
                    <>
                      {index !== 0 && <Divider/>}
                      <RiskyOrderItem
                        key={item._id}
                        item={item}
                        client={riskyClient.client}
                        onMoreClick={setSelectedOrder}
                      />
                    </>
                  )}
                />
              </Panel>)
            })}
          </Collapse>
        </Spin>
      </div>
      <ClientOrders
        visible={selectedOrder}
        selectedOrder={selectedOrder}
        onCancel={() => {
          setSelectedOrder(undefined)
        }}
      />
    </SBContent>
  )
}

export default RiskyOrders