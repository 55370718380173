import React, { useEffect, useState } from 'react'
import { Input, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { hasPermission } from '../../../api/user'
import Api from '../../../api'
import { FormattedMessage, useIntl } from 'react-intl'
import styles from './info.module.scss'
import { useLoading } from '../../helper/Hooks'
import { useSettings } from '../../helper/context/SettingsContext'

export default function ProductManager({ product: initialProduct, userId, onProductChange = {}, changed }) {
  const [product, setProduct] = useState(initialProduct)
  const [addState, setAddState] = useState()
  const [removeState, setRemoveState] = useState()
  const [returnState, setReturnState] = useState()
  const language = useIntl()
  const [info, setInfo] = useState({
    warehouse: product.total_count,
    onHand: product.count,
    returned: product.returned,
  })
  const { loading, startLoading } = useLoading()
  const { returnEnabled } = useSettings()

  const loadingIcon = (
    <LoadingOutlined style={{ fontSize: 12, marginLeft: 10 }} />
  )

  useEffect(() => {
    if (!changed) return
    const { type } = changed
    if (type === 'add') {
      setAddState()
    } else if (type === 'remove') {
      setRemoveState()
    } else if(type === 'return') {
      setReturnState()
    }
    setProduct({
      ...product,
      total_count: info.warehouse,
      count: info.onHand,
    })
  }, [changed])

  async function onUpdate(amount) {
    startLoading(async () => {
      try {
        if (!amount || Number.isNaN(amount)) {
          throw new Error(language.formatMessage({ id: 'product_wrong_input' }))
        }

        if (product.total_count < -amount) {
          throw new Error(
            language.formatMessage({ id: 'product_insufficient_warehouse' })
          )
        }

        if (product.count < amount) {
          throw new Error(
            language.formatMessage({ id: 'product_insufficient_worker' })
          )
        }
        await Api.post('delivery/products', {
          agent_id: userId,
          products: [
            {
              id: product.product,
              amount: amount,
              total: info.warehouse,
              count: info.onHand,
            },
          ],
        })
        setProduct({
          ...product,
          total_count: info.warehouse,
          count: info.onHand,
        })
      } catch (err) {
        message.error(err.message)

        setInfo({
          warehouse: initialProduct.total_count,
          onHand: initialProduct.count,
          returned: initialProduct.returned,
        })
      }
      setRemoveState()
      setAddState()
      onProductChange({
          amount: 0,
          type: amount > 0 ? "remove" : "add", 
          id: product._id, 
      })
    })
  }

  const onReturn = (amount) => {
    startLoading(async () => {
      try {
        if (initialProduct.returned + amount < 0) {
          throw new Error(
            language.formatMessage({ id: 'too_many_return_error' })
          )
        }
        await Api.post('delivery/returnedProducts', {
          agent_id: userId,
          products: [
            {
              id: product.product,
              amount: amount,
              total: initialProduct.returned,
            },
          ],
        })
        setProduct({
          ...product,
          returned: info.returned + amount,
        })
      } catch (ex) {
        message.error(ex.message)

        setInfo({
          warehouse: initialProduct.total_count,
          onHand: initialProduct.count,
          returned: initialProduct.returned,
        })
      }
      setReturnState()
      onProductChange({
          amount: 0,
          type: "return", 
          id: product.product, 
      })
    })
  }

  return (
    <div className={styles.productInfoContainer}>
      <div>
        <span>
          <strong>
            <FormattedMessage id='product_count_warehouse' />: {info.warehouse}
          </strong>
        </span>
        <span>
          <strong>
            <FormattedMessage id='product_count_on_hand' />: {info.onHand}
          </strong>
        </span>
        {returnEnabled && (
          <span>
            <strong>
              <FormattedMessage id='returned' />: {info.returned}
            </strong>
          </span>
        )}
      </div>
      <div>
        <Input
          disabled={
            !hasPermission('manager_product_udpate') ||
            addState > 0 ||
            returnState > 0
          }
          type='number'
          size='small'
          min={0}
          id={initialProduct._id + 'r'}
          value={removeState}
          max={product.count}
          onChange={(e) => {
            let value = parseInt(e.target.value)
            setRemoveState(value)
            value = isNaN(value) ? 0 : value
            const warehouse = product.total_count + value
            const onHand = product.count - value
            setInfo({ warehouse, onHand, returned: info.returned })

            onProductChange({
                type: "remove", 
                amount: value,
                total: warehouse,
                count: onHand,
            })
          }}
          onPressEnter={(e) => e.preventDefault()}
          addonAfter={
            <div
              style={
                addState > 0
                  ? { minWidth: 50 }
                  : { minWidth: 50, cursor: 'pointer' }
              }
              onClick={async () => {
                if (removeState > 0 && loading) {
                  return
                } else {
                  if (
                    addState > 0 ||
                    !removeState ||
                    Number.isNaN(removeState)
                  ) {
                    return
                  }
                  await onUpdate(removeState)
                }
              }}
            >
              <FormattedMessage id='remove_product_button' />
              {removeState > 0 && loading && loadingIcon}
            </div>
          }
        />
        <Input
          disabled={
            !hasPermission('manager_product_udpate') ||
            removeState > 0 ||
            returnState > 0
          }
          type='number'
          size='small'
          id={initialProduct._id + 'a'}
          min={0}
          value={addState}
          max={product.total_count}
          onChange={(e) => {
            let value = parseInt(e.target.value)
            setAddState(value)
            value = isNaN(value) ? 0 : value
            const warehouse = product.total_count - value
            const onHand = product.count + value
            setInfo({ warehouse, onHand, returned: info.returned })
            onProductChange({
                type: "add", 
                amount: -value,
                total: warehouse,
                count: onHand,
            })
          }}
          onPressEnter={(e) => e.preventDefault()}
          addonAfter={
            <div
              style={
                removeState > 0
                  ? { width: 50 }
                  : { width: 50, cursor: 'pointer' }
              }
              onClick={async () => {
                if (addState > 0 && loading) {
                  return
                } else {
                  if (removeState > 0 || !addState || Number.isNaN(addState)) {
                    return
                  }
                  await onUpdate(-addState)
                }
              }}
            >
              <FormattedMessage id='add_product_button' />
              {addState > 0 && loading && loadingIcon}
            </div>
          }
        />
        {returnEnabled && (
          <Input
            disabled={
              !hasPermission('manager_product_udpate') ||
              removeState > 0 ||
              addState > 0
            }
            type='number'
            size='small'
            id={initialProduct._id + 'a'}
            min={0}
            value={returnState}
            max={product.returned}
            onChange={(e) => {
              let value = parseInt(e.target.value)
              value = isNaN(value) ? 0 : value
              setReturnState(value)
              const returned = product.returned - value
              setInfo({ ...info, returned })
              
              onProductChange({
                  type: "return", 
                  amount: -value,
                  ...info,
                  total: product.returned,
              })
            }}
            onPressEnter={(e) => e.preventDefault()}
            addonAfter={
              <div
                style={
                  removeState > 0 || addState > 0
                    ? { width: 50 }
                    : { width: 50, cursor: 'pointer' }
                }
                onClick={async () => {
                  if (returnState > 0 && loading) {
                    return
                  } else {
                    if (
                      removeState > 0 ||
                      addState > 0 ||
                      !returnState ||
                      Number.isNaN(returnState)
                    ) {
                      return
                    }
                    await onReturn(-returnState)
                  }
                }}
              >
                <FormattedMessage id='return' />{' '}
                {returnState > 0 && loading && loadingIcon}
              </div>
            }
          />
        )}
      </div>
    </div>
  )
}
