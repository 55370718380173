import React, {useEffect, useRef, useState} from 'react'
import {Button, Form, message, Table, Input, Modal} from 'antd'
import {CheckCircleOutlined, CloseCircleOutlined, CommentOutlined} from '@ant-design/icons'
import {Link} from 'react-router-dom'
import {useLoading} from '../helper/Hooks'
import SBContent from '../helper/SidebarContent/'
import styles from './order.module.scss'
import API from '../../api'
import ClientOrders from "./ClientOrders";
import {formatNumber} from "../helper/Integer/IntegerFormatter";
import SearchDropdown from "../helper/searchDropdown/SearchDropdown";
import {FormattedMessage, useIntl} from "react-intl";
import {useReactToPrint} from "react-to-print";
import {hasPermission} from "../../api/user";

const {TextArea} = Input
const {confirm} = Modal

const InProgress = () => {
  const [postman, setPostman] = useState({id: undefined, name: ''})
  const [data, setData] = useState({
    totalPrice: 0,
    totalPaid: 0,
    orders: []
  })
  const [selectedOrder, setSelectedOrder] = useState()
  const {loading, startLoading} = useLoading()
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [editCommentForm] = Form.useForm()
  const lang = useIntl()
  const [columns, setColumns] = useState([])

  useEffect(() => {
    getOrders()
  }, [postman])

  const getOrders = () => {
    startLoading(async () => {
      const response = await API.get(`/order/postmanPicked?postman=${postman.id ? postman.id : ''}`)
      setData(response.data.result)
      setupTableColumns('֏')
    })
  }

  const setupTableColumns = currency => {
    const columns = [
      {
        title: lang.formatMessage({id: "client"}),
        dataIndex: 'client',
        render: (user) =>
          user ? (
            <Link
              to={{
                pathname: `/view/user/${user._id}`,
              }}
            >
              {user.fullname}
            </Link>
          ) : (
            ''
          ),
      },
      {
        title: lang.formatMessage({id: "ordered_user"}),
        dataIndex: 'ordered_user',
        render: (user) =>
          user ? (
            <Link
              to={{
                pathname: `/view/user/${user._id}`,
              }}
            >
              {user.name}
            </Link>
          ) : (
            ''
          ),
      },
      {
        title: lang.formatMessage({id: "manager"}),
        dataIndex: 'manager',
        render: (user) =>
          user ? (
            <Link
              to={{
                pathname: `/view/user/${user._id}`,
              }}
            >
              {user.name}
            </Link>
          ) : (
            ''
          ),
      },
      {
        title: lang.formatMessage({id: "postman"}),
        dataIndex: 'agent',
        render: (user) =>
          user ? (
            <Link
              to={{
                pathname: `/view/user/${user._id}`,
              }}
            >
              {user.name}
            </Link>
          ) : (
            ''
          ),
      },
      {
        title: lang.formatMessage({id: "total_price"}),
        dataIndex: 'total_price',
        render: (price) => formatNumber(price, currency),
      }]
    if (hasPermission('admin_pending_profit')) {
      columns.push(
        {
          title: lang.formatMessage({id: 'profit'}),
          dataIndex: 'profit',
          render: (profit) => formatNumber(profit, currency)
        }
      )
    }
    columns.push({
        title: lang.formatMessage({id: "invoice"}),
        dataIndex: 'order_type',
        render: (invoice) => (
          <div style={{textAlign: 'center'}}>
            {invoice !== 'STANDARD' ? (
              <CheckCircleOutlined style={{fontSize: 20}}/>
            ) : (
              <CloseCircleOutlined style={{fontSize: 20}}/>
            )}
          </div>
        ),
        width: 65
      },
      {
        title: lang.formatMessage({id: "comment"}),
        dataIndex: 'comment',
        width: 150,
        render: (text, record) => (
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <div
              style={{
                height: 30,
                overflowY: 'auto',
              }}
            >
              {text ? text : '-'}
            </div>
            <div>
              <CommentOutlined style={{cursor: 'pointer', fontSize: 20}} onClick={(e) => {
                e.stopPropagation()
                onCommentEdit(record._id, text)
              }}/>
            </div>
          </div>
        ),
      },)
    setColumns(columns)
  }

  const updateOrder = async (orderId, comment) => {
    await API.put('/order/updateComment', {order: orderId, comment: comment})
    getOrders()
  }

  const onCommentEdit = (orderId, comment) => {
    confirm({
      title: lang.formatMessage({id: "edit_order_comment"}),
      content: (
        <Form form={editCommentForm} name='editCommentForm' preserve={false}>
          <Form.Item
            name='comment'
            label={`${lang.formatMessage({id: 'comment'})}:`}
            className={styles.popupTextArea}
            initialValue={comment}
          >
            <TextArea rows={4}/>
          </Form.Item>
        </Form>
      ),
      async onOk() {
        try {
          await updateOrder(orderId, editCommentForm.getFieldValue('comment'))
        } catch (err) {
          message.error(err.message)
        }
      },
    })
  }

  return (
    <SBContent title={lang.formatMessage({id: "in_progress_orders"})}>
      <div className={styles.orderContainer}>
        <div className={styles.orderFilterContainer}>
          <SearchDropdown
            value={postman.id}
            placeholder={lang.formatMessage({id: "select_postman"})}
            style={{marginBottom: 10}}
            width={200}
            url='/user/v2?roles=postman&blocked=false'
            searchUrl='/user/v2?roles=postman&blocked=false&name='
            onSelect={(selectedValue, option) => {
              if (!selectedValue || !option) {
                setPostman({id: undefined, name: ''})
              } else {
                setPostman({id: selectedValue, name: option.children})
              }
            }}
            renderItem={(item) => ({
              key: item._id,
              value: item._id,
              name: item.fullname,
            })}
          />
          <Button
            type='primary'
            onClick={handlePrint}
          >
            <FormattedMessage id='print'/>
          </Button>
        </div>
        <div id='list' ref={componentRef}>
          <div className={styles.postman}>
            <strong style={{fontSize:16}}>
              {postman.name}
            </strong>
          </div>
          <Table
            title={() => (
              <div className={styles.deliveredOrdersTotals}>
                <div>
                  <strong><FormattedMessage id={"total_price"}/>: {formatNumber(data.totalPrice, '֏')}</strong>
                </div>
              </div>
            )}
            showSorterTooltip={false}
            bordered={true}
            pagination={false}
            loading={loading}
            size="small"
            columns={columns}
            dataSource={data.orders}
            onRow={(element) => {
              return {
                onClick: () => {
                  setSelectedOrder(element)
                },
              }
            }}
            className={'tableClass'}
          />
        </div>
        <ClientOrders
          visible={selectedOrder}
          selectedOrder={selectedOrder}
          onCancel={(shouldRefresh) => {
            setSelectedOrder(undefined)
            if (shouldRefresh) {
              getOrders()
            }
          }}
        />
      </div>
    </SBContent>
  )
}

export default InProgress
