import React, { useState, useEffect } from 'react'
import { Modal, List } from 'antd'
import OrderEditPostmanPopup from './OrderEditPostmanPopup'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { getDate } from '../helper/helper'
import {FormattedMessage, useIntl} from 'react-intl'

export default function ClientOrders(props) {
  const lang = useIntl()
  const isForOneOrder = props.selectedOrder
  const orders = props.orders
  const visible = props.visible
  const onCancel = props.onCancel
  const [selectedOrder, setSelectedOrder] = useState(undefined)

  useEffect(() => {
    if (!isForOneOrder) return
    setSelectedOrder(props.selectedOrder)
  }, [isForOneOrder])

  const onBack = () => {
    setSelectedOrder(undefined)
  }

  const onModalCancel = () => {
    onCancel(false)
    setSelectedOrder(undefined)
  }

  const title =
    !isForOneOrder && selectedOrder ? (
      <ArrowLeftOutlined onClick={onBack} />
    ) : (
      <FormattedMessage id='order_info' />
    )

  return (
    <Modal visible={visible} title={title} onCancel={onModalCancel} footer={[]}>
      {!selectedOrder ? (
        <List
          dataSource={orders}
          renderItem={(order) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <>
                    <b><FormattedMessage id='id' />: </b>
                    <a onClick={() => setSelectedOrder(order)}>
                      {order.order_id}
                    </a>
                  </>
                }
                description={
                  order.finished_date ? getDate(order.finished_date) : ""
                  }
              />
              <span>
                <b>
                  <FormattedMessage id='total' />:{' '}
                </b>
                {order.total_price + ' AMD'}
              </span>
            </List.Item>
          )}
        />
      ) : (
        <OrderEditPostmanPopup
          order={selectedOrder}
          cancelListener={onModalCancel}
          onClose={onCancel}
        />
      )}
    </Modal>
  )
}
