import React, { useState } from 'react'
import API from '../../../api'
import { useBundle } from '../../../provider/MakeOrderProductsProvider'
import {useLoading} from "../../helper/Hooks";

export const useFinishOrder = () => {
  const { bundle, bundleTotal, paidAmount } = useBundle()
  const {loading: finishLoading, startLoading: startFinishLoading} = useLoading()
  const {loading: postmanLoading, startLoading: startPostmanLoading} = useLoading()
  const [response, setResponse] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [orderFinished, setOrderFinished] = useState(false)

  const orderRequest = async ({userId, invoice, comment, status}) => {
    setOrderFinished(false)
    try {
      const result = await API.post(
          `order/admin`,
          prepRequestBody({userId, invoice, comment, status})
      )
      setResponse(result.data.result)
      setOrderFinished(true)
    } catch (error) {
      setError(error)
    }
  }

  const finishOrder = (userId) => {
    startFinishLoading(async () => {
      await orderRequest({userId, status: 'FINISHED'})
    })
  }

  const postmanOrder = (userId, invoice, comment) => {
    startPostmanLoading(async () => {
      await orderRequest({userId, invoice, comment, status: 'PENDING'})
    })
  }

  const prepRequestBody = ({userId, invoice, comment, status}) => {
    const products = Object.values(bundle).map((product) => ({
      product: product._id,
      product_price: product.price,
      product_count: product.count,
    }))
    return {
      client: userId,
      payed_amount: paidAmount,
      total_price: bundleTotal,
      order_details: products,
      order_type: invoice ? 'INVOICE' : '',
      comment,
      status
    }
  }
  return { finishOrder, finishLoading, postmanOrder, postmanLoading, data: response, error, orderFinished }
}
