import { Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import styles from './info.module.scss'

export default function UserPermissionGroup ({
  permissionGroup,
  onPermissionUpdate,
  userPermissions
}) {
  const [checkedList, setCheckedList] = useState([])
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const lang = useIntl()

  const getPermissionNames = keys => {
    return keys.map(key => {
      return { value: key, label: lang.formatMessage({ id: key }) }
    })
  }
  useEffect(() => {
    setCheckedList(userPermissions)
    setIndeterminate(
      userPermissions.length > 0 &&
        userPermissions.length < permissionGroup.permissions.length
    )
    setCheckAll(userPermissions.length === permissionGroup.permissions.length)
  }, [userPermissions])

  const onChange = permissionList => {
    setCheckedList(permissionList)
    setIndeterminate(
      permissionList.length > 0 &&
        permissionList.length < permissionGroup.permissions.length
    )
    setCheckAll(permissionList.length === permissionGroup.permissions.length)
    onPermissionUpdate(permissionGroup.name, permissionList)
  }

  const onCheckAllChange = e => {
    const checked = e.target.checked
    setCheckedList(checked ? permissionGroup.permissions : [])
    setIndeterminate(false)
    setCheckAll(checked)
    onPermissionUpdate(
      permissionGroup.name,
      checked ? permissionGroup.permissions : []
    )
  }

  return (
    <div className={styles.permissionGroupContainer}>
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        <span style={{ fontWeight: 500, fontSize: 16 }}>
          {lang.formatMessage({ id: permissionGroup.name })}
        </span>
      </Checkbox>
      <Checkbox.Group
        options={getPermissionNames(permissionGroup.permissions)}
        value={checkedList}
        onChange={onChange}
        className={styles.permissionGroup}
      />
    </div>
  )
}
