import React from 'react'
import {useIntl} from "react-intl";
import {isManager} from "../../../../api/user";
import InputConfirm from "../../../helper/InputConfirm";
import API from "../../../../api";
import {message} from "antd";
import PercentageRange from './PercentageRange';

const UserLimits = ({user, getUser, ...props}) => {
  const lang = useIntl()

  const updateUserMinDeliveryPrice = async (value) => await API.put(`/user/${user._id}`, {minDeliveryPrice: value});

  const updateUserDebtAmount = async (value) => await API.put(`/user/${user._id}`, {debtAmount: value});

  return (
    <div {...props}>
      {isManager(user) && (
        <InputConfirm
          onConfirm={(value) => updateUserMinDeliveryPrice(value)}
          onError={(error) => {
            if (error.response) {
              message.error(error.response.data.message)
            } else {
              message.error(lang.formatMessage({id: 'min_delivery_price_update_error'}))
            }
          }}
          onSuccess={() => {
            message.success(lang.formatMessage({id: "min_delivery_price_update_success"}))
            getUser()
          }}
          onClearConfirm={() => updateUserMinDeliveryPrice(null)}
          buttonTitle={lang.formatMessage({id: 'update'})}
          initialValue={user.minDeliveryPrice}
          inputPlaceholder={lang.formatMessage({id: 'min_delivery_price'})}
          onConfirmDescription={lang.formatMessage({id: "min_delivery_price_update_desc"})}
          onConfirmTitle={lang.formatMessage({id: "min_delivery_price_update_title"})}
          width={200}
          clearButtonTitle={lang.formatMessage({id: "clear_min_delivery_price"})}
          onClearConfirmTitle={lang.formatMessage({id: "clear_min_delivery_price_title"})}
          onClearConfirmDesc={lang.formatMessage({id: "clear_min_delivery_price_desc"})}
        />
      )}
      <InputConfirm
        onConfirm={(value) => updateUserDebtAmount(value)}
        onClearConfirm={() => updateUserDebtAmount(null)}
        onError={(error) => {
          if (error.response) {
            message.error(error.response.data.message)
          } else {
            message.error(lang.formatMessage({id: 'debt_amount_update_error'}))
          }
        }}
        onSuccess={() => {
          message.success(lang.formatMessage({id: "debt_amount_update_success"}))
          getUser()
        }}
        buttonTitle={lang.formatMessage({id: 'update'})}
        initialValue={user.debtAmount}
        inputPlaceholder={lang.formatMessage({id: 'debt_amount'})}
        onConfirmDescription={lang.formatMessage({id: "debt_amount_update_desc"})}
        onConfirmTitle={lang.formatMessage({id: "debt_amount_update_title"})}
        width={200}
        minValue={0}
        clearButtonTitle={lang.formatMessage({id: "clear_debt_amount"})}
        onClearConfirmTitle={lang.formatMessage({id: "clear_client_debt_title"})}
        onClearConfirmDesc={lang.formatMessage({id: "clear_client_debt_desc"})}
      />
      <PercentageRange user={user}/>
    </div>
  )
}

export default UserLimits