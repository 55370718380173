import React from 'react'
import { InputNumber, Row } from 'antd'
import { useBundle } from '../../../provider/MakeOrderProductsProvider'
import {IntTextFormatter} from "../../helper/Integer/IntegerFormatter";
import {useIntl} from "react-intl";

export default function BundleTotals({ balance, bundleTotal }) {
  const { paidAmount, updatePaidAmount } = useBundle()
  const lang = useIntl()

  return (
    <>
      <Row justify='space-between' style={{ marginBottom: 10 }}>
        <div style={{ fontSize: 16, color: 'black' }}>
          <IntTextFormatter prefix={`${lang.formatMessage({id: "order_total"})}:`} nextLine={true} currencyText={balance.currency_text} number={bundleTotal}/>
        </div>
        <div style={{ fontSize: 16, color: 'black' }}>
          <IntTextFormatter prefix={`${lang.formatMessage({id: "balance_after_payment"})}:`} nextLine={true} currencyText={balance.currency_text}
                            number={balance.total - bundleTotal + paidAmount} prefixNumber={-1}/>
        </div>
      </Row>
      <div style={{ fontSize: 16, color: 'black' }}>{`${lang.formatMessage({id: "paid_amount"})}:`}</div>
      <InputNumber
        min={0}
        value={paidAmount}
        onChange={(e) => {
          const value = parseInt(e)
          const change = isNaN(value) ? 0 : value
          updatePaidAmount(change)
        }}
        style={{ width: 200 }}
      />
    </>
  )
}
