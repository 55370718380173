import { Layout } from 'antd'
import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import withLanguage from '../provider/LanguageProvider'
import withSettings from '../provider/SettingsProvider'
import withUser from '../provider/UserProvider'
import './app.module.scss'
import DashboardClients from './dashboard/clients/DashboardClients'
import Dashboard from './dashboard/Dashboard'
import DashboardCategories from './dashboard/DashboardCategories'
import DashboardManagers from './dashboard/DashboardManagers'
import DashboardPostmen from './dashboard/DashboardPostmen'
import DashboardGroups from './dashboard/groups/DashboardGroups'
import DashboardClientProducts from './dashboard/products/DashboardClientProducts'
import DashboardManagerProducts from './dashboard/products/DashboardManagerProducts'
import Expenses from './expense/Expenses'
import GroupCreate from './group/GroupCreate'
import { AuthRoute } from './helper/AuthRoute'
import { useSettings } from './helper/context/SettingsContext'
import UserContext from './helper/context/UserContext'
import Home from './Home.js'
import Limits from './limits/Limits'
import Login from './login/Login'
import ClientMap from './map/ClientMap'
import ManagerLocation from './map/ManagerLocation'
import ManagerTracker from './map/ManagerTracker'
import ShopFinder from './map/ShopFinder'
import DeliveredOrders from './order/DeliveredOrders'
import InProgressOrders from './order/InProgressOrders'
import MakeOrder from './order/makeOrder/MakeOrder'
import PendingOrders from './order/PendingOrders'
import Product from './product/info/Product'
import ProductCreate from './product/ProductCreate'
import ProductEdit from './product/ProductEdit'
import ProductHistory from './product/ProductHistory'
import Products from './product/Products'
import SideBar from './SideBar'
import DebtUsers from './user/DebtUsers'
import Groups from './user/groups/Groups'
import User from './user/info/User'
import UserCreate from './user/UserCreate'
import UserEdit from './user/UserEdit'
import Users from './user/Users'
import RiskyOrders from "./order/riskyOrder/RiskyOrders";

const { Footer, Content } = Layout

const getStyles = user => {
  const mainLayoutStyle = user ? { minHeight: '100vh', marginLeft: 230 } : {}
  const contentStyle = user ? { padding: '24px', minHeight: 280 } : {}
  const loginStyle = user
    ? {}
    : {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }

  return { mainLayoutStyle, contentStyle, loginStyle }
}

function App () {
  const { user } = useContext(UserContext)
  const {
    showPostmanDashboard,
    showClientProducts,
    postmanDelivery
  } = useSettings()

  const { mainLayoutStyle, contentStyle, loginStyle } = getStyles(user)

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <React.Fragment>
          <Layout style={{ minHeight: '100vh', backgroundColor: '#fff' }}>
            {user && <SideBar />}
            <Layout style={{ backgroundColor: '#fff' }}>
              <Content>
                <Route exact path='/' component={Home} />
                <AuthRoute
                  path='/dashboard-core'
                  component={Dashboard}
                  permissions={['statistics']}
                />
                <AuthRoute
                  path='/dashboard-managers'
                  component={DashboardManagers}
                  permissions={['statistics']}
                />
                {showPostmanDashboard && (
                  <AuthRoute
                    path='/dashboard-postmen'
                    component={DashboardPostmen}
                    permissions={['statistics']}
                  />
                )}
                <AuthRoute
                  path='/dashboard-categories'
                  component={DashboardCategories}
                  permissions={['statistics']}
                />
                <AuthRoute
                  path='/dashboard-manager-products'
                  component={DashboardManagerProducts}
                  permissions={['statistics']}
                />
                {showClientProducts && (
                  <AuthRoute
                    path='/dashboard-client-products'
                    component={DashboardClientProducts}
                    permissions={['statistics']}
                  />
                )}
                <AuthRoute
                  path='/dashboard-clients'
                  component={DashboardClients}
                  permissions={['statistics']}
                />
                <AuthRoute
                  path='/dashboard-groups'
                  component={DashboardGroups}
                  permissions={['statistics']}
                />
                <AuthRoute path='/users' component={Users} />
                <AuthRoute path='/groups' component={Groups} />
                <AuthRoute path='/debt/users' component={DebtUsers} />
                <AuthRoute path='/create/user' component={UserCreate} />
                <AuthRoute path='/edit/user/:id' component={UserEdit} />
                <AuthRoute path='/view/user/:id' component={User} />
                <AuthRoute path='/user/make-order/:id' component={MakeOrder} />
                <AuthRoute
                  path='/expenses'
                  component={Expenses}
                  permissions={['expense_page_view']}
                />
                <AuthRoute
                  path='/category/products/:catgegoryId'
                  component={Products}
                />
                <AuthRoute path='/products/' component={Products} />
                <AuthRoute path='/edit/product/:id' component={ProductEdit} />
                <AuthRoute path='/create/product' component={ProductCreate} />
                <AuthRoute
                  path='/history/products'
                  component={ProductHistory}
                />
                <AuthRoute path='/view/product/:id' component={Product} />

                {/*<AuthRoute path='/view/map/managers' component={ManagerMap} />*/}
                <AuthRoute
                  path='/view/map/managers-locations'
                  component={ManagerLocation}
                  permissions={['map_managers_location']}
                />
                <AuthRoute
                  path='/view/map/manager-tracker'
                  component={ManagerTracker}
                  permissions={['map_manager_traker']}
                />
                <AuthRoute path='/view/map/clients' component={ClientMap} />
                <AuthRoute
                  path='/view/map/shop-finder'
                  component={ShopFinder}
                />
                <AuthRoute
                  path='/create/group/:manager'
                  component={GroupCreate}
                />
                <AuthRoute path='/limits' component={Limits} />
                {postmanDelivery && (
                  <>
                    <AuthRoute
                      path='/orders/pending'
                      component={PendingOrders}
                    />
                    <AuthRoute
                      path='/orders/in-progress'
                      component={InProgressOrders}
                    />{' '}
                    <AuthRoute
                      path='/orders/delivered'
                      component={DeliveredOrders}
                    />
                    <AuthRoute
                      path='/orders/risky'
                      component={RiskyOrders}
                    />
                  </>
                )}
                <Layout style={loginStyle}>
                  <Route path='/login' component={Login} />
                </Layout>
              </Content>
              <Footer style={{ textAlign: 'center', backgroundColor: '#fff' }}>
                MAD ©{new Date().getFullYear()}
              </Footer>
            </Layout>
          </Layout>
        </React.Fragment>
      </Switch>
    </Router>
  )
}

export default withLanguage(withUser(withSettings(App)))
