import React from 'react'
import { useIntl } from 'react-intl'
import SidebarContent from '../../helper/SidebarContent'
import UserClients from '../info/userClients/UserClients'

const Groups = () => {
  const lang = useIntl()

  return (
    <SidebarContent title={lang.formatMessage({ id: 'groups' })}>
      <UserClients />
    </SidebarContent>
  )
}

export default Groups
