import { Card } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import API from '../../../api'
import {formatNumber} from "../../helper/Integer/IntegerFormatter";

export default function ManagerTotal({ userId, ...props }) {
  const [totals, setTotals] = useState({
    dailyProfit: 0,
    dailyInProgressProfit: 0,
    monthlyProfit: 0,
    dailyTransactions: 0,
    dailyInProgressTotal: 0,
    monthlyTransactions: 0,
    dailyReturnTotal: 0,
    monthlyReturnTotal: 0,
    balance: {},
  })
  const lang = useIntl()

  const getTotals = async () => {
    const response = await API.get(`manager/balances/profit?user=${userId}`)
    const {
      daily_total,
      daily_in_progress_total,
      monthly_total,
      daily_profit,
      daily_in_progress_profit,
      monthly_profit,
      balance,
      daily_return_total,
      monthly_return_total
    } = response.data.result
    setTotals({
      dailyTransactions: daily_total ?? 0,
      monthlyTransactions: monthly_total ?? 0,
      dailyProfit: daily_profit ?? 0,
      monthlyProfit: monthly_profit ?? 0,
      balance: balance ?? 0,
      dailyInProgressProfit: daily_in_progress_profit ?? 0,
      dailyInProgressTotal: daily_in_progress_total ?? 0,
      monthlyReturnTotal: monthly_return_total ?? 0,
      dailyReturnTotal: daily_return_total ?? 0
    })
  }

  useEffect(() => {
    getTotals()
  }, [])

  const gridStyle = {
    align: 'center',
    boxShadow: 'none',
    textAlign: 'center',
    padding: 6,
    width: `50%`,
  }

  const spanStyle = {
    fontSize: '100%',
    fontWeight: 'bold',
  }
  return (
    <div {...props}>
      <Card
        title={
          <strong>
            <FormattedMessage id='totals' />
          </strong>
        }
      >
        <Card.Grid style={gridStyle} hoverable={false}>
          <span style={spanStyle}>
            {`${lang.formatMessage({ id: 'daily_transactions' })}:`}
            <br />
            {formatNumber(totals.dailyTransactions, totals.balance.currency_text)}
          </span>
        </Card.Grid>
        <Card.Grid style={gridStyle} hoverable={false}>
          <span style={spanStyle}>
            {`${lang.formatMessage({ id: 'daily_profit' })}:`}
            <br />
            {formatNumber(totals.dailyProfit, totals.balance.currency_text)}
          </span>
        </Card.Grid>
        <Card.Grid style={gridStyle} hoverable={false}>
          <span style={spanStyle}>
            {`${lang.formatMessage({ id: 'monthly_transactions' })}:`}
            <br />
            {formatNumber(totals.monthlyTransactions, totals.balance.currency_text)}
          </span>
        </Card.Grid>
        <Card.Grid style={gridStyle} hoverable={false}>
          <span style={spanStyle}>
            {`${lang.formatMessage({ id: 'monthly_profit' })}:`}
            <br />
            {formatNumber(totals.monthlyProfit, totals.balance.currency_text)}
          </span>
        </Card.Grid>
        <Card.Grid style={gridStyle} hoverable={false}>
          <span style={spanStyle}>
            {`${lang.formatMessage({ id: 'daily_in_progress_total' })}:`}
            <br />
            {formatNumber(totals.dailyInProgressTotal, totals.balance.currency_text)}
          </span>
        </Card.Grid>
        <Card.Grid style={gridStyle} hoverable={false}>
          <span style={spanStyle}>
            {`${lang.formatMessage({ id: 'daily_in_progress_profit' })}:`}
            <br />
            {formatNumber(totals.dailyInProgressProfit, totals.balance.currency_text)}
          </span>
        </Card.Grid>
        <Card.Grid style={gridStyle} hoverable={false}>
          <span style={spanStyle}>
            {`${lang.formatMessage({id: 'daily_return_total'})}:`}
            <br/>
            {formatNumber(totals.dailyReturnTotal, totals.balance.currency_text)}
          </span>
        </Card.Grid>
        <Card.Grid style={gridStyle} hoverable={false}>
          <span style={spanStyle}>
            {`${lang.formatMessage({id: 'monthly_return_total'})}:`}
            <br/>
            {formatNumber(totals.monthlyReturnTotal, totals.balance.currency_text)}
          </span>
        </Card.Grid>
      </Card>
    </div>
  )
}
