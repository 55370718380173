import React, { useState } from 'react'
import { InputNumber } from 'antd'
import ConfirmButton from './ConfirmButton'
import styles from './helper.module.scss'

const InputConfirm = ({
  inputPlaceholder,
  initialValue,
  minValue,
  maxValue,
  formatter = (value) => value,
  parser = (value) => value,
  onConfirm,
  onConfirmTitle,
  onConfirmDescription,
  buttonTitle,
  onClearConfirm,
  clearButtonTitle,
  onClearConfirmTitle,
  onClearConfirmDesc,
  width = 90,
  onError,
  onSuccess,
  shouldReset = false,
}) => {
  const [value, setValue] = useState(initialValue)

  const onChangeConfirm = async () => {
    try {
      await onConfirm(value)
      onSuccess()
      if (shouldReset) {
        setValue(initialValue ?? undefined)
      }
    } catch (error) {
      onError(error)
    }
  }

  return (
    <div className={styles.inputContainer}>
      <div className={styles.inputWrapper}>
        <span>{inputPlaceholder}</span>
        <InputNumber
          style={{ width: width }}
          placeholder={inputPlaceholder}
          value={value}
          min={minValue}
          max={maxValue}
          formatter={formatter}
          parser={parser}
          onChange={(value) => {
            if (!isNaN(value)) {
              setValue(value)
            }
          }}
        />
      </div>
      <ConfirmButton
        type="primary"
        onClick={onChangeConfirm}
        confirmTitle={onConfirmTitle}
        confirmContent={onConfirmDescription}
        className={styles.confirmButton}
      >
        <span>{buttonTitle}</span>
      </ConfirmButton>
      {clearButtonTitle && (
        <ConfirmButton
          type="primary"
          onClick={() => {
            onClearConfirm()
            setValue(null)
          }}
          confirmTitle={onClearConfirmTitle}
          confirmContent={onClearConfirmDesc}
          className={styles.confirmButton}
        >
          <span>{clearButtonTitle}</span>
        </ConfirmButton>
      )}
    </div>
  )
}

export default InputConfirm
