import { Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import API from '../../api'
import DateRangeFilter from '../helper/DateRangeFilter'
import {useLoading, useQuery} from '../helper/Hooks'
import SearchDropdown from '../helper/searchDropdown/SearchDropdown'
import SBContent from '../helper/SidebarContent/'
import ProfitTransactionChart from '../statistics/ProfitTransactionChart'
import TransactionsByMonth from '../statistics/tables/TransactionsByMonth'
import styles from './dashboard.module.scss'
import useDashboardFilter from './hooks/DashboardFilterHook'

const { Option } = Select

export default function Dashboard () {
  const lang = useIntl()
  const categoryId = useQuery('category')
  const [dateRange, setDateRange] = useState({
    start: moment()
      .startOf('month')
      .subtract(11, 'month')
      .valueOf(),
    end: moment()
      .endOf('month')
      .valueOf()
  })
  const [categories, setCategories] = useState([])
  const {
    loading: categoriesLoading,
    startLoading: startCategoryLoading
  } = useLoading()

  const [filter, setFilter] = useState({
    managerId: '',
    categoryId: undefined
  })

  const [data, setData] = useState()
  const { loading: dataLoading, startLoading: startDataLoading } = useLoading()

  useEffect(() => {
    getCategories()
    setFilter({
      managerId: '',
      categoryId:  categoryId ?? undefined
    })
  }, [])

  useEffect(() => {
    getData()
  }, [dateRange, filter.managerId, filter.categoryId])

  const getCategories = () => {
    startCategoryLoading(async () => {
      const response = await API.get('/category')
      setCategories(response.data.result.data)
    })
  }

  const getData = () => {
    startDataLoading(async () => {
      const response = await API.get(
        `/statistics/profitwithTransactions?start=${dateRange.start}&end=${
          dateRange.end
        }&manager=${filter.managerId ? filter.managerId : ''}&category=${
          filter.categoryId ? filter.categoryId : ''
        }`
      )
      setData(response.data.result)
    })
  }

  return (
    <SBContent title={lang.formatMessage({ id: 'core_statistics' })}>
      <div className={styles.filterContainer}>
        <DateRangeFilter
          className={styles.dateFilter}
          onChange={setDateRange}
          defaultValue={'12'}
          intervals={[6, 12, 24]}
          includeDays={false}
          includeMonths={false}
        />
        <SearchDropdown
          placeholder={lang.formatMessage({ id: 'select_manager' })}
          url='/user/v2?roles=manager&blocked=false'
          searchUrl='/user/v2?roles=manager&blocked=false&name='
          onSelect={selectedValue =>
            setFilter({
              ...filter,
              managerId: selectedValue
            })
          }
          renderItem={item => ({
            key: item._id,
            value: item._id,
            name: item.fullname
          })}
          className={styles.filter}
        />
        <Select
          allowClear={true}
          loading={categoriesLoading}
          className={styles.filter}
          placeholder={lang.formatMessage({ id: 'select_category' })}
          showSearch
          onChange={e => {
            setFilter({ ...filter, categoryId: e })
          }}
          value={filter.categoryId}
          optionFilterProp='children'
          filterOption={(input, option) => {
            if (!option.children) {
              return true
            }
            return option.children.toLowerCase().includes(input.toLowerCase())
          }}
        >
          {categories.map(category => (
            <Option key={category._id} value={category._id}>
              {category.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.dataContainer}>
        <TransactionsByMonth dataSet={data} loading={dataLoading} />
        <ProfitTransactionChart dataSet={data} loading={dataLoading} />
      </div>
    </SBContent>
  )
}
