import React, { useEffect, useState } from 'react'
import { Collapse } from 'antd'
import API from '../../../api'
import ProductItem from './ProductItem'
import withMakeOrderBundle from '../../../provider/MakeOrderProductsProvider'
import OrderInfo from './OrderInfo'
import { useBundle } from '../../../provider/MakeOrderProductsProvider'
import SBContent from '../../helper/SidebarContent/'
import styles from './makeOrder.module.scss'
import {useIntl} from "react-intl";

const { Panel } = Collapse

function MakeOrder({ match }) {
  const userId = match.params.id

  const [categories, setCategories] = useState([])
  const { bundle, updateBundle } = useBundle()
  const lang = useIntl()

  const getProducts = async () => {
    const response = await API.get(`/product/categories?client_id=${userId}`)

    setCategories(response.data.result.categories)
  }

  const updateOrderBundle = (product) => {
    updateBundle(product)
  }

  useEffect(() => {
    getProducts()
  }, [])

  return (
    <SBContent title={lang.formatMessage({id: "make_order"})}>
      <div className={styles.makeOrderContainer}>
        <div>
          <OrderInfo userId={userId} />
        </div>
        <div>
          <Collapse accordion>
            {categories.map((category) => (
              <Panel
                header={category.category.name}
                key={category.category._id}
              >
                {category.products.map((product) => {
                  const bundleProduct = bundle[product.product._id]
                  return (
                    <ProductItem
                      product={bundleProduct ? bundleProduct : product.product}
                      onBundleChange={updateOrderBundle}
                      key={product.product._id}
                    />
                  )
                })}
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </SBContent>
  )
}

export default withMakeOrderBundle(MakeOrder)
