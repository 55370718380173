import React, { useEffect, useState } from 'react'
import { useLoading, useQuery } from '../helper/Hooks'
import moment from 'moment'
import { Select } from 'antd'
import API from '../../api'
import SBContent from '../helper/SidebarContent/'
import ManagerTransactionTable from '../statistics/tables/ManagerTransactionTable'
import ManagerProfitTransactionChart from '../statistics/ManagerProfitTransactionChart'
import styles from './dashboardManagers.module.scss'
import SearchDropdown from '../helper/searchDropdown/SearchDropdown'
import DateRangeFilter from '../helper/DateRangeFilter'
import { useIntl } from 'react-intl'

const { Option } = Select

export default function DashboardManagers({
  managerId,
  showManagerFilter = true,
  showTitle = true,
}) {
  const lang = useIntl()
  const time = useQuery('month')
  const [dateRange, setDateRange] = useState({
    start: '',
    end: '',
  })

  const [categories, setCategories] = useState([])
  const { loading: categoriesLoading, startLoading: startCategoryLoading } =
    useLoading()

  const [products, setProducts] = useState([])
  const { loading: productsLoading, startLoading: startProductLoading } =
    useLoading()

  const [filter, setFilter] = useState({
    managerId: '',
    categoryId: '',
    productId: undefined,
  })

  const [data, setData] = useState()
  const { loading: dataLoading, startLoading: startDataLoading } = useLoading()

  const getProducts = (categoryId) => {
    startProductLoading(async () => {
      const res = await API.get(
        `/product/filter?category=${categoryId ? categoryId : ''}`
      )
      setProducts(res.data.result.products)
    })
  }

  useEffect(() => {
    if (time) {
      const [month, year] = time.split('_')
      setDateRange({
        start: moment().month(month).year(year).startOf('month').valueOf(),
        end: moment().month(month).year(year).endOf('month').valueOf(),
      })
    } else {
      setDateRange({
        start: moment().startOf('month').valueOf(),
        end: moment().valueOf(),
      })
    }
    setFilter({ ...filter, managerId: managerId })
    getCategories()
    getProducts()
  }, [])

  useEffect(() => {
    if (dateRange.start && dateRange.end) {
      getData()
    }
  }, [dateRange, filter])

  const getCategories = () => {
    startCategoryLoading(async () => {
      const response = await API.get('/category')
      setCategories(response.data.result.data)
    })
  }

  const getData = () => {
    startDataLoading(async () => {
      const response = await API.get(
        `/statistics/profitwithTransactions/managers?start=${
          dateRange.start
        }&end=${dateRange.end}&manager=${
          filter.managerId ? filter.managerId : ''
        }&category=${filter.categoryId ? filter.categoryId : ''}&product=${
          filter.productId ? filter.productId : ''
        }`
      )
      setData(response.data.result)
    })
  }

  const content = (
    <div>
      <div className={styles.filterContainer}>
        <DateRangeFilter
          className={styles.dateFilter}
          onChange={setDateRange}
          defaultValue={
            time ? moment().month(time).format('MMMM') : moment().format('MMMM')
          }
          intervals={[6, 12]}
        />
        {showManagerFilter && (
          <SearchDropdown
            placeholder={lang.formatMessage({ id: 'select_manager' })}
            url="/user/v2?roles=manager&blocked=false"
            searchUrl="/user/v2?roles=manager&blocked=false&name="
            onSelect={(selectedValue) =>
              setFilter({
                ...filter,
                managerId: selectedValue,
              })
            }
            renderItem={(item) => ({
              key: item._id,
              value: item._id,
              name: item.fullname,
            })}
            className={styles.filter}
          />
        )}
        <Select
          allowClear={true}
          loading={categoriesLoading}
          className={styles.filter}
          placeholder={lang.formatMessage({ id: 'select_category' })}
          showSearch
          onChange={(e) => {
            setFilter({ ...filter, categoryId: e, productId: undefined })
            getProducts(e)
          }}
          optionFilterProp="children"
          filterOption={(input, option) => {
            if (!option.children) {
              return true
            }
            return option.children.toLowerCase().includes(input.toLowerCase())
          }}
        >
          {categories.map((category) => (
            <Option key={category._id} value={category._id}>
              {category.name}
            </Option>
          ))}
        </Select>

        <Select
          loading={productsLoading}
          allowClear={true}
          value={filter.productId}
          className={styles.productFilter}
          placeholder={lang.formatMessage({ id: 'select_product' })}
          showSearch
          onChange={(e) => {
            setFilter({ ...filter, productId: e })
          }}
          optionFilterProp="children"
          filterOption={(input, option) => {
            if (!option.children) {
              return true
            }
            return option.children.toLowerCase().includes(input.toLowerCase())
          }}
        >
          {products.map((product) => (
            <Option key={product._id} value={product._id}>
              {`(${product.product_id}) ${product.description}`}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.dataContainer}>
        <ManagerTransactionTable
          dataSet={data}
          loading={dataLoading}
          date={moment(dateRange.start).format('MMMM_YYYY')}
        />
        <ManagerProfitTransactionChart dataSet={data} loading={dataLoading} />
      </div>
    </div>
  )

  const constructScreen = () => {
    return showTitle ? (
      <SBContent title={lang.formatMessage({ id: 'manager_statistics' })}>
        {content}
      </SBContent>
    ) : (
      content
    )
  }

  return constructScreen()
}
