import React, {useEffect, useState} from "react";
import {useLoading} from "../helper/Hooks";
import API from "../../api";
import styles from "./order.module.scss";
import {Button, message, Modal, Table} from "antd";
import SBContent from "../helper/SidebarContent";
import SearchDropdown from "../helper/searchDropdown/SearchDropdown";
import {Link} from "react-router-dom";
import {formatNumber} from "../helper/Integer/IntegerFormatter";
import moment from "moment";
import ClientOrders from "./ClientOrders";
import {FormattedMessage, useIntl} from "react-intl";
import DateRangeFilter from "../helper/DateRangeFilter";
import {hasPermission} from "../../api/user";

const {confirm} = Modal

const DeliveredOrders = () => {
  const [postman, setPostman] = useState(undefined)
  const [dateRange, setDateRange] = useState({
    start: moment()
      .startOf('month')
      .valueOf(),
    end: moment().valueOf()
  })
  const [selectedOrders, setSelectedOrders] = useState([])
  const [selectedOrder, setSelectedOrder] = useState()
  const [data, setData] = useState({
    totalPrice: 0,
    totalPaid: 0,
    orders: []
  })
  const {loading, startLoading} = useLoading()
  const {
    loading: assignLoading,
    startLoading: startAssignLoading,
  } = useLoading()
  const lang = useIntl()
  const [columns, setColumns] = useState([])

  useEffect(() => {
    if(dateRange.start && dateRange.end){
      getOrders()
    }
  }, [postman, dateRange])

  const updateOrders = async () => {
    await API.post(`delivery/verifyOrders`, {
      orders: selectedOrders
    })
    setPostman(undefined)
    setSelectedOrders([])
    getOrders()
  }

  const getOrders = () => {
    startLoading(async () => {
      const response = await API.get(`/order/delivered?postman=${postman ? postman : ''}&start=${
        dateRange.start
      }&end=${dateRange.end}`)
      setData(response.data.result)
      setupColumns('֏')
    })
  }

  const onVerify = () => {
    confirm({
      title: lang.formatMessage({id: "verify_orders_question"}),
      async onOk() {
        try {
          await updateOrders()
        } catch (err) {
          message.error(err.message)
        }
      },
    })
  }

  const setupColumns = (currency) => {
    const columns = [
      {
        title: lang.formatMessage({id: "postman"}),
        dataIndex: 'agent',
        render: (user) =>
          user ? (
            <Link
              to={{
                pathname: `/view/user/${user._id}`,
              }}
            >
              {user.name}
            </Link>
          ) : (
            ''
          ),
      },
      {
        title: lang.formatMessage({id: "client"}),
        dataIndex: 'client',
        render: (user) =>
          user ? (
            <Link
              to={{
                pathname: `/view/user/${user._id}`,
              }}
            >
              {user.fullname}
            </Link>
          ) : (
            ''
          ),
      },
      {
        title: lang.formatMessage({id: "date"}),
        dataIndex: 'finished_date',
        render: (date) => moment(date).format('DD/MM/YYYY HH:mm:ss'),
        sorter: (a, b) => {
          if (!a.finished_date) {
            return -1
          } else if (!b.finished_date) {
            return 1
          }
          return a.finished_date - b.finished_date
        },
        defaultSortOrder: 'descend',
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: lang.formatMessage({id: "total_price"}),
        dataIndex: 'total_price',
        render: (price) => formatNumber(price, currency),
      },
      {
        title: lang.formatMessage({id: "paid_amount"}),
        dataIndex: 'payed_amount',
        render: (amount) => formatNumber(amount, currency)
      }
    ]

    if (hasPermission('admin_pending_profit')) {
      columns.push(
        {
          title: lang.formatMessage({id: 'profit'}),
          dataIndex: 'profit',
          render: (profit) => formatNumber(profit, currency)
        }
      )
    }
    columns.push({
        title: lang.formatMessage({id: "comment"}),
        dataIndex: 'comment',
        render: (text) => (
          <div
            style={{
              height: 30,
              overflowY: 'auto',
            }}
          >
            {text ? text : '-'}
          </div>
        ),
      })
    setColumns(columns)
  }

  return (
    <SBContent title={lang.formatMessage({id: "delivered_orders"})}>
      <div className={styles.orderContainer}>
        <div className={styles.orderFilterContainer}>
          <SearchDropdown
            value={postman}
            placeholder={lang.formatMessage({id: "postman"})}
            style={{marginBottom: 10}}
            width={200}
            url='/user/v2?roles=postman&blocked=false'
            searchUrl='/user/v2?roles=postman&blocked=false&name='
            onSelect={(selectedValue) => setPostman(selectedValue)}
            renderItem={(item) => ({
              key: item._id,
              value: item._id,
              name: item.fullname,
            })}
          />
          <Button
            type='primary'
            disabled={selectedOrders.length === 0}
            onClick={onVerify}
          >
            <FormattedMessage id={"verify_orders"} />
          </Button>

          <DateRangeFilter
            className={styles.dateFilter}
            onChange={setDateRange}
            defaultValue={moment().format('MMMM')}
            intervals={[3, 6, 12]}
          />
        </div>
        <div>
          <Table
            showSorterTooltip={false}
            title={() => (
              <div className={styles.deliveredOrdersTotals}>
                <div>
                  <strong><FormattedMessage id={"total_price"}/>: {formatNumber(data.totalPrice, '֏')}</strong>
                  <strong><FormattedMessage id={"total_amount_paid"}/>: {formatNumber(data.totalPaid, '֏')}</strong>
                  {data.totalPrice - data.totalPaid > 0 && (
                    <strong><FormattedMessage id={"total_leftover"}/>: {formatNumber(data.totalPrice - data.totalPaid, '֏')}</strong>
                  )}
                </div>
              </div>
            )}
            bordered={true}
            loading={loading}
            rowSelection={{
              selectedRowKeys: selectedOrders,
              onChange: (selectedRowKeys) => {
                setSelectedOrders(selectedRowKeys)
              },
            }}
            columns={columns}
            dataSource={data.orders}
            rowKey={(element) => element._id}
            onRow={(element) => {
              return {
                onClick: () => {
                  setSelectedOrder(element)
                },
              }
            }}
            rowClassName={styles.rowHoverPointer}
            pagination={{
              defaultPageSize: 10,
              total: data.orders.length,
              position: ['bottomCenter'],
            }}
          />
        </div>
        <ClientOrders
          visible={selectedOrder}
          selectedOrder={selectedOrder}
          onCancel={(shouldRefresh) => {
            setSelectedOrder(undefined)
            if (shouldRefresh) {
              setPostman(undefined)
              getOrders()
            }
          }}
        />
      </div>
    </SBContent>
  )
}

export default DeliveredOrders