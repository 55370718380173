import { Alert, List, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import API from '../../api'
import { useSettings } from '../helper/context/SettingsContext'
import { useLoading } from '../helper/Hooks'
import styles from './onboarding.module.scss'

const { info } = Modal

const Onboarding = () => {
  const [steps, setSteps] = useState([])
  const { loading, startLoading } = useLoading()
  const { refreshSettings } = useSettings()
  const lang = useIntl()

  useEffect(() => {
    startLoading(async () => {
      const response = await API.get('/settings/onboarding')
      const result = response.data.result
      if (result.isCompleted && result.items.length > 0) {
        refreshSettings()
      } else {
        setSteps(result.items)
      }
    })
  }, [])

  const onAlertClick = (title, content) => {
    info({
      title: title,
      content: (
        <p style={{ whiteSpace: 'pre-line', lineHeight: '150%' }}>{content}</p>
      ),
      width: 600,
      okText: lang.formatMessage({ id: 'ok' }),
      maskClosable: true
    })
  }

  return (
    steps.length > 0 && (
      <div>
        <List
          loading={loading}
          size='small'
          bordered
          header={
            <h3>
              <FormattedMessage id='should_complete' />{' '}
            </h3>
          }
          className={styles.listContainer}
          dataSource={steps}
          renderItem={step => (
            <Alert
              message={lang.formatMessage({ id: step.title })}
              className={step.isDone ? styles.stepCompleted : styles.step}
              type={step.isDone ? 'success' : 'info'}
              showIcon
              onClick={() => {
                onAlertClick(
                  lang.formatMessage({ id: step.title }),
                  lang.formatMessage({ id: step.description })
                )
              }}
            />
          )}
        />
      </div>
    )
  )
}

export default Onboarding

// [
//   {
//     title: 'create_client',
//     isDone: true,
//     description: 'create_client_description'
//   },
//   {
//     title: 'create_manager',
//     isDone: true,
//     description: 'create_manager_description'
//   },
//   {
//     title: 'create_group',
//     isDone: false,
//     description: 'create_group_description'
//   },
//   {
//     title: 'assign_client_to_group',
//     isDone: true,
//     description: 'assign_client_to_group_description'
//   },
//   {
//     title: 'create_product',
//     isDone: false,
//     description: 'create_product_description'
//   },
//   {
//     title: 'add_product_to_client',
//     isDone: false,
//     description: 'add_product_to_client_description'
//   },
//   {
//     title: 'add_product_to_manager',
//     isDone: true,
//     description: 'add_product_to_manager_description'
//   },
//   {
//     title: 'create_admin',
//     isDone: true,
//     description: 'create_admin_description'
//   }
// ]
