import React, { useState } from 'react'
import styles from './info.module.scss'
import { Select } from 'antd'
import { useIntl } from 'react-intl'
import UserOrderHistoryItem from './UserOrderHistoryItem'
import ClientOrders from '../../order/ClientOrders'
import InfiniteScrollWrapper from '../../helper/InfiniteScroll'
import SearchDropdown from '../../helper/searchDropdown/SearchDropdown'
import moment from 'moment'
import { isManager, isPostman, isUserClient } from '../../../api/user'
import DateRangeFilter from '../../helper/DateRangeFilter'

const { Option } = Select

export default function UserOrderHistory({ user }) {
  const [selectedOrder, setSelectedOrder] = useState()
  const lang = useIntl()
  const [filter, setFilter] = useState({
    fromUser: '',
    status: '',
    start: moment().startOf('month').valueOf(),
    end: moment().valueOf(),
    orderType: '',
  })

  const userFilterUrl = `/user?role=${
    isManager(user)
      ? '5ebabd29edf1357edbff3a44'
      : isUserClient(user)
      ? '5ebabd32edf1357edbff3a45'
      : ''
  }`

  //TODO make request to get order status list
  const statuses = [
    'PENDING',
    'APPROVED',
    'PICKED',
    'FINISHED',
    'ARCHIVED',
    'DELIVERED',
  ]

  const orderTypes = ['STANDARD', 'INVOICE', 'RETURN']

  const onDateChange = ({ start, end }) => {
    setFilter({
      ...filter,
      start: start,
      end: end,
    })
  }

  return (
    <div>
      <div className={styles.filterContainer}>
        <DateRangeFilter
          style={{ width: 200, marginBottom: 10 }}
          placeholder={lang.formatMessage({ id: 'time' })}
          onChange={onDateChange}
          defaultValue={moment().format('MMMM')}
          intervals={[3, 6, 12, 48]}
        />
        <Select
          placeholder={lang.formatMessage({ id: 'status' })}
          allowClear={true}
          style={{ width: 200, marginBottom: 10 }}
          onChange={(e) => {
            setFilter({
              ...filter,
              status: e ? e : '',
            })
          }}
        >
          {statuses.map((status) => {
            return (
              <Option key={status} value={status}>
                {status}
              </Option>
            )
          })}
        </Select>
        <SearchDropdown
          placeholder={`${lang.formatMessage({ id: 'order' })} ${
            isManager(user)
              ? lang.formatMessage({ id: 'client' })
              : isUserClient(user)
              ? lang.formatMessage({ id: 'manager' })
              : ''
          }`}
          width={200}
          url={userFilterUrl}
          searchUrl={`${userFilterUrl}&name=`}
          onSelect={(selectedValue) =>
            setFilter({
              ...filter,
              fromUser: selectedValue ? selectedValue : '',
            })
          }
          renderItem={(item) => ({
            key: item._id,
            value: item._id,
            name: item.fullname,
          })}
        />
        <Select
          placeholder={lang.formatMessage({ id: 'order_type' })}
          allowClear={true}
          style={{ width: 200, marginBottom: 10 }}
          onChange={(e) => {
            setFilter({
              ...filter,
              orderType: e ? e : '',
            })
          }}
        >
          {orderTypes.map((type) => {
            return (
              <Option key={type} value={type}>
                {type}
              </Option>
            )
          })}
        </Select>
      </div>

      <div className={styles.orderList}>
        <InfiniteScrollWrapper
          useWindow={false}
          url={`order/historyByRole?user=${user._id}&role=${
            user.role.name
          }&start=${filter.start}&end=${filter.end}&status=${
            filter.status
          }&order_type=${filter.orderType}${
            isManager(user)
              ? `&client=${filter.fromUser}`
              : isUserClient(user)
              ? `&manager=${filter.fromUser}`
              : ''
          }`}
          processResult={(data) => data.orders}
          render={(item) => (
            <UserOrderHistoryItem
              key={item._id}
              item={item}
              user={user}
              onClick={setSelectedOrder}
            />
          )}
        />
        <ClientOrders
          visible={selectedOrder}
          selectedOrder={selectedOrder}
          onCancel={() => {
            setSelectedOrder(undefined)
          }}
        />
      </div>
    </div>
  )
}
