import React from "react"
import { Card, Divider, List, Typography } from "antd"
import { useIntl, FormattedMessage } from "react-intl"
import { formatNumber } from "../helper/Integer/IntegerFormatter"
import moment from "moment"

const ProductHistoryListItem = ({ item, currentProduct }) => {
  const language = useIntl()
  return (
    <Card
      key={item._id}
      style={{ marginTop: 10, border: "2px solid #00000033" }}
      bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
    >
      <List
        dataSource={
          currentProduct
            ? item.products.filter((e) => {
                return e.product._id === currentProduct
              })
            : item.products
        }
        renderItem={(product) => (
          <List.Item key={product._id}>
            <List.Item.Meta
              title={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <strong>
                    {" "}
                    {product.product
                      ? `(${product.product.product_id}) / ${product.product.description}`
                      : language.formatMessage({ id: "product_was_removed" })}
                  </strong>
                  <strong>
                    <FormattedMessage id="status" />:{" "}
                    <FormattedMessage id={item.type} />
                  </strong>
                </div>
              }
              description={
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography.Text>
                        <FormattedMessage id="from" />: {item.register.fullname}
                      </Typography.Text>
                      {item.receiver && (
                        <Typography.Text>
                          <FormattedMessage id="to" />: {item.receiver.fullname}
                        </Typography.Text>
                      )}
                      {!isNaN(product.amount) && (
                        <div style={{ marginLeft: 20 }}>
                          <Typography.Text>
                            {`${
                              product.amount > 0
                                ? language.formatMessage({ id: "entered" })
                                : language.formatMessage({ id: "withdrawed" })
                            }: ${formatNumber(Math.abs(product.amount))}`}
                          </Typography.Text>
                        </div>
                      )}
                      {!isNaN(product.user_on_hand) && item.register && (
                        <div style={{ marginLeft: 20 }}>
                          <Typography.Text>
                            {`${item.register.name} on hand: ${formatNumber(
                              product.user_on_hand
                            )}`}
                          </Typography.Text>
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text>
                        <div>
                          <span>
                            <FormattedMessage id="warehouse" />
                          </span>
                          <span>:</span>
                          <span>{formatNumber(product.total)}</span>
                        </div>
                      </Typography.Text>
                      {(product.on_hand || product.on_hand === 0) && (
                        <Typography.Text>
                          <div>
                            <span>
                              <FormattedMessage id="on_hand" />
                            </span>
                            <span>:</span>
                            <span>{formatNumber(product.on_hand)}</span>
                          </div>
                        </Typography.Text>
                      )}
                      {(product.reserved || product.reserved === 0) && (
                        <div>
                          <Typography.Text>
                            {language.formatMessage({ id: "reserved" }) +
                              ": " +
                              formatNumber(product.reserved)}
                          </Typography.Text>
                        </div>
                      )}
                    </div>
                  </div>
                  <Divider
                    style={{
                      width: "65%",
                      minWidth: 0,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "flex-end",
                      }}
                    >
                      <Typography.Text>{`${language.formatMessage({
                        id: "date",
                      })}: ${moment(item.date).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}`}</Typography.Text>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {!isNaN(product.amount) &&
                        product.old_total !== product.total && (
                          <div style={{ textAlign: "right" }}>
                            <Typography.Text>
                              {formatNumber(
                                product.amount,
                                "",
                                product.amount > 0 ? "+" : ""
                              )}
                            </Typography.Text>
                          </div>
                        )}
                      <div>
                        <Typography.Text>
                          {`${language.formatMessage({
                            id: "old_warehouse",
                          })}: ${formatNumber(product.old_total)}`}
                        </Typography.Text>
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  )
}

export default ProductHistoryListItem
