import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Input, message, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { default as api, default as API } from '../../../../api'
import {hasPermission, isSuperAdmin, isUserAdmin, isUserClient, isUserSuperAdmin} from '../../../../api/user'
import Map from '../../../map/Map'
import ManagerTotal from '../ManagerTotals'
import UserCard from '../UserCard'
import styles from './userInfo.module.scss'
import {useLoading} from "../../../helper/Hooks"
import Limits from "../Limits"

const { confirm } = Modal
const { TextArea } = Input

const canEditUser = (user) => {
  const hasEditPermission = hasPermission('user_update')
  const hasClientEditPermission = hasPermission('client_info_update')
  return (
    hasEditPermission ||
    (hasClientEditPermission && user.role.name === 'client')
  )
}
const UserInfo = ({
  user,
  userLoading,
  getUser,
  isManager,
  hasPermission,
  isLoggedInUser,
  ...props
}) => {
  const [blockLoading, setBlockLoading] = useState(false)
  const [comment, setComment] = useState()
  const [commentLoading, setCommentLoading] = useState(false)
  const [totalClients, setTotalClients] = useState()
  const [sendNotificationVisible, setSendNotificationVisible] = useState(false)
  const [notificationText, setNotificationText] = useState('')
  const {loading, startLoading} = useLoading()
  const [locationEditEnabled, setLocationEditEnabled] = useState()
  const {loading: locationEditLoading, startLoading: startLocationEditLoading} = useLoading()

  const language = useIntl()

  useEffect(() => {
    if (user) setComment(user.comment)
  }, [user])

  useEffect(() => {
    if (user) setLocationEditEnabled(user.settings ? user.settings.editLocation : false)
  }, [user])

  useEffect(() => {
    if (isManager(user)) {
      getTotalClients()
    }
  }, [])

  const onUserEditLocationUpdate = (checked) => {
    if (!locationEditLoading) {
      startLocationEditLoading(
        async () => {
          try {
            await API.post('/user/updateUserSettings', {
              user: user._id,
              settings: {
                editLocation: checked
              }
            })
            setLocationEditEnabled(checked)
          } catch (e) {
            message.error(e.message)
          }
        }
      )
    }
  }

  const showBlockPopup = () =>
    confirm({
      title: `${
        user.blocked
          ? language.formatMessage({ id: 'unblock' })
          : language.formatMessage({ id: 'block' })
      } ${language.formatMessage({ id: 'user' })}`,
      icon: <ExclamationCircleOutlined />,
      content: `${
        user.blocked
          ? language.formatMessage({ id: 'unblock_user_question' })
          : language.formatMessage({ id: 'block_user_question' })
      }`,
      onOk: async () => {
        try {
          setBlockLoading(true)
          const response = await api.put(
            `/user/${user.blocked ? 'unblock' : 'block'}/${user._id}`
          )

          setBlockLoading(false)
          message.success(
            user.blocked
              ? language.formatMessage({ id: 'user_unblocked' })
              : language.formatMessage({ id: 'user_blocked' })
          )
          getUser()
        } catch (error) {
          const key = error.response.data.key
          if (key) {
            message.error(language.formatMessage({ _id: key }))
          } else {
            message.error(language.formatMessage({ _id: "something_wrong" }))
          }
        }
      },
      onCancel: () => {},
      confirmLoading: blockLoading,
    })

  const getTotalClients = async () => {
    const response = await API.get(`/manager/${user._id}/clients/count`)
    setTotalClients(response.data.result)
  }

  const mapToMarker = () => {
    return {
      type: 'Feature',
      properties: {
        title: user.fullname,
        placeData: user,
        icon: 'shop-15-yellow',
      },
      geometry: {
        type: 'Point',
        coordinates: [user.location.long, user.location.lat],
      },
    }
  }

  const sendNotification = (sendAll) => {
    startLoading(async () => {
      try {
        await API.post('/notification/user', {
          user_id: user._id,
          content: notificationText,
          sendToAll: sendAll
        })
        closeSendNotificationModal()
      }catch (e) {
        if (e.response) {
          message.error(e.response.data.message)
        } else {
          message.error(e.message)
        }
      }
    })
  }

  const closeSendNotificationModal = () => {
    setSendNotificationVisible(false)
    setNotificationText('')
  }

  return (
    <div {...props}>
      {isManager(user) && (
        <h3>
          <FormattedMessage id='total_clients' /> : {totalClients}
        </h3>
      )}
      <div className={styles.container}>
        <UserCard
          user={user}
          loading={userLoading}
          isLoggedInUser={isLoggedInUser}
        />
        {user.role.name === 'client' && (
          <div
            style={{
              display: 'flex',
              width: 360,
              flexDirection: 'column',
            }}
            className={styles.contentItem}
          >
            <b>
              <FormattedMessage id='comment' />:
            </b>
            <TextArea
              style={{ marginTop: 5 }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
            <Button
              onClick={async () => {
                setCommentLoading(true)
                await api.post('/manager/comment', {
                  client_id: user._id,
                  text: comment,
                })
                setCommentLoading(false)
              }}
              style={{ marginLeft: 'auto', marginTop: 10 }}
              loading={commentLoading}
            >
              <FormattedMessage id='btn_comment' />
            </Button>
          </div>
        )}
        {isManager(user) && hasPermission('manager_profit') && user._id && (
          <ManagerTotal userId={user._id} className={styles.contentItem} />
        )}
        {(isUserClient(user) || isManager(user)) && hasPermission("admin_user_limits") && (
          <Limits user={user} className={styles.limitInfo} />
        )}
      </div>

      <div>
        {!isUserSuperAdmin(user) && !isUserAdmin(user) && (
          <Button type='primary' style={{ marginTop: 20, marginRight: 10 }} onClick={() => setSendNotificationVisible(true)}>
              <FormattedMessage id='send_notification' />
          </Button>
        )}
        {isUserClient(user) && (
          <Button type='primary' style={{ marginTop: 20, marginRight: 10 }}>
            <Link key='list-makeorder-view' to={`/user/make-order/${user._id}`}>
              <FormattedMessage id='make_order' />
            </Link>
          </Button>
        )}
        {canEditUser(user) && (
          <>
            <Button type='primary'>
              <Link to={`/edit/user/${user._id}`}>
                <FormattedMessage id='edit' />
              </Link>
            </Button>
            {(isSuperAdmin() || !isLoggedInUser) &&
              hasPermission('user_block_unblock') && (
                <Button
                  onClick={showBlockPopup}
                  style={{ marginTop: 20, marginLeft: 10 }}
                  type='danger'
                >
                  {user.blocked ? (
                    <FormattedMessage id='unblock' />
                  ) : (
                    <FormattedMessage id='block' />
                  )}
                </Button>
              )}
            {isUserClient(user) && hasPermission("client_edit_location") && (
             <Checkbox style={{marginTop: 20, marginLeft: 10}}
                       onChange={e => onUserEditLocationUpdate(e.target.checked)}
                       checked={locationEditEnabled}>
               <FormattedMessage id={"can_edit_user_location"}/>
             </Checkbox>
            )}
          </>
        )}
      </div>
      {isUserClient(user) && (
        <div>
          <Map
            markers={[mapToMarker()]}
            center={user.location}
            isMarkerClickable={false}
            className={styles.mapContainer}
            moveToMarker={true}
          />
        </div>
      )}
      <Modal
        visible={sendNotificationVisible}
        title={language.formatMessage({id: 'send_notification'})}
        onOk={sendNotification}
        onCancel={closeSendNotificationModal}
        footer={[
          <Button key="cancel" onClick={closeSendNotificationModal}>
            <FormattedMessage id='cancel' />
          </Button>,
          <Button key="send" type="primary" onClick={() => sendNotification(false)} disabled={!notificationText} loading={loading}>
            <FormattedMessage id='send' />
          </Button>,
          <Button
            key="send_all"
            type="primary"
            disabled={!notificationText}
            loading={loading}
            onClick={() => sendNotification(true)}
          >
            <FormattedMessage id='send_all' />
          </Button>,
        ]}
      >
        <TextArea
          rows={4}
          value={notificationText}
          onChange={(e) => setNotificationText(e.target.value)}
          placeholder={language.formatMessage({ id: 'notification_text' })}
        />
      </Modal>
    </div>
  )
}

export default UserInfo
