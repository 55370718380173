import { useFetcher } from "../../helper/Fetcher"

export default function useClientCategories(url) {
  const { data, isLoading } = useFetcher(url)

  return {
    categories: !isLoading ? data.data : undefined,
    isCategoriesLoading: isLoading,
  }
}
