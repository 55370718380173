import {DatePicker, Select} from 'antd'
import moment from 'moment'
import React, {useEffect, useRef, useState} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

const { Option, OptGroup } = Select

const DateRangeFilter = ({
  className,
  intervals,
  onChange,
  defaultValue,
  includeMonths = true,
  includeDays = true,
  ...props
}) => {
  const lang = useIntl()
  const [dates, setDates] = useState({
    datepicker: undefined,
    select: defaultValue
  })

  const firstUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
    } else {
      handleDayChange(dates.datepicker)
    }
  }, [dates.datepicker])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
    } else {
      handleChange(dates.select)
    }
  }, [dates.select])

  const handleChange = e => {
    if(!e) {
      console.warn(`date is undefined`)
      return
    }
    if (!isNaN(e)) {
      let value = parseInt(e)
      onChange({
        start: moment()
          .startOf('month')
          .subtract(value === 1 ? value : value - 1, 'month')
          .valueOf(),
        end: moment()
          .endOf('month')
          .valueOf()
      })
    } else if (RegExp('^[0-9]*-[0-9]{4}$').test(e)) {
      const [month, year] = e.split('-')
      const value = moment()
        .year(parseInt(year))
        .month(parseInt(month) - 1)
      onChange({
        start: value.startOf('month').valueOf(),
        end: value.endOf('month').valueOf()
      })
    } else if (e === 'today') {
      const value = moment()
      onChange({
        start: value.startOf('day').valueOf(),
        end: value.endOf('day').valueOf()
      })
    } else if (e === 'yesterday') {
      const value = moment().subtract(1, 'day')
      onChange({
        start: value.startOf('day').valueOf(),
        end: value.endOf('day').valueOf()
      })
    } else {
      const value = moment()
        .month(e)
        .startOf('month')
      onChange({
        start: value.valueOf(),
        end: value.endOf('month').valueOf()
      })
    }
  }

  const handleDayChange = e => {
    if(!e) {
      console.warn(`day date is undefined`)
      return
    }
    onChange({
      start: e.startOf("day").valueOf(),
      end: e.endOf('day').valueOf()
    })
  }

  const getIntervals = () => {
    let intervalOptions = []
    intervals.forEach(interval => {
      intervalOptions.push(
        <Option key={interval.toString()} value={interval.toString()}>
          {`${interval.toString()} ${lang.formatMessage({ id: 'months' })}`}
        </Option>
      )
    })

    return intervalOptions
  }

  const getLastYearMonths = (
    numberOfMonths = 4,
    year = moment()
      .subtract(1, 'year')
      .format('YYYY')
  ) => {
    let months = []
    for (let i = 11; i > 11 - numberOfMonths; i--) {
      const monthKey = moment()
      .month(i)
      .format('M')
      const monthName = moment()
      .month(i)
      .format('MMMM')
      months.push(
        <Option key={`${monthKey}-${year}`} value={`${monthKey}-${year}`}>
          {monthName}
        </Option>
      )
    }
    return months
  }

  const getMonths = () => {
    let months = []
    moment.months().map(month => {
      if (
        moment().month() >=
        moment()
          .month(month)
          .month()
      ) {
        months.push(
          <Option key={month} value={month}>
            {month}
          </Option>
        )
      }
    })
    return months.reverse()
  }

  return (
    <>
      <Select
        value={dates.select}
        className={className}
        onChange={(e) => {
          setDates({
            datepicker: undefined,
            select: e
          })
        }}
        {...props}
      >
        {includeDays && (
          <OptGroup label='Days'>
            <Option key={`today`} value={`today`}>
              {' '}
              <FormattedMessage id='today'/>{' '}
            </Option>
            <Option key={`yesterday`} value={`yesterday`}>
              {' '}
              <FormattedMessage id='yesterday'/>{' '}
            </Option>
          </OptGroup>
        )}

        {includeMonths && (
          <OptGroup label={lang.formatMessage({id: 'months'})}>
            {getMonths()}
          </OptGroup>
        )}
        {intervals && (
          <OptGroup label={lang.formatMessage({id: 'intervals'})}>
            {getIntervals()}
          </OptGroup>
        )}
        {includeMonths && (
          <OptGroup
            label={`${lang.formatMessage({id: 'months'})} (${moment()
              .subtract(1, 'year')
              .format('YYYY')})`}
          >
            {getLastYearMonths()}
          </OptGroup>
        )}
      </Select>
      <DatePicker onChange={(value) => {
        setDates({
          datepicker: value ?? undefined,
          select: value ? undefined : defaultValue
        })
      }}  value={dates.datepicker}/>
    </>
  )
}

export default DateRangeFilter
