import React from 'react'
import {Card} from 'antd'
import {IntTextFormatter} from "../helper/Integer/IntegerFormatter";
import {FormattedMessage} from "react-intl";

export default function TotalCard({
  totalProfit,
  totalTransactions,
  totalExpense,
  balance,
  className,
}) {
  const expense = totalExpense || totalExpense === 0
  const cardSize = 100.0 / (expense ? 3 : 2)
  const gridStyle = {
    align: 'center',
    boxShadow: 'none',
    textAlign: 'center',
    padding: 6,
    width: `${cardSize}%`,
  }

  const spanStyle = {
    fontSize: '100%',
    fontWeight: 'bold',
  }
  return (
    <Card title={<strong><FormattedMessage id='totals' /></strong>} className={className}>
      <Card.Grid style={gridStyle} hoverable={false}>
        <span style={spanStyle}>
          <FormattedMessage id='transactions' />:
          <br />
          <IntTextFormatter currencyText={balance.currency_text} number={totalTransactions}/>
        </span>
      </Card.Grid>
      <Card.Grid style={gridStyle} hoverable={false}>
        <span style={spanStyle}>
          <FormattedMessage id='profit' />:
          <br />
          <IntTextFormatter currencyText={balance.currency_text} number={totalProfit}/>
        </span>
      </Card.Grid>
      {expense && (
        <Card.Grid style={gridStyle} hoverable={false}>
          <span style={spanStyle}>
          <FormattedMessage id='expenses' />:
            <br />
            <IntTextFormatter currencyText={balance.currency_text} number={totalExpense}/>
          </span>
        </Card.Grid>
      )}
    </Card>
  )
}
