import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import API from '../../../api'
import { useSettings } from '../../helper/context/SettingsContext'
import { useLoading } from '../../helper/Hooks'
import { IntTextFormatter } from '../../helper/Integer/IntegerFormatter'
import styles from '../statistics.module.css'

export default function GroupTransactionTable ({
  dateRange,
  filter: { managerId, categoryId, productId }
}) {
  const lang = useIntl()
  const [data, setData] = useState({
    items: [],
    balance: { currency: '', currency_text: '' }
  })
  const { loading, startLoading } = useLoading()
  const [columns, setColumns] = useState([])
  const { returnEnabled } = useSettings()

  const getData = async () => {
    startLoading(async () => {
      if (!managerId) {
        setData({
          items: [],
          balance: { currency: '', currency_text: '' }
        })
        setupTableColumns('')
        return
      }
      const response = await API.get(
        `/statistics/profitwithTransactions/manager/groups?start=${
          dateRange.start
        }&end=${dateRange.end}&manager=${managerId ? managerId : ''}&category=${
          categoryId ? categoryId : ''
        }&product=${productId ? productId : ''}`
      )

      const {
        balance,
        chartData: { items }
      } = response.data.result

      const { graphItems } = items.reduce(
        (data, el) => {
          const dataItem = {
            group: el.group,
            profit: el.profit,
            transaction: el.transaction,
            profitPercentage: el.profitPercentage
          }
          if (returnEnabled) {
            data.graphItems.push({
              ...dataItem,
              returnTransaction: el.returnTransaction
            })
          } else {
            data.graphItems.push(dataItem)
          }
          return data
        },
        { graphItems: [] }
      )
      setData({ ...data, items: graphItems, balance: balance })
      setupTableColumns(balance.currency_text)
    })
  }

  const setupTableColumns = currency => {
    const columns = [
      {
        title: lang.formatMessage({ id: 'group' }),
        dataIndex: 'group',
        render: group => group.name,
        width: 200,
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.group.name.localeCompare(b.group.name),
        sortDirections: ['descend', 'ascend']
      },
      {
        title: lang.formatMessage({ id: 'transaction' }),
        dataIndex: 'transaction',
        render: transaction => (
          <IntTextFormatter currencyText={currency} number={transaction} />
        ),
        sorter: (a, b) => a.transaction - b.transaction,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: lang.formatMessage({ id: 'profit' }),
        dataIndex: 'profit',
        render: profit => (
          <IntTextFormatter currencyText={currency} number={profit} />
        ),
        sorter: (a, b) => a.profit - b.profit,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: lang.formatMessage({ id: 'profit_percentage' }),
        dataIndex: 'profitPercentage',
        render: profitPercentage =>
          `${profitPercentage ? profitPercentage + '%' : '-'}`,
        sorter: (a, b) => {
          if (!a.profitPercentage) {
            return -1
          } else if (!b.profitPercentage) {
            return 1
          }
          return a.profitPercentage - b.profitPercentage
        },
        sortDirections: ['descend', 'ascend']
      }
    ]
    if (returnEnabled) {
      columns.push({
        title: lang.formatMessage({ id: 'return_transaction' }),
        dataIndex: 'returnTransaction',
        render: returnTransaction => (
          <IntTextFormatter
            currencyText={currency}
            number={returnTransaction ?? 0}
          />
        ),
        sorter: (a, b) => a.returnTransaction - b.returnTransaction,
        sortDirections: ['descend', 'ascend']
      })
    }
    setColumns(columns)
  }

  useEffect(() => {
    getData()
  }, [dateRange, managerId, categoryId, productId])

  return columns.length > 0 ? (
    <Table
      showSorterTooltip={false}
      size='small'
      loading={loading}
      bordered={true}
      className={styles.statisticsTables}
      columns={columns}
      rowKey={element => element.group._id}
      rowClassName={styles.rowHoverPointer}
      pagination={{
        defaultPageSize: 9,
        total: data.items.length,
        position: ['bottomCenter']
      }}
      dataSource={data.items}
    />
  ) : ''
}
