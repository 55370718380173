import React from 'react'
import { useContext } from 'react'

const UserContext = React.createContext({ user: undefined })

export const useUser = () => {
  return useContext(UserContext)
}

export default UserContext
