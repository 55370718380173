import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Select,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import API from '../../api'
import { useLoading } from '../helper/Hooks'
import SearchDropdown from '../helper/searchDropdown/SearchDropdown'
import styles from './expense.module.scss'

const { TextArea } = Input

const { Option } = Select

export default function AddExpense({ onExpenseAdd }) {
  const lang = useIntl()
  const [updating, setUpdating] = useState(false)
  const [updatingType, setUpdatingType] = useState(false)
  const [types, setTypes] = useState([])
  const { loading: typesLoading, startLoading: startTypeLoading } = useLoading()
  const [typeToAdd, setTypeToAdd] = useState()
  const [selectedUser, setSelectedUser] = useState()

  const [form] = Form.useForm()

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    getExpenseTypes()
    getExpenses()
  }

  const getExpenses = async () => {
    await API.get(`/expense`)
  }
  const getExpenseTypes = async () => {
    startTypeLoading(async () => {
      const response = await API.get(`/expense/type`)
      setTypes(response.data.result)
    })
  }

  const addType = async (typeName) => {
    if (!typeName)
      message.error(lang.formatMessage({ id: 'expense_type_not_empty' }))
    try {
      const response = await API.post(`/expense/type`, { name: typeName })
      const newType = response.data.result
      setTypeToAdd(undefined)
      setTypes([...types, newType])
    } catch (e) {
      message.error(e.response.data.message)
    }
    setUpdatingType(false)
  }

  const onFinish = async (values) => {
    setUpdating(true)
    try {
      console.log('#art', selectedUser)
      const response = await API.post(`/expense`, {
        ...values,
        user: selectedUser,
      })
      let expense = response.data.result
      onExpenseAdd(expense)
      form.setFieldsValue({
        type: undefined,
        user: undefined,
        price: 1,
        description: undefined,
      })
      setSelectedUser(undefined)
    } catch (e) {}
    setUpdating(false)
  }

  return (
    <Card className={styles.addExpenseCard}>
      <Form form={form} onFinish={onFinish} className={styles.formContainer}>
        <div>
          <Form.Item>
            <Divider orientation="left">
              <FormattedMessage id="add_expense_type" />
            </Divider>
            <Alert
              message={lang.formatMessage({ id: 'type_warning' })}
              type="warning"
              style={{ marginBottom: 5, width: 250 }}
              showIcon
            />
            <Input
              placeholder={lang.formatMessage({ id: 'add_type' })}
              type="text"
              style={{ width: 250 }}
              value={typeToAdd}
              onChange={(e) => {
                setTypeToAdd(e.target.value)
              }}
              addonAfter={
                updatingType ? (
                  <LoadingOutlined />
                ) : (
                  <PlusOutlined
                    onClick={() => {
                      setUpdatingType(true)
                      addType(typeToAdd)
                    }}
                    style={{ curser: 'pointer' }}
                  />
                )
              }
            />
          </Form.Item>
        </div>

        <div>
          <Divider orientation="left">
            {lang.formatMessage({ id: 'add_expense' })}
          </Divider>
          <Form.Item
            name="type"
            rules={[
              {
                required: true,
                message: lang.formatMessage({ id: 'select_expense_type' }),
              },
            ]}
          >
            <Select
              loading={typesLoading}
              showSearch
              style={{ width: 200 }}
              placeholder={lang.formatMessage({ id: 'select_expense_type' })}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {types.map((type) => (
                <Option key={type._id} value={type._id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="user">
            <SearchDropdown
              placeholder={lang.formatMessage({
                id: 'select_user',
              })}
              value-={selectedUser}
              width={200}
              url="/user/v2?roles=manager,postman&blocked=false"
              searchUrl="/user/v2?roles=manager,postman&blocked=false&name="
              onSelect={(selectedValue) => setSelectedUser(selectedValue)}
              renderItem={(item) => ({
                key: item._id,
                value: item._id,
                name: `${item.fullname} (${lang
                  .formatMessage({
                    id: item.role.name,
                  })
                  .toLocaleLowerCase()})`,
              })}
            />
          </Form.Item>
          <Form.Item
            name="price"
            rules={[
              {
                required: true,
                message: lang.formatMessage({ id: 'expense_price_validation' }),
              },
              {
                type: 'number',
                min: 1,
                message: lang.formatMessage({
                  id: 'expense_price_validation_positive',
                }),
              },
            ]}
            initialValue={0}
          >
            <InputNumber
              step="1000"
              min={0}
              defaultValue={0}
              formatter={(value) => `${value}֏`}
              parser={(value) => value.replace('֏', '')}
            />
          </Form.Item>
          <Form.Item
            noStyle={true}
            name="description"
            placeholder={lang.formatMessage({ id: 'description' })}
          >
            <TextArea
              rows={4}
              placeholder={lang.formatMessage({ id: 'description' })}
            />
          </Form.Item>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type="primary" htmlType="submit" loading={updating}>
              <FormattedMessage id="add_expense" />
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Card>
  )
}
