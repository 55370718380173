import React, { useEffect, useState } from 'react'
import { Table, Tooltip } from 'antd'
import styles from '../statistics.module.css'
import { IntTextFormatter } from '../../helper/Integer/IntegerFormatter'
import {FormattedMessage, useIntl} from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import { useSettings } from '../../helper/context/SettingsContext'

const PostmanTransactionTable = ({ dataSet, loading, date }) => {
  const lang = useIntl()
  const [data, setData] = useState({
    items: [],
    balance: { currency: '', currency_text: '' },
    finishTotalCount: 0
  })

  const history = useHistory()
  const [columns, setColumns] = useState([])
  const { returnEnabled } = useSettings()

  const parseData = () => {
    console.log(dataSet)
    const { balance, items, totalCount } = dataSet
    const { graphItems } = items.reduce(
      (data, el) => {
        const dataItem = {
          postman: el.agent,
          profit: el.profit,
          transaction: el.transaction,
          profitPercentage: el.profitPercentage,
          expense: el.expense ? el.expense : 0,
          finishedOrderCount: el.count,
        }
        // if (returnEnabled) {
        //   data.graphItems.push({
        //     ...dataItem,
        //     returnTransaction: el.returnTransaction
        //   })
        // } else {
        //   data.graphItems.push(dataItem)
        // }
        data.graphItems.push(dataItem)
        return data
      },
      { graphItems: [] }
    )
    setData({ ...data, items: graphItems, balance: balance, finishTotalCount: totalCount })
    setupTableColumns(balance.currency_text)
  }

  const setupTableColumns = (currency) => {
    const columns = [
      {
        title: lang.formatMessage({ id: 'postman' }),
        dataIndex: 'postman',
        render: (postman) =>
          postman ? (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={{
                pathname: `/view/user/${postman._id}`,
              }}
            >
              {postman.fullname}
            </Link>
          ) : (
            ''
          ),
        width: 200,
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.postman.fullname.localeCompare(b.postman.fullname),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: lang.formatMessage({ id: 'transaction' }),
        dataIndex: 'transaction',
        render: (transaction) => (
          <IntTextFormatter currencyText={currency} number={transaction} />
        ),
        sorter: (a, b) => a.transaction - b.transaction,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: lang.formatMessage({ id: 'profit' }),
        dataIndex: 'profit',
        render: (profit) => (
          <IntTextFormatter currencyText={currency} number={profit} />
        ),
        sorter: (a, b) => a.profit - b.profit,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: lang.formatMessage({ id: 'profit_percentage' }),
        dataIndex: 'profitPercentage',
        render: (profitPercentage) =>
          `${profitPercentage ? profitPercentage + '%' : '-'}`,
        sorter: (a, b) => {
          if (!a.profitPercentage) {
            return -1
          } else if (!b.profitPercentage) {
            return 1
          }
          return a.profitPercentage - b.profitPercentage
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: () => lang.formatMessage({ id: 'finished_order_count' }),
        dataIndex: 'finishedOrderCount',
        render: (returnTransaction) => (
          <IntTextFormatter number={returnTransaction} />
        ),
        sorter: (a, b) => a.finishedOrderCount - b.finishedOrderCount,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: () => lang.formatMessage({ id: 'expenses' }),
        dataIndex: 'expense',
        render: (expense) => (
          <IntTextFormatter currencyText={currency} number={expense} />
        ),
        sorter: (a, b) => a.expense - b.expense,
        sortDirections: ['descend', 'ascend'],
      },
    ]
    // if (returnEnabled) {
    //   columns.push({
    //     title: lang.formatMessage({ id: 'return_transaction' }),
    //     dataIndex: 'returnTransaction',
    //     render: returnTransaction => (
    //       <IntTextFormatter
    //         currencyText={currency}
    //         number={returnTransaction ?? 0}
    //       />
    //     ),
    //     sorter: (a, b) => a.returnTransaction - b.expense,
    //     sortDirections: ['descend', 'ascend']
    //   })
    // }
    setColumns(columns)
  }

  useEffect(() => {
    if (dataSet) {
      parseData()
    }
  }, [dataSet])

  return columns.length > 0 ? (
    <Table
      title={() => (
        <div className={styles.postmanTableHeader}>
          <div><FormattedMessage id='profit_calculation_expense_excluded'/></div>
          <div><b><FormattedMessage id='finish_total_count'/>: <IntTextFormatter number={data.finishTotalCount}/></b></div>
        </div>
      )
      }
      showSorterTooltip={false}
      loading={loading}
      size='small'
      bordered={true}
      className={styles.statisticsTables}
      columns={columns}
      rowKey={(element) => element.postman._id}
      pagination={{
        defaultPageSize: 9,
        total: data.items.length,
        position: ['bottomCenter'],
      }}
      dataSource={data.items}
    />
  ) : ''
}

export default PostmanTransactionTable
