import React, { useEffect } from 'react'
import { useQuery } from '../../helper/Hooks'
import moment from 'moment'
import { Select } from 'antd'
import SBContent from '../../helper/SidebarContent/'
import useDashboardFilter from '../hooks/DashboardFilterHook'
import GroupTransactionTable from '../../statistics/tables/GroupTransactionTable'
import GroupProfitTransactionChart from '../../statistics/GroupProfitTransactionChart'
import useGroupCategories from './GroupCategoriesHook'
import useGroupProducts from './GroupProductsHook'
import styles from './dashboardGroups.module.scss'
import SearchDropdown from "../../helper/searchDropdown/SearchDropdown";
import DateRangeFilter from "../../helper/DateRangeFilter";
import {useIntl} from "react-intl";

const { Option } = Select

export default function DashboardGroups() {
  const lang = useIntl()
  const month = useQuery('month')

  const { dateRange, setDateRange, filter, setFilter } = useDashboardFilter(
    month
  )

  const { categories, isCategoriesLoading } = useGroupCategories('/category')

  const { products, isProductsLoading, getProducts } = useGroupProducts(
    `/product/filter?category=${filter.categoryId ? filter.categoryId : ''}`
  )

  useEffect(() => {
    if (filter.categoryId) {
      getProducts()
    }
  }, [filter.categoryId])

  return (
    <SBContent title={lang.formatMessage({id: 'group_statistics'})}>
      <div className={styles.filterContainer}>
        <DateRangeFilter className={styles.dateFilter}
                         onChange={setDateRange}
                         defaultValue={month
                           ? moment().month(month).format('MMMM')
                           : moment().format('MMMM')}
                         intervals={[6, 12]}/>
        <SearchDropdown
          placeholder={lang.formatMessage({id: 'select_manager'})}
          url='/user/v2?roles=manager&blocked=false'
          searchUrl='/user/v2?roles=manager&blocked=false&name='
          onSelect={(selectedValue) =>
            setFilter({
                        ...filter,
                        managerId: selectedValue,
                      })
          }
          renderItem={(item) => ({
            key: item._id,
            value: item._id,
            name: item.fullname,
          })}
          className={styles.filter}
        />
        <Select
          loading={isCategoriesLoading}
          allowClear={true}
          className={styles.filter}
          placeholder={lang.formatMessage({id: 'select_category'})}
          showSearch
          onChange={(e) => {
            setFilter({ ...filter, categoryId: e, productId: undefined })
          }}
          optionFilterProp='children'
          filterOption={(input, option) => {
            if (!option.children) {
              return true
            }
            return option.children.toLowerCase().includes(input.toLowerCase())
          }}
        >
          {!isCategoriesLoading &&
            categories.map((category) => (
              <Option key={category._id} value={category._id}>
                {category.name}
              </Option>
            ))}
        </Select>
        <Select
          loading={isProductsLoading}
          allowClear={true}
          value={filter.productId}
          className={styles.productFilter}
          placeholder={lang.formatMessage({id: 'select_product'})}
          showSearch
          onChange={(e) => {
            setFilter({ ...filter, productId: e })
          }}
          optionFilterProp='children'
          filterOption={(input, option) => {
            if (!option.children) {
              return true
            }
            return option.children.toLowerCase().includes(input.toLowerCase())
          }}
        >
          {!isProductsLoading &&
            products.map((product) => (
              <Option key={product._id} value={product._id}>
                {`(${product.product_id}) ${product.description}`}
              </Option>
            ))}
        </Select>
      </div>
      <div className={styles.dataContainer}>
        <GroupTransactionTable dateRange={dateRange} filter={filter} />
        <GroupProfitTransactionChart dateRange={dateRange} filter={filter} />
      </div>
    </SBContent>
  )
}
