import { PlusOutlined } from '@ant-design/icons'
import { Button, message, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Api from '../../../api'
import ConfirmButton from '../../helper/ConfirmButton'
import { useLoading } from '../../helper/Hooks'
import CreateProfile from './CreateProfile'
import styles from './info.module.scss'
import UserPermissionGroup from './UserPermissionGroup'

const { Option } = Select

function UserPermission(props) {
  const userId = props.user._id
  const [allPermissions, setAllPermissions] = useState([])
  const [isSaveDisabled, setSaveDisabled] = useState(true)
  const [selectedProfile, setSelectedProfile] = useState(undefined)
  const [createProfileVisibility, setCreateProfileVisibility] = useState(false)
  const [profiles, setProfiles] = useState([])
  const { loading, startLoading } = useLoading()
  const userPermissionsRef = useRef({})
  const profilesRef = useRef({})
  const lang = useIntl()

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    await getUserPermissions()
    await getAllPermissions()
    getProfiles()
  }

  const getUserPermissions = async () => {
    const response = await Api.get(`/permission/user/${userId}`)
    userPermissionsRef.current = response.data.result
  }

  const getAllPermissions = async () => {
    const response = await Api.get('/permission')
    const permissions = response.data.result.permissions
    setAllPermissions(permissions)
  }

  const getProfiles = () => {
    startLoading(async () => {
      try {
        const response = await Api.get(`/permission/profiles`)
        setProfiles(response.data.result.permissions)
        profilesRef.current = response.data.result.permissions.reduce(
          (prev, next) => {
            prev[next._id] = next.permissions
            return prev
          },
          {}
        )
      } catch (e) {
        if (e.response.data && e.response.data.message) {
          message.error(e.response.data.message)
        } else {
          message.error(lang.formatMessage({ id: 'get_profiles_error' }))
        }
      }
    })
  }

  const updatePermission = async (permissions) => {
    try {
      const data = {
        user_id: userId,
        permissions: permissions,
      }
      await Api.post('/permission', data)
    } catch (e) {
      if (e.response.data && e.response.data.message) {
        message.error(e.response.data.message)
      } else {
        message.error(lang.formatMessage({ id: 'update_permission_error' }))
      }
    }
  }

  const onPermissionSave = () => {
    updatePermission(getPermissionArray())
    setSaveDisabled(true)
  }

  const onProfileUpdate = async () => {
    try {
      await Api.put(`permission/profile/update/${selectedProfile}`, {
        permissions: getPermissionArray(),
      })
      message.info(lang.formatMessage({ id: 'profile_update_message' }))
    } catch (e) {
      if (e.response.data && e.response.data.message) {
        message.error(e.response.data.message)
      } else {
        message.error(lang.formatMessage({ id: 'profile_update_error' }))
      }
    }
  }

  const onProfileDelete = async () => {
    try {
      await Api.delete(`permission/profile/${selectedProfile}`)
      setSelectedProfile(undefined)
      getProfiles()
      message.info(lang.formatMessage({ id: 'profile_delete_message' }))
    } catch (e) {
      if (e.response.data && e.response.data.message) {
        message.error(e.response.data.message)
      } else {
        message.error(lang.formatMessage({ id: 'profile_delete_error' }))
      }
    }
  }

  const getPermissionArray = () => {
    return Object.values(userPermissionsRef.current).reduce(
      (prev, next) => [...prev, ...next],
      []
    )
  }

  return (
    <div className={styles.permissionList}>
      <div className={styles.permissionFilter}>
        <Button
          type='primary'
          disabled={isSaveDisabled}
          onClick={onPermissionSave}
        >
          <FormattedMessage id='save_changes' />
        </Button>
        <div className={styles.permissionDropdownContainer}>
          <Select
            value={selectedProfile}
            placeholder={lang.formatMessage({ id: 'select_profile' })}
            showSearch={false}
            allowClear
            className={styles.permissionDropdown}
            onChange={(selectedValue) => {
              setSelectedProfile(selectedValue)
              if (selectedValue) {
                userPermissionsRef.current = profilesRef.current[selectedValue]
                if (isSaveDisabled) {
                  setSaveDisabled(false)
                }
              }
            }}
            loading={loading}
          >
            {profiles.map((profile) => {
              return (
                <Option key={profile._id} value={profile._id}>
                  {profile.name}
                </Option>
              )
            })}
          </Select>
          <div
            className={styles.addPermission}
            onClick={() => {
              setCreateProfileVisibility(true)
            }}
          >
            <PlusOutlined />
          </div>
        </div>
        <ConfirmButton
          type='primary'
          disabled={!selectedProfile || isSaveDisabled}
          onClick={onProfileUpdate}
          confirmTitle={lang.formatMessage({ id: 'update_profile_confirm' })}
        >
          <FormattedMessage id='update_profile' />
        </ConfirmButton>
        <ConfirmButton
          type='danger'
          disabled={!selectedProfile}
          onClick={onProfileDelete}
          confirmTitle={lang.formatMessage({ id: 'delete_profile_confirm' })}
        >
          <FormattedMessage id='delete_profile' />
        </ConfirmButton>
      </div>
      {allPermissions.map((permissionGroup) => {
        const perm = userPermissionsRef.current[permissionGroup.name]
        return (
          <UserPermissionGroup
            permissionGroup={permissionGroup}
            userPermissions={perm ? perm : []}
            onPermissionUpdate={(key, permissionList) => {
              userPermissionsRef.current[key] = permissionList
              if (isSaveDisabled) {
                setSaveDisabled(false)
              }
            }}
          />
        )
      })}
      <CreateProfile
        onProfileCreate={() => {
          setSelectedProfile(undefined)
          getProfiles()
          setCreateProfileVisibility(false)
        }}
        onCancel={() => {
          setCreateProfileVisibility(false)
        }}
        permissions={getPermissionArray()}
        visibility={createProfileVisibility}
      />
    </div>
  )
}

export default UserPermission
