import React from 'react'
import ProductHistoryList from '../ProductHistoryList'

export default function ProductHistoryTab({ productId }) {

  return (
    <ProductHistoryList
      currentProduct={productId}
    />
  )
}
