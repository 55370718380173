import React, {useEffect, useState} from 'react'
import SContent from '../helper/SidebarContent/'
import {Button, Collapse, Divider, Empty, List, Spin,} from 'antd'
import {EditOutlined, AreaChartOutlined} from '@ant-design/icons'
import API from '../../api'
import {Link, useHistory, useParams} from 'react-router-dom'
import {hasPermission} from '../../api/user'
import {FormattedMessage, useIntl} from 'react-intl'
import ProductListItem from './ProductListItem'
import CreateCategory from '../category/CreateCategory'
import {useLoading} from '../helper/Hooks'
import styles from './product.module.scss'
import {formatNumber} from "../helper/Integer/IntegerFormatter";
import EditCategory from "../category/EditCategory";

const { Panel } = Collapse

export default function Products() {
  const lang = useIntl()
  const history = useHistory()
  const { categoryId } = useParams()
  const [data, setData] = useState({ categories: [], total: 0, totalCost: 0 })
  const [selectedData, setSelectedData] = useState({selectedTotalCost: 0, selectedTotalCount: 0})
  const [createVisibility, setCreateVisibility] = useState(false)
  const [editVisibility, setEditVisibility] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState({})
  const { loading, startLoading } = useLoading()
  const {
    loading: removeLoading,
    startLoading: startRemoveLoading,
  } = useLoading()

  useEffect(() => {
    fetchProducts()
  }, [])

  const fetchProducts = async () => {
    startLoading(async () => {
      const response = await API.get(
        `/product${categoryId ? '?category=' + categoryId : ''}`
      )

      const result = response.data.result
      setData({
        categories: result.categories,
        total: result.total,
        totalCost: result.total_cost,
      })
    })
  }

  const onItemChecked = (count, cost, isChecked) => {
    setSelectedData({
      selectedTotalCost: isChecked ? selectedData.selectedTotalCost + cost : selectedData.selectedTotalCost - cost,
      selectedTotalCount: isChecked ? selectedData.selectedTotalCount + count : selectedData.selectedTotalCount - count
    })
  }

  const hasPricePermission = hasPermission('product_actual_price_edit')

  const removeEmptyCategory = (id) => {
    startRemoveLoading(async () => {
      await API.delete(`/category/${id}`)
      fetchProducts()
    })
  }

  const customizeRenderEmpty = (categoryId) => (
    <Empty
      image={null}
      description={
        <span style={{ fontSize: 14, color: '#000000a6' }}>
          <FormattedMessage id='empty_category' />
        </span>
      }
      imageStyle={{
        height: 0,
        width: 0,
      }}
    >
      <Button
        type='danger'
        onClick={() => removeEmptyCategory(categoryId)}
        loading={removeLoading}
      >
        <FormattedMessage id='remove' />
      </Button>
    </Empty>
  )

  return (
    <SContent title={lang.formatMessage({id: 'products'})}>
      {hasPermission('category_product_create') && (
        <Button style={{ marginTop: 30, marginRight: 30 }}>
          <Link to='/create/product'>
            <FormattedMessage id='create_product' />
          </Link>
        </Button>
      )}
      {hasPermission('category_product_create') && (
        <Button onClick={() => setCreateVisibility(true)}>
          <FormattedMessage id='create_category' />
        </Button>
      )}
      <Divider />
      {hasPermission('product_history_view') && (
        <Link to='/history/products'>
          <FormattedMessage id='product_history' />
        </Link>
      )}

      <Divider />
      <div className={styles.totalsHeader}>
        <div>
          <div>
            <FormattedMessage id='total' />: <b>{data.total.toLocaleString()}</b>
          </div>
          {hasPricePermission && (
            <div>
              <FormattedMessage id='total_cost' />: <b>{formatNumber(data.totalCost, '֏')}</b>
            </div>
          )}
        </div>
        <div>
          <div>
            <FormattedMessage id='selected_total' />: <b>{selectedData.selectedTotalCount.toLocaleString()}</b>
          </div>
          {hasPricePermission && (
            <div>
              <FormattedMessage id='selected_total_cost' />: <b>{formatNumber(selectedData.selectedTotalCost, '֏')}</b>
            </div>
          )}
        </div>
      </div>

      <div className={styles.productsContainer}>
        <Spin spinning={loading}>
          <Collapse style={{ minHeight: 230 }} accordion>
            {data.categories.map((category) => {
                return (<Panel
                  key={category.category._id}
                  header={category.category.name}
                  extra={
                    <div>
                      <FormattedMessage id='total' />: <b>{category.total.toLocaleString()}</b>{' '}
                      {hasPricePermission && (
                        <>
                          / <FormattedMessage id='total_cost' />: <b>{formatNumber(category.total_cost, '֏')}</b>
                        </>
                      )}
                      <EditOutlined style={{marginLeft: 5}} onClick={(e) => {
                        e.stopPropagation()
                        setSelectedCategory(category.category)
                        setEditVisibility(true)
                      }
                      }/>
                      <AreaChartOutlined style={{marginLeft: 5}} onClick={() => {
                        history.push(
                          `/dashboard-core?category=${category.category._id}`
                        )}
                      }/>
                    </div>
                  }
                >
                  <List
                    itemLayout='horizontal'
                    dataSource={category.products}
                    renderItem={(item, index) => (
                      <>
                        {index !== 0 && <Divider />}
                        <ProductListItem product={item} onProductSelect={onItemChecked} />
                      </>
                    )}
                    locale={{
                      emptyText: customizeRenderEmpty(category.category._id),
                    }}
                  />
                </Panel>)
            })}
          </Collapse>
        </Spin>
      </div>
      <CreateCategory
        visibility={createVisibility}
        onCategoryCreate={() => {
          setCreateVisibility(false)
          fetchProducts()
        }}
        onCancel={() => setCreateVisibility(false)}
      />
      <EditCategory
        visibility={editVisibility}
        category={selectedCategory}
        onCategoryUpdate={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
      />
    </SContent>
  )
}
