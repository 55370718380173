import React from 'react'
import { useQuery } from 'react-query'
import API from '../../api'

const useDefaultFetcher = ({ url, interval = false, lazy = false }) => {
  const fetchData = async () => {
    return await API.get(url)
  }

  const { data, isLoading, isSuccess, error, refetch } = useQuery(
    url,
    fetchData,
    {
      refetchInterval: interval,
      enabled: !lazy,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  )
  let query = { isLoading, isSuccess, error, refetch }

  if (data) {
    query = { ...query, data: data.data.result }
  }

  return query
}

export const useFetcher = (url) => {
  return useDefaultFetcher({ url })
}

export const useLazyFetcher = (url) => {
  return useDefaultFetcher({ url, lazy: true })
}

export const usePeriodicFetcher = (url, interval) => {
  return useDefaultFetcher({ url, interval })
}
