import React, {useEffect, useState} from 'react'
import {useLoading} from "../helper/Hooks";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, Form, Input, Modal, Upload} from "antd";
import Icon from "@ant-design/icons";
import API from "../../api";

const EditCategory = ({visibility, onCategoryUpdate, onCancel, category}) => {
  const {loading, startLoading} = useLoading()
  const [editForm] = Form.useForm()
  const [image, setImage] = useState(undefined)
  const language = useIntl()

  async function uploadImage() {
    const formData = new FormData()
    if (image) {
      formData.append('photo', image)
      formData.append('category_id', category._id)
      try {
        await API.post('/category/uploadImage', formData)
      } catch (ex) {
        console.error(ex)
      }
    }
    setImage(undefined)
  }

  function updateCategory(values) {
    startLoading(async () => {
      const response = await API.put(`/category/${category._id}`, values)
      if (response.status === 200) {
        await uploadImage()
        onCategoryUpdate()
      }
    })
  }


  return (<Modal
    width={700}
    title={<FormattedMessage id='edit'/>}
    onCancel={() => onCancel()}
    visible={visibility}
    footer={''}
  >
    <Form form={editForm} onFinish={updateCategory}>
      <Form.Item>
        <div>
          <Upload
            type
            name='logo'
            listType='picture'
            accept='jpg'
            showUploadList={false}
            beforeUpload={(file) => {
              setImage(file)
              return false
            }}
          >
            <Button>
              <Icon type='upload'/>
              <FormattedMessage id='click_to_upload'/>
            </Button>
          </Upload>
          <img
            style={{marginLeft: 20}}
            src={
              image ? URL.createObjectURL(image) : category.image
            }
            width='100'
          />
        </div>
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit' loading={loading}>
          <FormattedMessage id='update'/>
        </Button>
      </Form.Item>
    </Form>
  </Modal>)
}

export default EditCategory