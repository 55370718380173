import React from 'react'
import {Card} from "antd";
import {FormattedMessage, useIntl} from "react-intl";
import {formatNumber} from "../../helper/Integer/IntegerFormatter";
import {isManager, isUserClient} from "../../../api/user";

const Limits = ({user, ...props}) => {

  const lang = useIntl()


  const gridStyle = {
    align: 'center',
    boxShadow: 'none',
    textAlign: 'center',
    padding: 6,
    width: '100%'
  }

  const spanStyle = {
    fontSize: '100%',
    fontWeight: 'bold',
  }

  return (<div {...props}>
    <Card
      title={
        <strong>
          <FormattedMessage id='limits'/>
        </strong>
      }
    >
      {isManager(user) && (
        <div>
          <Card.Grid style={gridStyle} hoverable={false}>
            <span style={spanStyle}>
              {`${lang.formatMessage({id: 'min_delivery_price'})}: ${formatNumber(user.minDeliveryPrice,
                user.balance.currency_text)}`}
            </span>
          </Card.Grid>
        </div>
      )}
      <div>
        <Card.Grid style={gridStyle} hoverable={false}>
            <span style={spanStyle}>
              {`${lang.formatMessage({id: 'debt_amount'})}: ${formatNumber(user.debtAmount,
                user.balance.currency_text)}`}
            </span>
        </Card.Grid>
      </div>
    </Card>
  </div>)
}

export default Limits