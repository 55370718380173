import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu, PageHeader, Select } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import { hasPermission } from '../../../api/user'
import { languages } from '../../../provider/LanguageProvider'
import settings from '../../../settings'
import Onboarding from '../../onboarding/Onboarding'
import LanguageContext from '../context/LanguageContext'
import UserContext, { useUser } from '../context/UserContext'
import styles from './content.module.scss'
import { useSettings } from '../context/SettingsContext'
import { useEffect, useState } from 'react'
import { Lightbox } from "react-modal-image"

const { Option } = Select

export default function SidebarContent({
  children,
  title = '',
  avatar,
  applyCss = true,
}) {
  let history = useHistory()
  const { user } = useUser()
  const { signOut } = useContext(UserContext)
  const { language, setLanguage } = useContext(LanguageContext)
  const { showOnboarding } = useSettings()
  const [focusModalState, setFocusedModalState] = useState({
    state: false,
    imageUrl: undefined,
  })


  return (
    <div className={styles.sidebarContent}>
      <PageHeader
        style={{ backgroundColor: '#fff' }}
        backIcon={false}
        title={title}
        avatar={{
          src: avatar?.src
            ? avatar.src
            : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
          onClick: () => {
            const src = avatar?.src
              ? avatar.src
              : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"

            setFocusedModalState({ state: true, imageUrl: src })
          }
        }}
        extra={[
          <Select
            style={{
              width: 75,
            }}
            defaultValue={language}
            onChange={setLanguage}
          >
            {languages().map((element) => (
              <Option key={element.lang_key} value={element.lang_key}>
                {element.value}
              </Option>
            ))}
          </Select>,
          <Avatar
            src={user.image}
            style={{ marginLeft: 10, marginRight: 0 }}
          />,
          <Dropdown
            style={{ margin: 0 }}
            overlay={
              <Menu>
                <Menu.Item key='profile'>
                  <Link
                    to={{
                      pathname: `/view/user/${user._id}`,
                    }}
                  >
                    <FormattedMessage id='my_profile' />
                  </Link>
                </Menu.Item>
                {hasPermission('admin_view_subscription') && (
                  <Menu.Item key='limits'>
                    <Link
                      to={{
                        pathname: `/limits`,
                      }}
                    >
                      <FormattedMessage id='subscription' />
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Divider />
                <Menu.Item
                  key='logout'
                  onClick={() => {
                    signOut()
                    history.replace('/login')
                  }}
                >
                  <FormattedMessage id='logout' />
                </Menu.Item>
              </Menu>
            }
            trigger='click'
          >
            <a
              className='ant-dropdown-link'
              onClick={(e) => e.preventDefault()}
            >
              {user.fullname} <DownOutlined />
            </a>
          </Dropdown>,
        ]}
      >
        {showOnboarding && (
          <div className={styles.pageContainer}>
            <Onboarding />
          </div>
        )}
        <div className={applyCss ? styles.pageContainer : ''}>{children}</div>
      </PageHeader>
      {focusModalState.state && (
        <Lightbox
          medium={focusModalState.imageUrl}
          hideDownload={true}
          hideZoom={true}
          onClose={() => {
            setFocusedModalState({
              state: false,
              imageUrl: undefined,
            })
          }}
        />
      )}
    </div>
  )
}
