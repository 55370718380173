import React from "react";
import InfiniteScrollWrapper from "../../helper/InfiniteScroll";
import OrderNotificationItem from "./OrderNotificationItem";


const OrderNotifications = ({userId}) => {

  return(
    <div>
      <InfiniteScrollWrapper
        url={`notification?user=${userId}`}
        render={(item) => (
         <OrderNotificationItem item={item} />
        )}
      />
    </div>
  )
}

export default OrderNotifications