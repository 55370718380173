import React, { useState, useEffect, useRef } from 'react'
import styles from './group.module.css'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Select, Avatar, List } from 'antd'
import api from '../../api'
import { useIntl } from 'react-intl'

const { Option } = Select

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgrey' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 200,
})

export default function GroupUsersCreate(props) {
  const [users, setUsers] = useState([])
  const [sortedUsers, setSortedUsers] = useState([])
  const language = useIntl()

  useEffect(() => {
    fetchAndMergeUsers()
  }, [])

  async function fetchAndMergeUsers() {
    const users = await fetchNonGroupUsers()

    setUsers(users)
  }

  async function fetchNonGroupUsers() {
    const response = await api.get(`user/clients/nonGroup`)
    const users = response.data.result
    return users
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const items = reorder(
      sortedUsers,
      result.source.index,
      result.destination.index
    )
    setSortedUsers(items)
    props.onUserAdd(items.map((e) => e._id))
  }

  const onUserSelect = (id) => {
    const sorted = [...sortedUsers, ...users.filter((e) => e._id == id)]
    setSortedUsers(sorted)
    props.onUserAdd(sorted.map((e) => e._id))
  }

  const onUserDeselect = (id) => {
    const users = sortedUsers.filter((e) => e._id != id)
    setSortedUsers(users)
    props.onUserAdd(users.map((e) => e._id))
  }

  return (
    <div>
      <div className={styles.userSelect}>
        <Select
          mode='multiple'
          placeholder={language.formatMessage({ id: 'select_users' })}
          className={styles.userSelect}
          onSelect={onUserSelect}
          onDeselect={onUserDeselect}
        >
          {users.map((user) => (
            <Option key={user._id} value={user._id}>
              {user.fullname + (user.group ? ` in ${user.group.name}` : '')}
            </Option>
          ))}
        </Select>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {sortedUsers.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <List.Item>
                        <List.Item.Meta
                          title={item.fullname}
                          avatar={<Avatar shape='circle' src={item.image} />}
                        />
                      </List.Item>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
