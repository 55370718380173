import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './map.module.scss'
import { isValidLocation } from '../helper/helper'

import mapboxgl from 'mapbox-gl'
import Directions from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions'
import { customStyle, zoomRatios } from './CustomMapOptions'

mapboxgl.accessToken =
  'pk.eyJ1IjoibWFkLWFwcGJ1aWxkZXIiLCJhIjoiY2sxdGpmbDQ5MGFvbjNocXE4MjBlbnJpbyJ9.jjLmEMkIaFCzgfG2GDFGJw'

const Map = ({
  markers,
  center,
  route,
  isMarkerClickable = false,
  onMarkerClick,
  onRadiusChange,
  className = styles.mapContainer,
  moveToMarker = false,
}) => {
  const mapRef = useRef(undefined)
  const directionRef = useRef(undefined)
  const isMarkerClicable = isMarkerClickable
  const [mapLoaded, setMapLoaded] = useState(false)
  const validCenter = useMemo(() => {
    return center &&
      (center.long <= -90 ||
        center.long >= 90 ||
        center.lat <= -90 ||
        center.lat >= 90)
      ? { long: 44.51667, lat: 40.18333 }
      : center
  }, [center])

  const shouldMoveMarkerToCenter = () => moveToMarker && center

  const mapContainer = useRef(null)
  useEffect(() => {
    const directions = new Directions({
      accessToken: mapboxgl.accessToken,
      unit: 'metric',
      profile: 'mapbox/walking',
      interactive: false,
      controls: {
        inputs: false,
        instructions: false,
        profileSwitcher: false,
      },
      styles: customStyle,
    })
    const createdMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: shouldMoveMarkerToCenter()
        ? [validCenter.long, validCenter.lat]
        : [44.51667, 40.18333],
      zoom: shouldMoveMarkerToCenter() ? 11 : 12,
    })
    createdMap.once('load', () => {
      createdMap.loadImage(
        process.env.PUBLIC_URL + '/shop-15-yellow.png',
        (error, image) => {
          if (error) throw error
          createdMap.addImage('shop-15-yellow', image, { sdf: true })
        }
      )
      createdMap.resize()
      createdMap.addSource('places', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: markers,
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 125,
      })

      createdMap.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'places',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            2,
            '#f1f075',
            100,
            '#f28cb1',
          ],
          'circle-radius': ['step', ['get', 'point_count'], 15, 2, 20, 100, 25],
        },
      })

      createdMap.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'places',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      })

      createdMap.addLayer({
        id: 'unclustered-places',
        type: 'symbol',
        source: 'places',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': ['concat', ['get', 'icon']],
          'icon-allow-overlap': false,
          'text-field': ['get', 'title'],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 0.6],
          'text-anchor': 'top',
        },
        paint: {
          'icon-color': [
            'match',
            ['get', 'icon'],
            'shop-15-yellow',
            '#ffc703',
            '#ff0303',
          ],
        },
      })

      createdMap.on('click', 'clusters', function (e) {
        const features = createdMap.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        })
        const clusterId = features[0].properties.cluster_id
        createdMap
          .getSource('places')
          .getClusterExpansionZoom(clusterId, function (err, zoom) {
            if (err) return

            createdMap.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            })
          })
      })

      if (isMarkerClicable) {
        createdMap.on('click', 'places', function (e) {
          const data = JSON.parse(e.features[0].properties.placeData)
          const type = e.features[0].properties.type
          if (onMarkerClick) {
            onMarkerClick(type, data)
          }
        })
      }
      setPointer(['unclustered-places', 'clusters'], createdMap)
      // use anonymous functions instead of lambdas
      createdMap.on('move', function () {
        sendCenterData(createdMap)
      })
      mapRef.current = createdMap
      sendCenterData(createdMap)
      setMapLoaded(true)
    })

    createdMap.addControl(directions, 'top-left')
    directionRef.current = directions

    return () => {
      createdMap.remove()
      mapRef.current = undefined
      directionRef.current = undefined
    }
  }, [])

  useEffect(() => {
    if (!mapRef.current) {
      return
    } else if (markers.length === 0) {
      mapRef.current.getSource('places').setData({
        type: 'FeatureCollection',
        features: [],
      })
      return
    }

    mapRef.current.getSource('places').setData({
      type: 'FeatureCollection',
      features: markers.filter(
        (marker) =>
          marker.geometry.coordinates[0] !== 0 ||
          marker.geometry.coordinates[1] !== 0
      ),
    })
  }, [mapLoaded, markers])

  useEffect(() => {
    if (!center || !mapRef.current) {
      return
    }
    mapRef.current.flyTo({
      center: [validCenter.long, validCenter.lat],
      zoom: 13,
      essential: true,
    })
    console.log('#art', 'hello')
  }, [center])

  useEffect(() => {
    if (
      !route ||
      !isValidLocation(route.origin) ||
      !isValidLocation(route.destination) ||
      !directionRef.current
    ) {
      return
    }
    directionRef.current.setOrigin([route.origin.long, route.origin.lat])
    directionRef.current.setDestination([
      route.destination.long,
      route.destination.lat,
    ])
  }, [route])

  const sendCenterData = (createdMap) => {
    if (onRadiusChange) {
      const radius = Math.ceil(
        Math.sqrt(
          Math.pow(mapContainer.current.clientWidth, 2) +
            Math.pow(mapContainer.current.clientHeight, 2)
        ) / 2
      )
      onRadiusChange(
        radius * zoomRatios[Math.floor(createdMap.getZoom())],
        createdMap.getCenter()
      )
    }
  }

  return <div className={className} ref={(el) => (mapContainer.current = el)} />
}

const setPointer = (components, map) => {
  components.forEach((component) => {
    map.on('mouseenter', component, function () {
      map.getCanvas().style.cursor = 'pointer'
    })

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', component, function () {
      map.getCanvas().style.cursor = ''
    })
  })
}

export default Map
