import React from 'react'
import {Avatar, Divider} from 'antd'
import styles from './order.module.scss'
import {Link} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'

export default function OrderInfo(props) {
  const order = props.order
  const user = props.order.client
  const isLastProduct = (index) => index != order.order_details.length - 1
  return (
    <div>
      <div className={styles.orderHeader}>
        <div>
          <FormattedMessage id='id' />: <b>{order.order_id ?? order._id.toUpperCase().slice(-5)}</b>
        </div>
        <div>
          <FormattedMessage id='status' />: <b>{order.status}</b>
        </div>
      </div>

      {user && (
        <>
          <Divider orientation='left'>
            <FormattedMessage id='client'/>
          </Divider>
          <div className={styles.orderContent}>
            <Avatar shape='square' size={64} src={user.image ? user.image : ''}/>
            <div className={styles.userInfo}>
              <div>
                <FormattedMessage id='name'/>:{' '}
                <b>
                  <Link to={`/view/user/${user._id}`}>{user.fullname}</Link>
                </b>
              </div>
            </div>
          </div>
        </>
      )}
      <Divider orientation='left'>
        <FormattedMessage id='products' />
      </Divider>
      <div className={styles.productContainer}>
        {order.order_details.map((info, index) => (
          <div key={index}>
            <div className={styles.productItemContainer}>
              <Avatar shape='square' size={64} src={info.product.image_url} />
              <div className={styles.productInfo}>
                <div>
                  <b>
                    <Link to={`/view/product/${info.product._id}`}>
                      {`${info.product.product_id} / ${info.product.description}`}
                    </Link>
                  </b>
                </div>
                <div>
                  <FormattedMessage id='product_price' />:{' '}
                  <b>{info.product_price}</b>
                </div>
                <div>
                  <FormattedMessage id='product_count' />:{' '}
                  <b>{info.product_count}</b>
                </div>
              </div>
            </div>
            {isLastProduct(index) && <Divider />}
          </div>
        ))}
      </div>
      <Divider />
      <div className={styles.footer}>
        <div className={styles.moreInfo}>
          <div>
            <FormattedMessage id='total' />: <b>{order.total_price}</b>
          </div>
        </div>
      </div>
    </div>
  )
}
