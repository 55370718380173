import React, {useEffect, useReducer, useState} from 'react'
import {Avatar, Button, Collapse, List, message, Spin, Tag} from 'antd'
import Api from '../../../api'
import ProductManager from './ProductManager'
import {useLoading} from '../../helper/Hooks'
import {FormattedMessage, useIntl} from 'react-intl'
import {formatNumber} from "../../helper/Integer/IntegerFormatter";
import styles from "./info.module.scss"
import {hasPermission} from "../../../api/user";
import {EditOutlined} from "@ant-design/icons";
import ConfirmButton from "../../helper/ConfirmButton";

const {Panel} = Collapse

export default function PostmanProducts({user: userId}) {
  const lang = useIntl()
  const [info, setInfo] = useState({
    categories: [],
    userProducts: {},
    totalPrice: 0,
    total: 0
  })

  const [products, setProducts] = useState(
    { return: {}, add: {}, remove: {}}
  )

  const {loading, startLoading} = useLoading()
  const [changed, setChanged] = useState()

  useEffect(() => {
    getProducts()
  }, [])

  const modifyProducts =  ({ id, amount, type, ...additionalData}) => {
    const oldProducts = products
    if (amount === 0) {
      delete oldProducts[type][id]
      setProducts(oldProducts)
    } else {
      const newProducts = {
        ...oldProducts[type],
        [id]: { amount, id, ...additionalData }
      }
      setProducts({ ...oldProducts, [type]: newProducts })
    }
  }

  const modifyAllProducts = async (type) => {
    const prods = Object.values(products[type])

    if (prods.length === 0) {
      message.error(lang.formatMessage({ id: 'no_selected_products' }))
      return
    }

    startLoading(async () => {

      try {
        for (const product of prods) {
          const {amount, title} = product
          const isAdd = type === "add"
          const isRemove = type === "remove"
          if ((isAdd || isRemove) && (!amount || Number.isNaN(amount))) {
            throw new Error(lang.formatMessage({ id: 'product_wrong_input' }))
          }

          if (isAdd && product.total < 0) {
            throw new Error(
              `${lang.formatMessage({ id: 'product_insufficient_warehouse' })}
              ${title}`
            )
          }

          if (isRemove && product.count < amount) {
            throw new Error(
              `${lang.formatMessage({ id: 'product_insufficient_worker' })}
              ${title}`
            )
          }
        }
        if (type === 'return') {
          await Api.post('delivery/returnedProducts', {
              agent_id: userId,
              products: prods,
          })
        } else {
          await Api.post('delivery/products', {
              agent_id: userId,
              products: prods,
          })
        }
        setChanged({type, timestamps: Date.now()})
        setProducts({...products, [type]: {}})
      } catch (err) {
          message.error(err.message)
          // reset
      }
    })
  }

  const getProducts = async () => {
    startLoading(async () => {
      const userProductsRequest = Api.get(`/user/products/${userId}`)
      let userProducts = (await userProductsRequest).data.result
      setInfo({
        categories: userProducts.categories,
        total: userProducts.total,
        totalPrice: userProducts.totalPrice
      })
    })
  }

  const hasProducts = (category) => {
    return category.total > 0 || category.total_return > 0 || category.total_in_progress > 0
  }

  return (
    <Spin spinning={loading}>
      <div className={styles.topContainer}>
        <div className={styles.modifyProductsContainer}>
          <ConfirmButton 
            type="primary"
            onClick={() => modifyAllProducts('add')}
            confirmTitle={lang.formatMessage({id: "add_selected_products"})}
            confirmContent={lang.formatMessage({id: "add_selected_products_question"})}>
            <FormattedMessage id="add_all"/>
          </ConfirmButton>
          <ConfirmButton 
            type="primary"
            onClick={() => modifyAllProducts('remove')}
            confirmTitle={lang.formatMessage({id: "remove_selected_products"})}
            confirmContent={lang.formatMessage({id: "remove_selected_products_question"})}>
            <FormattedMessage id="remove_all"/>
          </ConfirmButton>
          <ConfirmButton type="primary"
            type="primary"
            onClick={() => modifyAllProducts('return')}
            confirmTitle={lang.formatMessage({id: "return_selected_products"})}
            confirmContent={lang.formatMessage({id: "return_selected_products_question"})}>
            <FormattedMessage id="return_all"/>
          </ConfirmButton>
        </div>
        {hasPermission('product_actual_price_view') &&
        (<div className={styles.postmanProductTotalContainer}>
          <strong><FormattedMessage id={"total_price"}/>: {formatNumber(info.totalPrice, '֏')}</strong>
          <strong><FormattedMessage id={"total"}/>: {formatNumber(info.total)}</strong>
        </div>)}
      </div>
      <Collapse style={{minHeight: 230}} accordion>
        {info.categories.map((category) => (
          <Panel header={category.category.name} key={category.category._id}
                 extra={
                  <div>
                     <FormattedMessage id='total'/>: <b>{formatNumber(category.total)}</b>
                     {hasPermission('product_actual_price_view') && (
                       <>
                         / <FormattedMessage id='total_cost'/>: <b>{formatNumber(category.total_cost, '֏')}</b>
                       </>
                     )}
                    <Tag color='#1991ff' style={{marginLeft: 5, width: 120}} visible={hasProducts(category)}>
                      <FormattedMessage id='products_available'/>
                    </Tag>
                   </div>
                 }>
            {category.products.map((product) => (
              <List.Item key={product.product}>
                <List.Item.Meta
                  avatar={
                    <Avatar shape='square' size={64} src={product.image_url}/>
                  }
                  title={`(${product.product_id}) ${product.description}`}
                />
                <div>
                  <ProductManager
                    product={{
                      ...product,
                      count: product.count,
                      returned: product.return_count
                    }}
                    userId={userId}
                    onProductChange={({ amount, type, ...additionalData}) => {
                      console.log("#art", additionalData)
                      modifyProducts({ 
                        id: product.product, 
                        amount, 
                        type, 
                        title: `(${product.product_id}) ${product.description}`,
                        ...additionalData
                      })
                    }}
                    changed={changed}
                  />
                  <div style={{marginTop: 10}}>
                    <strong>
                      <FormattedMessage id='in_progress_product_count' defaultMessage="Total in progress"/>
                      : {product.in_progress_count}
                    </strong>
                  </div>
                </div>
              </List.Item>
            ))}
          </Panel>
        ))}
      </Collapse>
    </Spin>
  )
}
