import React, {useContext} from 'react'
import {Button, Form, Input, message} from 'antd'
import {useHistory} from 'react-router-dom'
import UserContext from '../helper/context/UserContext'
import {FormattedMessage, useIntl} from 'react-intl'
import {useLoading} from "../helper/Hooks";

export default function Login() {
  const history = useHistory()
  const language = useIntl()
  const {loading, startLoading} = useLoading()

  const {signIn} = useContext(UserContext)

  const onFinish = async (values) => {
    startLoading(async () => {
      try {
        await signIn(values.username, values.password)
        history.replace('/dashboard-core')
      } catch (ex) {
        // ex.response.data.message
        if (ex.response) {
          message.error(ex.response.data.message)
        }
      }
    })
  }

  return (
    <div
      style={{
        width: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Form onFinish={onFinish}>
        <h1>MAD</h1>
        <Form.Item
          name='username'
          rules={[
            {
              required: true,
              message: language.formatMessage({id: 'fill_username'}),
            },
          ]}
        >
          <Input
            value='c'
            placeholder={language.formatMessage({id: 'username'})}
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: language.formatMessage({id: 'fill_password'}),
            },
          ]}
        >
          <Input.Password
            placeholder={language.formatMessage({id: 'password'})}
          />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' loading={loading}>
            <FormattedMessage id='submit'/>
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
