import React, {useEffect, useState} from 'react'
import {useLoading} from '../helper/Hooks'
import {Select} from 'antd'
import API from '../../api'
import SBContent from '../helper/SidebarContent/'
import styles from './dashboardPostmen.module.scss'
import SearchDropdown from '../helper/searchDropdown/SearchDropdown'
import DateRangeFilter from '../helper/DateRangeFilter'
import {useIntl} from 'react-intl'
import moment from 'moment'
import PostmanTransactionTable from '../statistics/tables/PostmanTransactionTable'
import PostmanChart from '../statistics/PostmanChart'

const {Option} = Select

const DashboardPostmen = ({postmanId, showPostmanFilter = true, showTitle = true}) => {
  const lang = useIntl()
  const [categories, setCategories] = useState([])
  const {loading: categoriesLoading, startLoading: startCategoryLoading} =
    useLoading()

  const [products, setProducts] = useState([])
  const {loading: productsLoading, startLoading: startProductsLoading} =
    useLoading()

  const [filter, setFilter] = useState({
    postmanId: '',
    categoryId: '',
    productId: '',
    managerId: ''
  })

  const [dateRange, setDateRange] = useState({
    start: '',
    end: '',
  })

  const [data, setData] = useState()
  const {loading: dataLoading, startLoading: startDataLoading} = useLoading()

  useEffect(() => {
    setDateRange({
      start: moment().startOf('month').valueOf(),
      end: moment().endOf('month').valueOf(),
    })
    getCategories()
    getProducts()
    setFilter({...filter, postmanId: postmanId})
  }, [])

  useEffect(() => {
    if (dateRange.start && dateRange.end) {
      getData()
    }
  }, [dateRange, filter.postmanId, filter.categoryId, filter.productId, filter.managerId])

  const getCategories = () => {
    startCategoryLoading(async () => {
      const response = await API.get('/category')
      setCategories(response.data.result.data)
    })
  }

  const getProducts = (categoryId) => {
    startProductsLoading(async () => {
      const res = await API.get(
        `/product/filter?category=${categoryId ? categoryId : ''}`
      )
      setProducts(res.data.result.products)
    })
  }

  const getData = () => {
    startDataLoading(async () => {
      const response = await API.get(
        `/statistics/profitwithTransactions/postmans?start=${
          dateRange.start
        }&end=${dateRange.end}&postman=${
          filter.postmanId ? filter.postmanId : ''
        }&category=${filter.categoryId ? filter.categoryId : ''}&product=${
          filter.productId ? filter.productId : ''
        }&manager=${filter.managerId ? filter.managerId : ''}`
      )
      setData(response.data.result)
    })
  }
  const content = (
    <div>
      <div className={styles.filterContainer}>
        <DateRangeFilter
          className={styles.dateFilter}
          onChange={setDateRange}
          defaultValue={moment().format('MMMM')}
          intervals={[6, 12]}
        />
        {showPostmanFilter && (
          <SearchDropdown
            placeholder={lang.formatMessage({id: 'select_postman'})}
            url='/user/v2?roles=postman&blocked=false'
            searchUrl='/user/v2?roles=manager&blocked=false&name='
            onSelect={(selectedValue) =>
              setFilter({
                ...filter,
                postmanId: selectedValue,
              })
            }
            renderItem={(item) => ({
              key: item._id,
              value: item._id,
              name: item.fullname,
            })}
            className={styles.filter}
          />
        )}
        <SearchDropdown
          placeholder={lang.formatMessage({id: 'select_manager'})}
          url='/user/v2?roles=manager&blocked=false'
          searchUrl='/user/v2?roles=manager&blocked=false&name='
          onSelect={selectedValue =>
            setFilter({
              ...filter,
              managerId: selectedValue
            })
          }
          renderItem={item => ({
            key: item._id,
            value: item._id,
            name: item.fullname
          })}
          className={styles.filter}
        />
        <Select
          allowClear={true}
          loading={categoriesLoading}
          className={styles.filter}
          placeholder={lang.formatMessage({id: 'select_category'})}
          showSearch
          onChange={(e) => {
            setFilter({...filter, categoryId: e, productId: undefined})
            getProducts(e ? e : '')
          }}
          optionFilterProp='children'
          filterOption={(input, option) => {
            if (!option.children) {
              return true
            }
            return option.children.toLowerCase().includes(input.toLowerCase())
          }}
        >
          {categories.map((category) => (
            <Option key={category._id} value={category._id}>
              {category.name}
            </Option>
          ))}
        </Select>
        <Select
          loading={productsLoading}
          allowClear={true}
          // value={filter.productId}
          className={styles.productFilter}
          placeholder={lang.formatMessage({id: 'select_product'})}
          showSearch
          onChange={(e) => {
            setFilter({...filter, productId: e})
          }}
          optionFilterProp='children'
          filterOption={(input, option) => {
            if (!option.children) {
              return true
            }
            return option.children.toLowerCase().includes(input.toLowerCase())
          }}
        >
          {!productsLoading &&
          products.map((product) => (
            <Option key={product._id} value={product._id}>
              {`(${product.product_id}) ${product.description}`}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.dataContainer}>
        <PostmanTransactionTable
          dataSet={data}
          loading={dataLoading}
          date={moment(dateRange.start).format('MMMM_YYYY')}
        />
        <PostmanChart dataSet={data} loading={dataLoading}/>
      </div>
    </div>
  )

  const constructScreen = () => {
    return showTitle ? (
      <SBContent title={lang.formatMessage({id: 'postman_statistics'})}>
        {content}
      </SBContent>
    ) : content
  }

  return constructScreen()
}

export default DashboardPostmen
