import React, {useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import {Spin} from 'antd'
import {useIntl} from 'react-intl'
import TotalCard from './TotalCard'
import styles from './statistics.module.css'
import {formatNumber} from "../helper/Integer/IntegerFormatter";

export default function ManagerProfitTransactionChart({ dataSet, loading }) {
  const lang = useIntl()
  const [data, setData] = useState({
    profit: { data: [], total: 0 },
    transaction: { data: [], total: 0 },
    expense: { data: [], total: 0 },
    managers: [],
    balance: { currency: '', currency_text: '' },
  })

  useEffect(() => {
    if (dataSet) {
      parseData()
    }
  }, [dataSet])

  const parseData = () => {
    const {
      items,
      totalProfit,
      totalTransactions,
      totalExpenses,
      balance,
    } = dataSet
    const { managers, profit, transaction, expenses } = items.reduce(
      (data, el) => {
        data.profit.push(el.profit)
        data.transaction.push(el.transaction)
        data.expenses.push(el.expense ? el.expense : 0)
        data.managers.push(el.manager.fullname)
        return data
      },
      { managers: [], profit: [], transaction: [], expenses: [] }
    )

    setData({
      profit: { data: profit, total: totalProfit },
      transaction: { data: transaction, total: totalTransactions },
      expense: { data: expenses, total: totalExpenses },
      managers: managers,
      balance: balance,
    })
  }

  const dataSeries = [
    {
      name: lang.formatMessage({id: 'profits'}),
      data: data.profit.data,
    },
    {
      name: lang.formatMessage({id: 'transactions'}),
      data: data.transaction.data,
    },
    {
      name: lang.formatMessage({id: 'expenses'}),
      data: data.expense.data,
    },
  ]

  const options = {
    chart: {
      type: 'bar',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    title: {
      text: lang.formatMessage({id: 'manager_profit_transactions'}),
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: data.managers,
    },
    legend: {
      position: 'top',
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return formatNumber(value, '֏')
        },
      },
    },
  }

  return (
    <Spin spinning={loading}>
      <TotalCard
        totalProfit={data.profit.total}
        totalTransactions={data.transaction.total}
        totalExpense={data.expense.total}
        balance={data.balance}
        className={styles.totalsCard}
      />
      <ReactApexChart
        className={styles.statisticsCharts}
        options={options}
        series={dataSeries}
        type='bar'
        height={450}
      />
    </Spin>
  )
}
