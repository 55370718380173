import React, {useEffect, useState} from 'react'
import Api from '../../api'
import styles from './user.module.scss'
import SBContent from '../helper/SidebarContent/'
import DynamicFields from '../helper/DynamicFields'
import {Button, Form, Input, message, Select, Spin, Upload} from 'antd'
import Icon from '@ant-design/icons'
import {useHistory} from 'react-router-dom'
import {FormattedMessage, useIntl} from 'react-intl'

const {Option} = Select

function UserCreate() {
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(undefined)
  const [form] = Form.useForm()
  const history = useHistory()
  const language = useIntl()

  useEffect(() => {
    getRoles()
    form.setFieldsValue({
      password: genereatedPassword(),
    })
  }, [])

  const genereatedPassword = () => Math.random().toString(36).slice(-8)

  async function getRoles() {
    try {
      const response = await Api.get('/role')
      setRoles(response.data.result.data.filter((role) => {
        return role.name !== 'superadmin'
      }))
    } catch (ex) {}
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      const data = values
      data['location'] = {type: 'Point', coordinates: [data.lng, data.lat]}
      const response = await Api.post('/user', data)
      const userId = response.data.result.user._id
      await uploadImage(userId)
      history.push(`/view/user/${userId}`)
    } catch (ex) {
      if (ex.response) {
        message.error(ex.response.data.message)
        return
      }
      message.error(ex.message)
      console.error(ex)
    } finally {
      setLoading(false)
    }
  }

  async function uploadImage(userId) {
    try {
      const formData = new FormData()
      if (image) {
        formData.append('photo', image)
        formData.append('user_id', userId)
      }
      await Api.post('/user/uploadImage', formData)
    } catch (ex) {}
  }

  return (
    <SBContent title={language.formatMessage({id: 'create_user'})}>
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <div className={styles.userEditFormContainer}>
            <div>
              <Upload
                type
                name='logo'
                listType='picture'
                accept='jpg'
                showUploadList={false}
                beforeUpload={(file) => {
                  setImage(file)
                  return false
                }}
              >
                <Button className={styles.userEditUploadImage}>
                  <Icon type='upload'/>
                  <FormattedMessage id='click_to_upload'/>
                </Button>
              </Upload>
              <img
                style={{marginLeft: 20}}
                src={image ? URL.createObjectURL(image) : ''}
                width='100'
              />
              <Form.Item
                label={language.formatMessage({id: "name"})}
                name='name'
                rules={[
                  {
                    required: true,
                    message: language.formatMessage({
                      id: 'fill_user_name',
                    }),
                  },
                ]}
              >
                <Input/>
              </Form.Item>
              <Form.Item
                label={language.formatMessage({id: "surname"})}
                name='surname'
                rules={[
                  {
                    required: true,
                    message: language.formatMessage({
                      id: 'fill_user_surname',
                    }),
                  },
                ]}
              >
                <Input/>
              </Form.Item>
              <Form.Item
                label={language.formatMessage({id: 'username'})}
                name='username'
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value.match(/^[0-9a-zA-Z]+$/)) {
                        return Promise.reject(
                          language.formatMessage({id: 'username_rule'})
                        )
                      }
                      return Promise.resolve()
                    },
                    message: language.formatMessage({
                      id: 'username_rule',
                    }),
                  },
                ]}
              >
                <Input autoComplete='off'/>
              </Form.Item>
              <Form.Item
                label={language.formatMessage({id: "password"})}
                name='password'
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (
                        value.match(
                          /[^a-zA-Z0-9\\,\\.\\;\\:\\?\\>\\<\\/\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\-\\_\\+\\=\\|]/g
                        )
                      ) {
                        return Promise.reject(
                          language.formatMessage({id: 'password_rule'})
                        )
                      }
                      return Promise.resolve()
                    },
                    message: language.formatMessage({
                      id: 'password_rule',
                    }),
                  },
                ]}
              >
                    <span className={styles.passwordForm}>
                      <Input.Password
                        autocomplete='new-password'
                        prefix={
                          <Icon
                            type='lock'
                            style={{color: 'rgba(0,0,0,.25)'}}
                          />
                        }
                      />
                    </span>
              </Form.Item>
              <Form.Item
                label={language.formatMessage({id: 'select_role'})}
                name='role'
                rules={[
                  {
                    required: true,
                    message: language.formatMessage({
                      id: 'fill_user_role',
                    }),
                  },
                ]}
              >
                <Select>
                  {roles.map((role) => (
                    <Option key={role._id} value={role._id}>
                      {role.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name='address'
                         label={language.formatMessage({id: 'select_address'})}>
                <Input />
              </Form.Item>
              <Form.Item label={language.formatMessage({id: 'phone_number'})} name='phone_number'>
                <Input type='tel'/>
              </Form.Item>
              <Form.Item
                label={language.formatMessage({ id: "hvhh" })}
                name='hvhh'
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value) return Promise.resolve()
                      if (value.length == 0 || value.length == 8) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        language.formatMessage({id: 'hvhh_rule'})
                      )
                    },
                    message: language.formatMessage({id: 'hvhh_rule'}),
                  },
                ]}
              >
                <Input
                  max={8}
                  min={8}/>
              </Form.Item>
              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  <FormattedMessage id='create'/>
                </Button>
              </Form.Item>
            </div>
            <div style={{paddingTop: 52}}>
              <DynamicFields/>
            </div>
          </div>
        </Form>
      </Spin>
    </SBContent>
  )
}

export default UserCreate
