import React, { useState, useEffect, Fragment } from 'react'
import {
  Row,
  Upload,
  List,
  Modal,
  Form,
  Input,
  Button,
  Divider,
  Col,
  Avatar,
} from 'antd'
import Icon from '@ant-design/icons'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLoading } from '../helper/Hooks'
import API from '../../api'

export default function CreateCategory({
  visibility,
  onCategoryCreate,
  onCancel,
}) {
  const { loading, startLoading } = useLoading()
  const [image, setImage] = useState(undefined)
  const language = useIntl()

  async function onCreate(values) {
    startLoading(async () => {
      const response = await API.post(`/category`, values)
      if (response.status == 200) {
        await uploadImage(response.data.result.data._id)
        onCategoryCreate()
      }
    })
  }

  async function uploadImage(categoryId) {
    const formData = new FormData()
    if (image) {
      formData.append('photo', image)
      formData.append('category_id', categoryId)
      try {
        await API.post('/category/uploadImage', formData)
      } catch (ex) {
        console.error(ex)
      }
    }
    setImage(undefined)
  }

  return (
    <Modal
      width={700}
      title={<FormattedMessage id='create_category' />}
      visible={visibility}
      onCancel={() => {
        onCancel()
      }}
      footer={''}
    >
      <Form onFinish={onCreate}>
        <Form.Item name='name' rules={[{ required: true }]}>
          <Input placeholder={language.formatMessage({ id: 'name' })} />
        </Form.Item>

        <Form.Item>
          <div>
            <Upload
              type
              name='logo'
              listType='picture'
              accept='jpg'
              showUploadList={false}
              beforeUpload={(file) => {
                setImage(file)
                return false
              }}
            >
              <Button>
                <Icon type='upload' /> <FormattedMessage id='click_to_upload' />
              </Button>
            </Upload>
            <img
              style={{ marginLeft: 20 }}
              src={image ? URL.createObjectURL(image) : ''}
              width='100'
            />
          </div>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' loading={loading}>
            <FormattedMessage id='create' />
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
