import React, { useState } from 'react'
import { Input, message } from 'antd'
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons'

const DynamicCountModifier = ({
  onSubmit,
  disabled = true,
  info: { name, suffix = '' },
  width = '100%',
  maxValue = -1,
  onChange
}) => {
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState(0)

  const handleSubmit = async () => {
    if (!amount) {
      return
    }
    setLoading(true)
    try {
      await onSubmit(parseInt(amount))
      setAmount(0)
    } catch (err) {
      message.error(err.message)
    }
    setLoading(false)
  }

  const max = maxValue !== -1 ? {max : maxValue} : {}

  return (
    <Input
      {...max}
      disabled={disabled}
      style={{ width: width, textAlign: 'right' }}
      type='number'
      min={0}
      step={100}
      size='small'
      suffix={suffix}
      prefix={<b>{name}</b>}
      value={amount}
      onChange={(e) => {
        const changeAmount = parseInt(e.target.value)
        let amount
        if (maxValue === -1) {
          amount = changeAmount
        } else {
          amount = Math.min(changeAmount, maxValue)
        }

        setAmount(amount)

        if(onChange) {
          onChange(amount)
        }
      }}
      addonAfter={
        loading ? <LoadingOutlined /> : <CheckOutlined onClick={handleSubmit} />
      }
    />
  )
}

export default DynamicCountModifier
