import { Select, Spin } from 'antd'
import React, { useEffect, useRef } from 'react'
import useSearchDropdownUseCase from './SearchDropdownUseCase'

const { Option } = Select

export default function SearchDropdown({
  key = Date.now(),
  url,
  searchUrl,
  onSelect,
  renderItem,
  width,
  label,
  placeholder,
  processResult = (data) => data,
  enabled = true,
  value = undefined,
  ...props
}) {
  const dropdown = useRef(null)
  const timeoutRef = useRef(null)
  const { data, loading, getItems } = useSearchDropdownUseCase(
    url,
    processResult,
    enabled
  )

  useEffect(() => () => timeoutRef.current = null)

  const handleChange = (value, option) => {
      if (!value) {
        getItems(url, false)
      }
      onSelect(value, option)
  }

  const handleSearch = (value) => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      getItems(searchUrl + value, false)
    }, 400);
  }

  const onScroll = () => {
    if (!dropdown.current) {
      return
    }
    const lastElement = document.getElementById(
      `${key}_${data.data.length - 1}`
    )
    if (!lastElement) {
      return
    }
    const containerTop = dropdown.current.getBoundingClientRect().top
    const lastElementTopPos =
      lastElement.getBoundingClientRect().top - containerTop
    const containerHeight = dropdown.current.getBoundingClientRect().height

    if (!loading && data.hasMore && lastElementTopPos - 15 < containerHeight) {
      if (data.nextPage) getItems(data.nextPage)
    }
  }

  return (
    <div style={props.style}>
      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        {label}
      </span>
      <Select
        value={value}
        disabled={!enabled}
        placeholder={placeholder}
        showSearch={searchUrl}
        onSearch={searchUrl ? handleSearch : null}
        allowClear
        style={{ width: width, marginLeft: placeholder ? 0 : 10 }}
        className={props.className}
        onChange={handleChange}
        filterOption={false}
        onPopupScroll={onScroll}
        loading={loading}
        dropdownRender={(menu) => (
          <div ref={(el) => (dropdown.current = el)}>
            {menu}
            <div
              style={{
                height: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin spinning={loading} size='small' />
            </div>
          </div>
        )}
      >
        {data.data.map((item, index) => {
          const mappedItem = renderItem(item)
          return (
            <Option
              key={mappedItem.key}
              id={`${key}_${index}`}
              value={mappedItem.value}
            >
              {mappedItem.name}
            </Option>
          )
        })}
      </Select>
    </div>
  )
}
