import { Empty, List, Spin } from 'antd'
import React from 'react'
import { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import API from '../../api'
import { useLoading } from './Hooks'

export default function InfiniteScrollWrapper({
  url,
  useWindow = true,
  render,
  processResult = (data) => data,
}) {
  const [data, setData] = useState({
    data: [],
    nextPage: '',
    hasMore: false,
  })

  const { loading, startLoading } = useLoading(true)

  useEffect(() => {
    getData(url, false)
  }, [url])

  const getData = (url, shouldAppend = true) =>
    startLoading(async () => {
      const response = await API.get(url)
      const result = response.data.result
      const nextPageUrl = response.data.nextPageUrl
      const data = processResult(result)
      if (shouldAppend) {
        appendData(data, nextPageUrl)
      } else {
        updateData(data, nextPageUrl)
      }
    })

  const appendData = (newData, nextPageUrl) => {
    setData({
      data: [...data.data, ...newData],
      nextPage: nextPageUrl,
      hasMore: newData.length !== 0,
    })
  }

  const updateData = (newData, nextPageUrl) => {
    setData({
      data: [...newData],
      nextPage: nextPageUrl,
      hasMore: newData.length >= 10,
    })
  }

  return (
    <InfiniteScroll
      useWindow={useWindow}
      pageStart={0}
      loadMore={() => getData(data.nextPage)}
      hasMore={!loading && data.hasMore}
    >
      <List dataSource={data.data} renderItem={render} />
      <Spin style={{marginTop: "20",  width:  "100%"}} spinning={loading}/>
    </InfiniteScroll>
  )
}
