import { Button, message, Modal, Select } from "antd"
import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import API from "../../../api"
import { useLoading } from "../../helper/Hooks"
import styles from "./info.module.scss"

const { Option } = Select
const { confirm } = Modal

function AddProduct({ userId, onAdd, products }) {
  const [notHaveProducts, setNotHaveProducts] = useState([])
  const language = useIntl()
  const [filter, setFilter] = useState({
    productIds: [],
    categoryId: undefined,
  })

  const { loading: productsLoading, startLoading: startProductsLoading } =
    useLoading()
  const { loading: addProductLoading, startLoading: startAddProductLoading } =
    useLoading()
  const { loading: categoriesLoading, startLoading: startCategoryLoading } =
    useLoading()
  const [categories, setCategories] = useState([])

  const addProduct = () => {
    startAddProductLoading(async () => {
      try {
        await API.post("/product/user/add-multiple", {
          user_id: userId,
          products: filter.productIds,
        })
        setFilter({
          productIds: [],
          categoryId: undefined,
        })
        onAdd()
        getProducts()
      } catch (e) {
        message.error(e.response.data ? e.response.data.message : e.message)
      }
    })
  }

  const addCategory = () => {
    confirm({
      title: language.formatMessage({ id: "add_category_question" }),
      async onOk() {
        startAddProductLoading(async () => {
          try {
            await API.post("/product/user/add-multiple", {
              user_id: userId,
              category: filter.categoryId,
            })
            setFilter({
              productIds: [],
              categoryId: undefined,
            })
            onAdd()
            getProducts()
          } catch (e) {
            message.error(e.response.data ? e.response.data.message : e.message)
          }
        })
      },
      onCancel() {},
    })
  }

  async function addAllProducts() {
    confirm({
      title: language.formatMessage({ id: "add_all_products" }),
      async onOk() {
        try {
          await API.post("product/addAll", { user_id: userId })
          setFilter({
            productIds: [],
            categoryId: undefined,
          })
          onAdd()
        } catch (e) {
          message.error(e.response.data.message)
        }
      },
      onCancel() {},
    })
  }

  const getProducts = async (categoryId = "") => {
    startProductsLoading(async () => {
      const response = await API.get(
        `product/nothave?user_id=${userId}&category=${categoryId}`
      )
      setNotHaveProducts(response.data.result.products)
    })
  }

  const getCategories = () => {
    startCategoryLoading(async () => {
      const response = await API.get("/category")
      setCategories(response.data.result.data)
    })
  }

  useEffect(() => {
    getCategories()
    getProducts()
  }, [products])

  return (
    <div>
      {(notHaveProducts.length > 0 || filter.categoryId) && (
        <div className={styles.addProductContainer}>
          <div className={styles.dropdownContainer}>
            <Select
              value={filter.categoryId}
              loading={categoriesLoading}
              allowClear={true}
              className={styles.filter}
              placeholder={language.formatMessage({ id: "select_category" })}
              showSearch
              onChange={(e) => {
                setFilter({
                  categoryId: e,
                  productIds: [],
                })
                getProducts(e ? e : "")
              }}
              optionFilterProp="children"
              filterOption={(input, option) => {
                if (!option.children) {
                  return true
                }
                return option.children
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }}
            >
              {categories.map((category) => (
                <Option key={category._id} value={category._id}>
                  {category.name}
                </Option>
              ))}
            </Select>
            <Select
              mode="multiple"
              placeholder={language.formatMessage({ id: "select_products" })}
              loading={productsLoading}
              allowClear={true}
              className={styles.filter}
              showSearch
              optionFilterProp="children"
              value={filter.productIds}
              filterOption={(input, option) => {
                if (!option.children) {
                  return true
                }
                return option.children
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  productIds: e,
                })
              }}
            >
              {notHaveProducts.map((item) => (
                <Option key={item._id} value={item._id}>
                  {`(${item.product_id}) ${item.description}`}
                </Option>
              ))}
            </Select>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              disabled={filter.productIds.length === 0}
              type="primary"
              onClick={addProduct}
              loading={addProductLoading}
            >
              <FormattedMessage id="add_selected" />
            </Button>
            <Button
              type="primary"
              disabled={!filter.categoryId}
              onClick={addCategory}
            >
              <FormattedMessage id="add_category" />
            </Button>
            <Button onClick={addAllProducts}>
              <FormattedMessage id="add_all" />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default AddProduct
