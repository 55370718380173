import React, {useState} from 'react'
import {List, Avatar, Input, InputNumber} from 'antd'
import {CheckOutlined} from '@ant-design/icons'
import {useEffect} from 'react'
import {IntTextFormatter} from "../../helper/Integer/IntegerFormatter";
import {useIntl} from "react-intl";

export default function ProductItem({product, onBundleChange}) {
  const [changeAmount, setChangeAmount] = useState(0)
  const lang = useIntl()

  useEffect(() => {
    setChangeAmount(product.count ? product.count : 0)
  }, [product])

  return (
    <List.Item key={product.product_id}>
      <List.Item.Meta
        avatar={<Avatar shape='square' size={64} src={product.image_url}/>}
        title={
          <strong
            style={{fontSize: 16}}
          >{`(${product.product_id}) ${product.description}`}</strong>
        }
        description={
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <span style={{fontSize: 14, color: 'black'}}>
              <IntTextFormatter prefix={`${lang.formatMessage({id: "total_cost"})}:`} currencyText={product.currency_text}
                                number={product.price * (changeAmount ? changeAmount : 0)}/>
            </span>
            <span style={{fontSize: 14, color: 'black'}}>
              <IntTextFormatter prefix={`${lang.formatMessage({id: "in_warehouse"})}:`} nextLine={true}
                                number={product.total_count - (changeAmount ? changeAmount : 0)}/>
            </span>
          </div>
        }
      />
      <div style={{margin: 10, textAlign: 'end'}}>
        <span style={{fontSize: 14, color: 'black'}}>
          <IntTextFormatter prefix={`${lang.formatMessage({id: "item_cost"})}:`} currencyText={product.currency_text}
                            number={product.price}/>
        </span>
        <div style={{marginTop: 10}}>
          <InputNumber
            style={{width: 150}}
            size='small'
            value={changeAmount}
            min={0}
            max={product.total_count}
            onChange={(e) => {
              const value = parseInt(e)
              const change = isNaN(value) ? undefined : value
              setChangeAmount(change)
              onBundleChange({...product, count: change})
            }}
            onFocus={(e) => {
              e.target.select()
            }}
          />
        </div>
      </div>
    </List.Item>
  )
}
