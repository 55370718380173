import React, { useState, useRef } from 'react'
import { Input, Button, Modal, InputNumber, message } from 'antd'
import { EditFilled } from '@ant-design/icons'

const EditItem = ({
  count,
  info: { name, suffix = '' },
}) => {
  const [total, setTotal] = useState(count)

  return (
    <Input
      type='number'
      step={100}
      size='small'
      prefix={name}
      suffix={suffix}
      disabled={true}
      value={total}
      onChange={(e) => setTotal(e.target.value)}
    />
  )
}

export default EditItem
