import React, { useEffect, useState } from 'react'
import { Avatar, InputNumber, List, Spin, message } from 'antd'
import Api from '../../../api'
import CoefficientItem from './CoefficientItem'
import AddProduct from './AddProduct'
import { useLoading } from '../../helper/Hooks'
import { hasPermission } from '../../../api/user'
import { FormattedMessage, useIntl } from 'react-intl'
import styles from './info.module.scss'
import API from '../../../api'
import ConfirmButton from '../../helper/ConfirmButton'

function UserProduct ({ userId, userDiscount }) {
  const lang = useIntl()
  const [updateBtnDisabled, setUpdateBtnDisabled] = useState(true)
  const [products, setProducts] = useState([])
  const [sale, setSale] = useState()
  const { loading, startLoading } = useLoading()

  useEffect(() => {
    setUpdateBtnDisabled(true)
    setSale(userDiscount)
    getProducts()
  }, [])

  const getProducts = async () => {
    startLoading(async () => {
      const response = await Api.get(`/product/user?clientId=${userId}`)
      setProducts(response.data.result.products)
    })
  }

  const updateSale = async () => {
    try {
      await API.post('/user/client/discount', {
        user_id: userId,
        discount: sale
      })
      getProducts()
      setUpdateBtnDisabled(true)
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.message)
      } else {
        message.error(lang.formatMessage({ id: 'discount_update_error' }))
      }
    }
  }

  return (
    <div>
      <AddProduct userId={userId} onAdd={getProducts} products={products} />
      {products.length > 0 && (
        <div className={styles.saleContainer}>
          <InputNumber
            placeholder={lang.formatMessage({ id: 'set_sale' })}
            value={sale}
            min={0}
            max={100}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
            onChange={value => {
              if (value && !isNaN(value)) {
                setSale(value)
              } else {
                setSale(0)
              }
              setUpdateBtnDisabled(false)
            }}
          />
          <ConfirmButton
            type='primary'
            onClick={updateSale}
            confirmTitle={lang.formatMessage({
              id: 'discount_update_confirm_title'
            })}
            confirmContent={lang.formatMessage({
              id: 'discount_update_confirm_content'
            })}
            disabled={updateBtnDisabled}
          >
            <FormattedMessage id='update_sale' />
          </ConfirmButton>
        </div>
      )}
      <Spin spinning={loading}>
        <List
          dataSource={products}
          renderItem={item => (
            <List.Item key={item._id}>
              <List.Item.Meta
                avatar={
                  <Avatar shape='square' size={64} src={item.image_url} />
                }
                title={`(${item.product_id}) ${item.description}`}
                description={
                  hasPermission('product_actual_price_edit') ||
                  hasPermission('product_actual_price_view')
                    ? `${lang.formatMessage({ id: 'price' })}: ${item.price}`
                    : ''
                }
              />
              <CoefficientItem
                userId={userId}
                product={item}
                onRemove={getProducts}
              />
            </List.Item>
          )}
        />
      </Spin>
    </div>
  )
}

export default UserProduct
