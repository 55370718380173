import React, {useState, useEffect} from 'react'
import SBContent from '../helper/SidebarContent/'
import {Form, Input, Button, InputNumber, Select, Upload} from 'antd'
import Icon from '@ant-design/icons'
import API from '../../api'
import {useHistory} from 'react-router-dom'
import api from '../../api'
import {useIntl, FormattedMessage} from 'react-intl'

const {Option} = Select

function ProductInfo() {
  let history = useHistory()
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(undefined)
  const [categories, setCategories] = useState([])
  const language = useIntl()

  useEffect(() => {
    fetchCategories()
  }, [])

  const createProduct = async (data) => {
    setLoading(true)
    const formData = new FormData()
    if (image) {
      formData.append('photo', image)
    }

    Object.keys(data).forEach((key, value) => {
      formData.append(key, data[key])
    })

    const result = await API.post(`product/`, formData, {})
    setLoading(false)
  }

  async function fetchCategories() {
    const resposne = await api.get('/category')

    setCategories(resposne.data.result.data)
  }

  const handleSubmit = async (values) => {
    try {
      await createProduct(values)
    } finally {
      setLoading(false)
    }
    history.goBack()
  }
  return (
    <SBContent title={language.formatMessage({id: "create_product"})}>
      <Form
        labelCol={{span: 5}}
        wrapperCol={{span: 12}}
        onFinish={handleSubmit}
      >
        <Form.Item label={language.formatMessage({id: 'upload'})}>
          <div>
            <Upload
              type
              name='logo'
              listType='picture'
              accept='jpg'
              showUploadList={false}
              beforeUpload={(file) => {
                setImage(file)
                return false
              }}
            >
              <Button>
                <Icon type='upload'/>
                <FormattedMessage id='click_to_upload'/>
              </Button>
            </Upload>
            <img
              style={{marginLeft: 20}}
              src={image ? URL.createObjectURL(image) : ''}
              width='100'
            />
          </div>
        </Form.Item>
        <Form.Item
          label={language.formatMessage({id: "id"})}
          name='product_id'
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (value.match(/[\s]/)) {
                  return Promise.reject(
                    language.formatMessage({id: 'id_rule'})
                  )
                }
                return Promise.resolve()
              },
              message: language.formatMessage({id: 'id_rule'}),
            },
          ]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={language.formatMessage({id: 'name'})}
          name='description'
          rules={[
            {
              required: true,
              message: language.formatMessage({
                id: 'fill_product_description',
              }),
            },
          ]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={language.formatMessage({id: 'default_price'})}
          name='price'
          rules={[
            {
              required: true,
              message: language.formatMessage({id: 'fill_product_price'}),
            },
          ]}
        >
          <InputNumber step='1000' style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item
          label={language.formatMessage({id: 'actual_price'})}
          name='actual_price'
          rules={[
            {
              required: true,
              message: language.formatMessage({
                id: 'fill_product_actual_price',
              }),
            },
          ]}
        >
          <InputNumber step='1000' style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item
          label={language.formatMessage({id: 'category'})}
          name='category'
          rules={[
            {
              required: true,
              message: language.formatMessage({
                id: 'fill_product_category',
              }),
            },
          ]}
        >
          <Select>
            {categories.map((category) => (
              <Option value={category._id}>{category.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{span: 12, offset: 5}}>
          <Button type='primary' htmlType='submit' loading={loading}>
            <FormattedMessage id='create'/>
          </Button>
        </Form.Item>
      </Form>
    </SBContent>
  )
}

export default ProductInfo
