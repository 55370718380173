import style from '@mapbox/mapbox-gl-directions/src/directions_style'

const zoomRatios = {
  0: 78271.484,
  1: 39135.742,
  2: 19567.871,
  3: 9783.936,
  4: 4891.968,
  5: 2445.984,
  6: 1222.992,
  7: 611.496,
  8: 305.748,
  9: 152.874,
  10: 76.437,
  11: 38.218,
  12: 19.109,
  13: 9.555,
  14: 4.777,
  15: 2.389,
  16: 1.194,
  17: 0.597,
  18: 0.299,
  19: 0.149,
  20: 0.075,
  21: 0.037,
  22: 0.019,
}

const customStyle = [...style.slice(0, style.length - 4),
  {
    'id': 'directions-origin-point',
    'type': 'circle',
    'source': 'directions',
    'paint': {
      'circle-radius': 14,
      'circle-color': '#3bb2d0'
    },
    'filter': [
      'all',
      ['in', '$type', 'Point'],
      ['in', 'marker-symbol', 'A']
    ]
  }, {
    'id': 'directions-origin-label',
    'type': 'symbol',
    'source': 'directions',
    'layout': {
      'text-field': 'A',
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-size': 0
    },
    'paint': {
      'text-color': '#fff'
    },
    'filter': [
      'all',
      ['in', '$type', 'Point'],
      ['in', 'marker-symbol', 'A']
    ]
  }, {
    'id': 'directions-destination-point',
    'type': 'circle',
    'source': 'directions',
    'paint': {
      'circle-radius': 14,
      'circle-color': '#8a8bc9'
    },
    'filter': [
      'all',
      ['in', '$type', 'Point'],
      ['in', 'marker-symbol', 'B']
    ]
  }, {
    'id': 'directions-destination-label',
    'type': 'symbol',
    'source': 'directions',
    'layout': {
      'text-field': 'B',
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-size': 0
    },
    'paint': {
      'text-color': '#fff'
    },'filter': [
      'all',
      ['in', '$type', 'Point'],
      ['in', 'marker-symbol', 'B']
    ]
  }
]

export {customStyle, zoomRatios}