import { Button, Input, Modal, Select } from "antd"
import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import API from "../../../../api"
import { useLoading } from "../../../helper/Hooks"
import SearchDropdown from "../../../helper/searchDropdown/SearchDropdown"

const { Option } = Select

const ProductGroupPriceModal = ({ onCancel, onConfirm, visible }) => {
  const lang = useIntl()
  const [selectedManager, setSelectedManager] = useState()
  const [groups, setGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState()
  const [price, setPrice] = useState(0)
  const { loading: groupLoading, startLoading: startGroupLoading } =
    useLoading()
  const { loading, startLoading } = useLoading()

  useEffect(() => {
    if (!visible) {
      setSelectedManager()
      setSelectedGroup()
      setGroups([])
      setPrice(0)
    }
  }, [visible])

  useEffect(() => {
    if (selectedManager) {
      getGroups()
    }
  }, [selectedManager])

  const getGroups = () => {
    startGroupLoading(async () => {
      const response = await API.get(
        `/managerGroup/manager?manager=${selectedManager}`
      )
      setGroups(response.data.result)
      if (response.data.result[0]) {
        setSelectedGroup(response.data.result[0]._id)
      } else {
        setSelectedGroup(undefined)
      }
    })
  }

  const handleConfirm = () => {
    startLoading(async () => {
      await onConfirm({ group: selectedGroup, price })
    })
  }

  return (
    <>
      <Modal
        title={lang.formatMessage({ id: "set_group_price" })}
        visible={visible}
        onCancel={() => onCancel(false)}
        footer={[
          <Button
            disabled={loading}
            key="back"
            type="danger"
            onClick={() => onCancel(false)}
          >
            <FormattedMessage id="cancel" />
          </Button>,
          <Button
            disabled={!selectedGroup}
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleConfirm}
          >
            <FormattedMessage id="ok" />
          </Button>,
        ]}
      >
        <div style={{ display: "flex" }}>
          <SearchDropdown
            value={selectedManager}
            placeholder={lang.formatMessage({ id: "select_manager" })}
            url="/user/v2?roles=manager&blocked=false"
            searchUrl="/user/v2?roles=manager&blocked=false&name="
            onSelect={(manager) => setSelectedManager(manager)}
            renderItem={(item) => ({
              key: item._id,
              value: item._id,
              name: item.fullname,
            })}
          />
          <Select
            loading={groupLoading}
            allowClear={false}
            style={{ width: 200, marginLeft: 20 }}
            placeholder={lang.formatMessage({ id: "select_group" })}
            onChange={(e) => {
              setSelectedGroup(e)
            }}
            value={selectedGroup}
          >
            {groups.map((group) => (
              <Option key={group._id} value={group._id}>
                {group.name}
              </Option>
            ))}
          </Select>
        </div>
        <div style={{ marginTop: 10, width: 144 }}>
          <Input
            type="number"
            step={100}
            size="small"
            value={price}
            addonBefore={
              <div style={{ textAlign: "left", width: 40 }}>
                <FormattedMessage id="price" />
              </div>
            }
            min={0}
            onChange={(e) => {
              const value = parseInt(e.target.value)
              if (!Number.isNaN(value) || value > 0) {
                setPrice(value)
              } else {
                setPrice(0)
              }
            }}
          />
        </div>
      </Modal>
    </>
  )
}

export default ProductGroupPriceModal
