import React from 'react'
import { Card, Divider, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import { IntTextFormatter } from '../../helper/Integer/IntegerFormatter'
import moment from 'moment'
import { isUserClient } from '../../../api/user'

const BalanceChange = ({ item, user }) => {
  if (!isUserClient(user)) {
    return (
      (item.amount || item.amount !== 0) && (
        <div>
          <Typography.Text>
            <IntTextFormatter
              number={item.amount}
              currencyText={'֏'}
              prefixNumber={-1}
              prefix={item.amount < 0 ? '+' : ''}
            />
          </Typography.Text>
        </div>
      )
    )
  } else {
    return item.order ? (
      <div>
        {item.order.order_type !== 'RETURN' ? (
          <Typography.Text>
            <IntTextFormatter
              number={item.order.total_price - item.amount}
              currencyText={'֏'}
              prefix={item.order.total_price - item.amount > 0 ? '+' : ''}
            />
          </Typography.Text>
        ) : (
          <Typography.Text>
            <IntTextFormatter
              number={item.order.total_price + item.amount}
              prefixNumber={-1}
              currencyText={'֏'}
              prefix={item.order.total_price + item.amount <= 0 ? '+' : ''}
            />
          </Typography.Text>
        )}
      </div>
    ) : (
      <div>
        <Typography.Text>
          <IntTextFormatter
            number={item.amount}
            prefixNumber={-1}
            currencyText={'֏'}
            prefix={item.amount <= 0 ? '+' : ''}
          />
        </Typography.Text>
      </div>
    )
  }
}

const UserBalanceHistoryItem = ({ item, user }) => {
  return (
    <Card
      key={item._id}
      style={{ marginTop: 10, border: '2px solid #00000033' }}
    >
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text>
              <FormattedMessage id='from' />:{' '}
              {item.from_user ? (
                item.from_user.fullname
              ) : (
                <FormattedMessage id='user_is_removed' />
              )}
            </Typography.Text>
            <Typography.Text>
              <FormattedMessage id='to' />:{' '}
              {item.to_user ? (
                item.to_user.fullname
              ) : (
                <FormattedMessage id='user_is_removed' />
              )}
            </Typography.Text>
            <div style={{ marginLeft: 20 }}>
              {item.order && (
                <div>
                  <Typography.Text>
                    <FormattedMessage id='order_price' />:{' '}
                    <IntTextFormatter
                      number={item.order.total_price}
                      currencyText={'֏'}
                    />
                  </Typography.Text>
                </div>
              )}
            </div>
            <div style={{ marginLeft: 20 }}>
              <Typography.Text>
                {item.order && item.order.order_type === 'RETURN' ?<FormattedMessage id='manager_paid' />: <FormattedMessage id='payed' />}:{' '}
                <IntTextFormatter
                  number={item.amount}
                  currencyText={'֏'}
                  prefixNumber={-1}
                />
              </Typography.Text>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'right'
            }}
          >
            <Typography.Text strong={true}>
              <FormattedMessage id='status' />: {item.type}
            </Typography.Text>
            {user.role.name !== 'superadmin' && (
              <Typography.Text>
                {item.to_user.fullname} <FormattedMessage id='balance' />:{' '}
                <IntTextFormatter
                  number={item.currentBalance}
                  currencyText={'֏'}
                  prefixNumber={-1}
                />
              </Typography.Text>
            )}
          </div>
        </div>
        <Divider
          style={{
            width: '65%',
            minWidth: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 12,
            marginBottom: 12
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'flex-end'
            }}
          >
            <Typography.Text>
              <FormattedMessage id='date' />:{' '}
              {moment(item.date).format(' DD/MM/YYYY HH:mm:ss')}
            </Typography.Text>
          </div>
          <div style={{ textAlign: 'right' }}>
            <BalanceChange item={item} user={user} />
            <Typography.Text>
              <FormattedMessage id='old_balance' />:{' '}
              <IntTextFormatter
                number={item.old_balance}
                prefixNumber={-1}
                currencyText={'֏'}
              />
            </Typography.Text>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default UserBalanceHistoryItem
