import React, {useEffect, useRef, useState} from 'react'
import {message, Table} from 'antd'
import moment from 'moment'
import SContent from '../helper/SidebarContent/'
import styles from './map.module.scss'
import {useLoading} from '../helper/Hooks'
import Map from './Map'
import API from '../../api'
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";

const ManagerLocation = () => {
  const lang = useIntl()
  const {loading, startLoading} = useLoading()
  const [managers, setManagers] = useState([])
  const [markers, setMarkers] = useState([])
  const [center, setCenter] = useState({})
  const timer = useRef(0)

  useEffect(() => {
    getManagers(true)
    timer.current = setInterval(() => {
      getManagers(false)
    }, 1000 * 60 * 3)
    return () => {
      clearInterval(timer.current)
      timer.current = 0
    }
  }, [])

  const getManagers = (shouldLoad) => {
    if(shouldLoad){
      startLoading(async () => {
        await fetchData()
      })
    }else {
      fetchData()
    }
  }

  const fetchData = async () => {
    const response = await API.get(
      `user/location`
    )

    setManagers(response.data.result)
    setMarkers(mapManagersToMarkers(response.data.result))
  }

  const mapManagersToMarkers = (objs) => {
    return objs.reduce((prev, next, index) => {
      prev.push({
        type: 'Feature',
        properties: {
          title: next.fullname,
          date: next.locationDate,
          placeData: next,
          index: objs.length - index,
          icon: 'pitch-15',
        },
        geometry: {
          type: 'Point',
          coordinates: [next.location.long, next.location.lat]
        },
      })

      return prev
    }, [])
  }

  return (
    <SContent title={lang.formatMessage({id: 'manager_location_map'})} applyCss={false}>
      <div className={styles.managerMapContainer}>
        <div className={styles.managerMapFilter}>
          <Table
            showSorterTooltip={false}
            bordered={false}
            columns={[
              {
                title: lang.formatMessage({id: 'manager'}),
                dataIndex: 'fullname',
                render: (fullname, manager) => <Link
                  onClick={(e) => e.stopPropagation()}
                  to={{
                    pathname: `/view/user/${manager._id}`,
                  }}
                >
                  {fullname}
                </Link>,
              },
              {
                title: lang.formatMessage({id: 'last_date'}),
                dataIndex: 'locationDate',
                render: (date) => date ? moment(date).format('MMM-DD HH:mm:ss') : '-',
              },
            ]}
            dataSource={managers}
            loading={loading}
            pagination={false}
            scroll={{y: '77.5vh'}}
            onRow={(element) => {
              return {
                onClick: () => {
                  if (element.location.long !== 0 || element.location.lat !== 0) {
                    setCenter(element.location)
                  } else {
                    message.error(
                      lang.formatMessage({id: 'client_location_error'})
                    )
                  }
                },
              }
            }}
            rowClassName={styles.rowHoverPointer}
            rowKey={(record) => record._id}
            className={styles.managerLocationCell}
          />
        </div>
        <Map markers={markers} center={center} isMarkerClickable={false} className={styles.managerLocationMapContainer}/>
      </div>
    </SContent>
  )
}

export default ManagerLocation
