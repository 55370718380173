const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export function hasPermission(permission) {
  const user = getUser();
  return isSuperAdmin() || (user.permissions && user.permissions[permission]);
}

export function isSuperAdmin() {
  const user = getUser();
  return user.role && user.role.name === "superadmin";
}

const isManager = (user) => user.role.name === "manager";
const isPostman = (user) => user.role.name === "postman";
const isUserAdmin = (user) => user.role.name === "admin";
const isUserClient = (user) => user.role.name === "client";
const isUserSuperAdmin = (user) => user.role.name === "superadmin"

export { isManager, isPostman, isUserAdmin, isUserClient, isUserSuperAdmin };
