import React, { useContext } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { registerAuthInterceptor } from '../../api'
import { hasPermission } from '../../api/user'
import LimitationComponent from '../limits/LimitationComponent'
import UserContext from './context/UserContext'

export const AuthRoute = ({
  component: Component,
  roles,
  permissions = undefined,
  ...rest
}) => {
  const history = useHistory()
  const { user, signOut } = useContext(UserContext)

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
        registerAuthInterceptor((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              signOut()
              history.replace('/login')
            }
          }
        })

        if (permissions) {
          for (const index in permissions) {
            if (permissions.hasOwnProperty(index)) {
              const permission = permissions[index]
              if (!hasPermission(permission)) {
                return <Redirect to={{ pathname: '/' }} />
              }
            }
          }
        }

        return <LimitationComponent component={Component} {...props} />
      }}
    />
  )
}
