import React, {useRef} from "react";
import {useReactToPrint} from "react-to-print";
import {Button, Divider} from "antd";
import {FormattedMessage} from "react-intl";
import styles from './info.module.scss'


const OrderNotificationItem = ({item}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div key={item._id}>
      <p ref={componentRef} className={styles.notificationText}>
        {item.content}
      </p>
      <Button type={'primary'} onClick={handlePrint}>
        <FormattedMessage id={'print'}/>
      </Button>
      <Divider/>
    </div>
  )
}

export default OrderNotificationItem