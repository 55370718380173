import React, { useEffect, useState } from "react"
import { Avatar, Button, Card, message, Modal, Skeleton } from "antd"
import { EditFilled, ExclamationCircleOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import ProductCount from "../../EditItem"
import { FormattedMessage, useIntl } from "react-intl"
import API, { default as api } from "../../../api"
import { hasPermission, isSuperAdmin } from "../../../api/user"
import ProductGroupPrice from "./ProductGroupPrice"

const { Meta } = Card
const { confirm } = Modal

export default function ProductCard({ product, loading }) {
  const language = useIntl()

  const [archived, setArchived] = useState()

  useEffect(() => {
    setArchived(product.archived ?? false)
  }, [product])

  const onProductCountChange = async (amount, total) => {
    await API.put(`product/count/${product._id}`, {
      amount,
      total_count: total,
    })
  }

  const showBlockPopup = () =>
    confirm({
      title: `${
        archived
          ? language.formatMessage({ id: "restore" })
          : language.formatMessage({ id: "archive" })
      } ${language.formatMessage({ id: "product_lowercase" })}`,
      icon: <ExclamationCircleOutlined />,
      content: `${
        archived
          ? language.formatMessage({ id: "restore_product_question" })
          : language.formatMessage({ id: "archive_product_question" })
      }`,
      onOk: async () => {
        try {
          await API.put(
            `product/${archived ? "restore" : "archive"}/${product._id}`
          )
          message.success(
            archived
              ? language.formatMessage({ id: "product_restored" })
              : language.formatMessage({ id: "product_archived" })
          )
          setArchived(!archived)
        } catch (error) {
          message.error(error.response.data.message)
        }
      },
      onCancel: () => {},
    })

  const removeFromAll = async () => {
    confirm({
      maskClosable: true,
      title: language.formatMessage({ id: "remove_product_from_all" }),
      content: language.formatMessage({
        id: "remove_product_from_all_confirm",
      }),
      async onOk() {
        try {
          await API.delete(`product/${product._id}/remove-from-users`)
        } catch (err) {
          message.error(err.message)
        }
      },
    })
  }
  const addToAll = async () => {
    confirm({
      maskClosable: true,
      title: language.formatMessage({ id: "add_product_to_all" }),
      content: language.formatMessage({ id: "add_product_to_all_confirm" }),
      async onOk() {
        try {
          await API.put(`product/${product._id}/add-to-users`)
        } catch (err) {
          message.error(err.message)
        }
      },
    })
  }

  return (
    <Card
      title={
        product.product_id
          ? `${product.product_id} / ${product.description}`
          : ""
      }
      style={{ width: 300, marginTop: 16 }}
      actions={[
        <Link to={`/edit/product/${product._id}`}>
          <EditFilled key="edit" />{" "}
        </Link>,
      ]}
    >
      <Skeleton loading={loading} avatar active>
        <>
          <Meta
            avatar={
              <Avatar
                src={
                  product.image_url
                    ? product.image_url
                    : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                }
              />
            }
            title={
              <ProductCount
                count={product.total_count}
                info={{
                  name: language.formatMessage({ id: "total" }),
                  title: language.formatMessage({ id: "product_count" }),
                }}
              />
            }
            description={`${language.formatMessage({ id: "category" })}: ${
              product.category ? product.category.name : ""
            }`}
          />
          <div
            style={{
              marginTop: 30,
              padding: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              style={{ width: 150, marginBottom: 10 }}
              onClick={() => removeFromAll()}
              type="primary"
              danger
            >
              <FormattedMessage id="remove_from_all" />
            </Button>
            <Button
              style={{ width: 150 }}
              onClick={() => addToAll()}
              type="primary"
            >
              <FormattedMessage id="add_to_all" />
            </Button>
            {hasPermission("product_archive") && (
              <Button
                onClick={showBlockPopup}
                style={{ marginTop: 10, width: 150 }}
                type={archived ? "primary" : "danger"}
              >
                {archived ? (
                  <FormattedMessage id="restore" />
                ) : (
                  <FormattedMessage id="archive" />
                )}
              </Button>
            )}
            {hasPermission("product_actual_price_edit") && (
              <ProductGroupPrice
                style={{ marginTop: 10, width: 150 }}
                product={product._id}
              />
            )}
          </div>
        </>
      </Skeleton>
    </Card>
  )
}
