import {
  BarChartOutlined,
  CompassOutlined,
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { hasPermission } from '../api/user'
import './app.module.scss'
import { useSettings } from './helper/context/SettingsContext'
import { useQuery } from './helper/Hooks'

const { Sider } = Layout
const { SubMenu } = Menu

const KEY_OPEN_MENUS = 'key_open_menus'

export default function SideBar () {
  const [selectedPath, setSelectedPath] = useState('')
  const [openMenus, setOpenMenus] = useState([])
  const query = useQuery('role')
  const {
    showPostmanDashboard,
    showClientProducts,
    postmanDelivery
  } = useSettings()

  useEffect(() => {
    const lsOpenMenus = JSON.parse(localStorage.getItem(KEY_OPEN_MENUS))
    setOpenMenus(
      lsOpenMenus && lsOpenMenus.length !== 0
        ? lsOpenMenus
        : ['dashboard', 'users']
    )
  }, [])

  useEffect(() => {
    localStorage.setItem(KEY_OPEN_MENUS, JSON.stringify(openMenus))
  }, [openMenus])

  const isActive = (_, location) => {
    if (location) {
      if (query) {
        setSelectedPath(query)
      } else {
        setSelectedPath(location.pathname.replace('/', ''))
      }
    }
    return location
  }

  return (
    <Sider
      theme='dark'
      collapsible={true}
      breakpoint={'lg'}
      style={{
        height: '100vh',
        position: 'sticky',
        top: 0,
        left: 0
      }}
    >
      <Menu
        theme='dark'
        mode='inline'
        selectedKeys={[selectedPath]}
        openKeys={openMenus}
        onOpenChange={openKeys => {
          setOpenMenus(openKeys)
        }}
      >
        {hasPermission('statistics') && (
          <SubMenu
            key='dashboard'
            icon={<BarChartOutlined />}
            title={<FormattedMessage id='dashboard' />}
          >
            <Menu.Item key='dashboard-core'>
              <span>
                <FormattedMessage id='core' />
              </span>
              <NavLink to='/dashboard-core' isActive={isActive} />
            </Menu.Item>
            <Menu.Item key='dashboard-clients'>
              <span>
                <FormattedMessage id='clients' />
              </span>
              <NavLink to='/dashboard-clients' isActive={isActive} />
            </Menu.Item>
            <Menu.Item key='dashboard-groups'>
              <span>
                <FormattedMessage id='groups' />
              </span>
              <NavLink to='/dashboard-groups' isActive={isActive} />
            </Menu.Item>
            <Menu.Item key='dashboard-managers'>
              <span>
                <FormattedMessage id='managers' />
              </span>
              <NavLink to='/dashboard-managers' isActive={isActive} />
            </Menu.Item>
            <Menu.Item key='dashboard-categories'>
              <span>
                <FormattedMessage id='categories' />
              </span>
              <NavLink to='/dashboard-categories' isActive={isActive} />
            </Menu.Item>
            <Menu.Item key='dashboard-manager-products'>
              <span>
                <FormattedMessage id='manager_products' />
              </span>
              <NavLink to='/dashboard-manager-products' isActive={isActive} />
            </Menu.Item>
            {showPostmanDashboard && (
              <Menu.Item key='dashboard-postmen'>
                <span>
                  <FormattedMessage id='postmen' />
                </span>
                <NavLink to='/dashboard-postmen' isActive={isActive} />
              </Menu.Item>
            )}
            {showClientProducts && (
              <Menu.Item key='dashboard-client-products'>
                <span>
                  <FormattedMessage
                    id='client_products'
                    defaultMessage={'Client Products'}
                  />
                </span>
                <NavLink to='/dashboard-client-products' isActive={isActive} />
              </Menu.Item>
            )}
          </SubMenu>
        )}
        <SubMenu
          key='users'
          icon={<UserOutlined />}
          title={<FormattedMessage id='users_and_groups' />}
        >
          <Menu.Item key='client'>
            <span>
              <FormattedMessage id='clients' defaultMessage='Clients' />
            </span>
            <NavLink to='/users?role=client' isActive={isActive} />
          </Menu.Item>
          <Menu.Item key='manager'>
            <span>
              <FormattedMessage id='managers' defaultMessage='Managers' />
            </span>
            <NavLink to='/users?role=manager' isActive={isActive} />
          </Menu.Item>
          <Menu.Item key='admin'>
            <span>
              <FormattedMessage id='admins' defaultMessage='Admins' />
            </span>
            <NavLink to='/users?role=admin' isActive={isActive} />
          </Menu.Item>
          <Menu.Item key='postman'>
            <span>
              <FormattedMessage id='postmen' defaultMessage='Postmen' />
            </span>
            <NavLink to='/users?role=postman' isActive={isActive} />
          </Menu.Item>
          <Menu.Item key='groups'>
            <span>
              <FormattedMessage id='groups' />
            </span>
            <NavLink to='/groups' isActive={isActive} />
          </Menu.Item>
        </SubMenu>
        {postmanDelivery && (
          <SubMenu
            key='orders'
            icon={<ShoppingCartOutlined />}
            title={<FormattedMessage id='orders' />}
          >
            <Menu.Item key='orders/pending'>
              <span>
                <FormattedMessage id='pending' />
              </span>
              <NavLink to='/orders/pending' isActive={isActive} />
            </Menu.Item>
            <Menu.Item key='orders/in-progress'>
              <span>
                <FormattedMessage id='in_progress' />
              </span>
              <NavLink to='/orders/in-progress' isActive={isActive} />
            </Menu.Item>
            <Menu.Item key='orders/delivered'>
              <span>
                <FormattedMessage id='delivered' />
              </span>
              <NavLink to='/orders/delivered' isActive={isActive} />
            </Menu.Item>
            <Menu.Item key='/orders/risky'>
              <span>
                <FormattedMessage id='risky_orders' />
              </span>
              <NavLink to='/orders/risky' isActive={isActive} />
            </Menu.Item>
          </SubMenu>
        )}
        {(hasPermission('map_manager_traker') ||
          hasPermission('map_managers_location')) && (
          <SubMenu
            key='map'
            icon={<CompassOutlined />}
            title={<FormattedMessage id='map' />}
          >
            {/*<Menu.Item key='view/map/managers'>*/}
            {/*  <span>Manager Map</span>*/}
            {/*  <NavLink to='/view/map/managers' isActive={isActive} />*/}
            {/*</Menu.Item>*/}
            {hasPermission('map_managers_location') && (
              <Menu.Item key='view/map/managers-locations'>
                <span>
                  <FormattedMessage id='manager_location' />
                </span>
                <NavLink
                  to='/view/map/managers-locations'
                  isActive={isActive}
                />
              </Menu.Item>
            )}
            {hasPermission('map_manager_traker') && (
              <Menu.Item key='view/map/manager-tracker'>
                <span>
                  <FormattedMessage id='manager_tracker' />
                </span>
                <NavLink to='/view/map/manager-tracker' isActive={isActive} />
              </Menu.Item>
            )}
            <Menu.Item key='view/map/clients'>
              <span>
                <FormattedMessage id='client_map' />
              </span>
              <NavLink to='/view/map/clients' isActive={isActive} />
            </Menu.Item>
            <Menu.Item key='view/map/shop-finder'>
              <span>
                <FormattedMessage id='shop_finder' />
              </span>
              <NavLink to='/view/map/shop-finder' isActive={isActive} />
            </Menu.Item>
          </SubMenu>
        )}
        <Menu.Item key='products' icon={<ShoppingOutlined />}>
          <span>
            <FormattedMessage id='products' />
          </span>
          <NavLink to='/products' isActive={isActive} />
        </Menu.Item>
        {hasPermission('expense_page_view') && (
          <Menu.Item key='expenses' icon={<DollarCircleOutlined />}>
            <span>
              <FormattedMessage id='expenses' />
            </span>
            <NavLink to='/expenses' isActive={isActive} />
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  )
}
