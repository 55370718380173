import React, { useState } from 'react'
import { Button, Col, Form, InputNumber, message, Modal, Row } from 'antd'
import Api from '../../../api'
import { hasPermission } from '../../../api/user'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEffect } from 'react'

const { confirm } = Modal

function CoefficientItem (props) {
  const product = props.product
  const [isChanged, setChanged] = useState(false)
  const [updating, setUpdating] = useState(false)
  const language = useIntl()

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      price: product.user_price,
      percentage: getPercentage(product.user_price, product.price)
    })
  }, [props.product])

  const onRemove = () => {
    confirm({
      title: language.formatMessage({ id: 'remove_product_for_user' }),
      okText: language.formatMessage({ id: 'yes' }),
      okType: 'danger',
      cancelText: language.formatMessage({ id: 'no' }),
      onOk: () => {
        removeUserProduct()
      }
    })
  }
  const removeUserProduct = async () => {
    try {
      const data = { product: product._id, user: props.userId }
      await Api.post(`/product/user/remove`, data)
      props.onRemove()
    } catch (error) {
      message.error(error.response.data.message)
    }
  }

  const onPercetangeChange = percentage => {
    form.setFieldsValue({
      price: ((product.price * percentage) / 100 + product.price).toFixed(0)
    })
    setChanged(true)
  }

  const onCoeffChange = currentPrice => {
    form.setFieldsValue({
      percentage: getPercentage(currentPrice, product.price)
    })
    setChanged(true)
  }

  const updateCoefficient = async data => {
    setUpdating(true)
    try {
      data.user_id = props.userId
      data.product = product._id
      data.price = parseInt(data.price)
      await Api.post('product/coefficient', data)
    } catch (e) {
      message.error(e.response.data.message)
      form.setFieldsValue({
        price: product.user_price,
        percentage: getPercentage(product.user_price, product.price)
      })
    } finally {
      setUpdating(false)
    }
  }

  const handleSubmit = values => {
    setChanged(false)
    const coefficient = values.price
    if (coefficient > 0) {
      updateCoefficient(values)
    }
  }
  const disabled = !hasPermission('client_product_edit')
  const canSeeActualPrice =
    hasPermission('product_actual_price_edit') ||
    hasPermission('product_actual_price_view')

  const getPercentage = (userPrice, actualPrice) => {
    if (userPrice === actualPrice) {
      return 0
    } else if (actualPrice === 0) {
      return 'infinite'
    } else {
      return (((userPrice - actualPrice) * 100) / actualPrice).toFixed(0)
    }
  }

  return (
    <Form
      form={form}
      name={product._id}
      onFinish={handleSubmit}
      style={{ width: 320 }}
      initialValues={{
        price: product.user_price,
        percentage: getPercentage(product.user_price, product.price)
      }}
    >
      {hasPermission('client_product_price_change') && (
        <Row>
          <Col span={10}>
            <Form.Item
              label={language.formatMessage({ id: 'price' })}
              name='price'
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    if (value < 0) {
                      return Promise.reject(
                        language.formatMessage({ id: 'price_rule' })
                      )
                    }
                    return Promise.resolve()
                  },
                  message: language.formatMessage({ id: 'price_rule' })
                }
              ]}
            >
              <InputNumber
                disabled={disabled}
                onChange={onCoeffChange}
                precision={0}
                step={1000}
              />
            </Form.Item>
          </Col>
          {canSeeActualPrice && (
            <Col span={12}>
              <Form.Item
                label={language.formatMessage({ id: 'percentage' })}
                name='percentage'
              >
                <InputNumber
                  disabled={disabled}
                  formatter={value => `${value}%`}
                  precision={0}
                  step={5}
                  onChange={onPercetangeChange}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Form.Item>
            {hasPermission('client_product_price_change') && (
              <Button
                disabled={disabled}
                loading={updating}
                type={isChanged ? 'primary' : 'ghost'}
                htmlType='submit'
              >
                <FormattedMessage id='update' />
              </Button>
            )}
            <Button
              disabled={disabled}
              style={{ marginLeft: 10 }}
              type='danger'
              onClick={onRemove}
            >
              <FormattedMessage id='remove' />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default CoefficientItem
