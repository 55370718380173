import React, { useEffect, useState } from "react"
import { useLoading, useQuery } from "../../helper/Hooks"
import moment from "moment"
import { Row, Col, Select } from "antd"
import API from "../../../api"
import SBContent from "../../helper/SidebarContent"
import ProductChart from "../../statistics/ProductChart"
import ProductTransactionTable from "../../statistics/tables/ProductTransactionTable"
import styles from "./dashboardClientProducts.module.scss"
import SearchDropdown from "../../helper/searchDropdown/SearchDropdown"
import DateRangeFilter from "../../helper/DateRangeFilter"

const { Option } = Select

export default function DashboardClientProducts() {
  const [dateRange, setDateRange] = useState({
    start: "",
    end: "",
  })

  const [categories, setCategories] = useState([])
  const { loading: categoriesLoading, startLoading: startCategoryLoading } =
    useLoading()
  const [products, setProducts] = useState([])
  const { loading: productsLoading, startLoading: startProductLoading } =
    useLoading()

  const [filter, setFilter] = useState({
    clientId: "",
    categoryId: "",
    productId: undefined,
  })

  const [data, setData] = useState()
  const { loading: dataLoading, startLoading: startDataLoading } = useLoading()

  useEffect(() => {
    setDateRange({
      start: moment().startOf("month").valueOf(),
      end: moment().valueOf(),
    })

    getCategories()
    getProducts()
  }, [])

  useEffect(() => {
    if (dateRange.start && dateRange.end) {
      getData()
    }
  }, [dateRange, filter.categoryId, filter.productId, filter.clientId])

  const getCategories = () => {
    startCategoryLoading(async () => {
      const response = await API.get("/category")
      setCategories(response.data.result.data)
    })
  }

  const getProducts = (categoryId) => {
    startProductLoading(async () => {
      const res = await API.get(
        `/product/filter?category=${categoryId ? categoryId : ""}`
      )
      setProducts(res.data.result.products)
    })
  }

  const getData = () => {
    startDataLoading(async () => {
      const response = await API.get(
        `/statistics/profitwithTransactions/product?start=${
          dateRange.start
        }&end=${dateRange.end}&category=${
          filter.categoryId ? filter.categoryId : ""
        }&product=${filter.productId ? filter.productId : ""}&client=${
          filter.clientId ? filter.clientId : ""
        }`
      )
      setData(response.data.result)
    })
  }

  return (
    <SBContent>
      <div className={styles.filterContainer}>
        <DateRangeFilter
          className={styles.dateFilter}
          onChange={setDateRange}
          defaultValue={moment().format("MMMM")}
          intervals={[6, 12]}
        />
        <SearchDropdown
          placeholder="Select Client"
          url="/user/v2?roles=client&blocked=false"
          searchUrl="/user/v2?roles=client&blocked=false&name="
          onSelect={(selectedValue) =>
            setFilter({ ...filter, clientId: selectedValue })
          }
          renderItem={(item) => ({
            key: item._id,
            value: item._id,
            name: item.fullname,
          })}
          className={styles.filter}
        />
        <Select
          loading={categoriesLoading}
          allowClear={true}
          className={styles.filter}
          placeholder="Select Category"
          showSearch
          onChange={(e) => {
            setFilter({ ...filter, productId: undefined, categoryId: e })
            getProducts(e)
          }}
          optionFilterProp="children"
          filterOption={(input, option) => {
            if (!option.children) {
              return true
            }
            return option.children.toLowerCase().includes(input.toLowerCase())
          }}
        >
          {categories.map((category) => (
            <Option key={category._id} value={category._id}>
              {category.name}
            </Option>
          ))}
        </Select>
        <Select
          loading={productsLoading}
          allowClear={true}
          value={filter.productId}
          className={styles.productFilter}
          placeholder="Select product"
          showSearch
          onChange={(e) => {
            setFilter({ ...filter, productId: e })
          }}
          optionFilterProp="children"
          filterOption={(input, option) => {
            if (!option.children) {
              return true
            }
            return option.children.toLowerCase().includes(input.toLowerCase())
          }}
        >
          {products.map((product) => (
            <Option key={product._id} value={product._id}>
              {`(${product.product_id}) ${product.description}`}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.dataContainer}>
        <ProductTransactionTable dataSet={data} loading={dataLoading} />
        <ProductChart dataSet={data} loading={dataLoading} />
      </div>
    </SBContent>
  )
}
