import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { isManager } from "../../../../../api/user"
import API from "../../../../../api"
import { Input, InputNumber, message } from "antd"
import { useSettings } from "../../../../helper/context/SettingsContext"
import ConfirmButton from "../../../../helper/ConfirmButton"
import styles from "./index.module.scss"

const PercentageRange = ({ user }) => {
  const lang = useIntl()
  const settings = useSettings()
  const [percentageRange, setRange] = useState({})

  useEffect(() => {
    getPercentangeSettings()
  }, [])

  const getPercentangeSettings = async () => {
    const result = await API.get(
      `settings/manager/${user._id}/profit-percentage`
    )
    const profitPercentage = result.data.result
    if (profitPercentage) {
      const percentage = profitPercentage.profitPercentage
      setRange({ min: percentage.minPercentage, max: percentage.maxPercentage })
    } else {
      setRange({ min: 0, max: 100 })
    }
  }

  const updatePercenrtageSettings = async () => {
    if (percentageRange.min >= percentageRange.max) {
      message.error("Min cannot be equal or greater than max")
      return
    }
    await API.put(
      `settings/manager/${user._id}/profit-percentage`,
      percentageRange
    )
  }

  return (
    <div>
      {isManager(user) && settings.calculateClientRank && (
        <div className={styles.container}>
          <span>
            <FormattedMessage id="percentage_range" />
          </span>
          <div className={styles.inputContainer}>
            <div className={styles.input}>
              <InputNumber
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
                value={percentageRange.min}
                onChange={(value) => {
                  if (!isNaN(value)) {
                    setRange({ ...percentageRange, min: value })
                  }
                }}
              />
            </div>
            <div className={styles.inputSpliter}>
              <Input placeholder="-" disabled />
            </div>
            <div className={styles.input}>
              <InputNumber
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
                value={percentageRange.max}
                onChange={(value) => {
                  if (!isNaN(value)) {
                    setRange({ ...percentageRange, max: value })
                  }
                }}
              />
            </div>
            <ConfirmButton
              type="primary"
              onClick={() => {
                updatePercenrtageSettings()
              }}
              confirmTitle={lang.formatMessage({
                id: "percentage_range_update",
              })}
              confirmContent={lang.formatMessage({
                id: "percentage_range_update_content",
              })}
              className={styles.confirmButton}
            >
              <span style={{ textTransform: "capitalize" }}>
                <FormattedMessage id="update" />
              </span>
            </ConfirmButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default PercentageRange
