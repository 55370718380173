import { Col, Empty, Row } from 'antd'
import React from 'react'
import { useBundle } from '../../../provider/MakeOrderProductsProvider'
import BundleTotals from './BundleTotals'
import ProductItem from './ProductItem'
import {useIntl} from "react-intl";

export default function OrderBundle({ balance }) {
  const { bundle, bundleTotal, updateBundle } = useBundle()
  const products = Object.values(bundle)
  const lang = useIntl()

  return (
    <Row style={{ marginTop: 20 }}>
      <Col span={24}>
        <div style={{ fontWeight: 500, fontSize: 16 }}>{lang.formatMessage({id: "bundle"})}</div>
        <hr style={{ marginBottom: 10 }} />
        {products.length > 0 ? (
          <div
            style={{ marginBottom: 20 }}
          >
            {products.map((product) => {
              return (
                <ProductItem
                  product={product}
                  onBundleChange={updateBundle}
                  key={product._id}
                />
              )
            })}
          </div>
        ) : (
          <Empty description={lang.formatMessage({id: "no_products"})} />
        )}

        <BundleTotals balance={balance} bundleTotal={bundleTotal} />
      </Col>
    </Row>
  )
}
