import React, {useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import {Spin} from 'antd'
import {useIntl} from 'react-intl'
import API from '../../api'
import TotalCard from './TotalCard'
import {useLoading} from '../helper/Hooks'
import styles from './statistics.module.css'
import {formatNumber} from "../helper/Integer/IntegerFormatter";

export default function GroupProfitTransactionChart({
  dateRange,
  filter: { managerId, categoryId, productId },
}) {
  const lang = useIntl()
  const [data, setData] = useState({
    profit: { data: [], total: 0 },
    transaction: { data: [], total: 0 },
    groups: [],
    balance: { currency: '', currency_text: '' },
  })

  const { loading, startLoading } = useLoading()

  useEffect(() => {
    getData()
  }, [dateRange, managerId, categoryId, productId])

  const getData = async () => {
    startLoading(async () => {
      if (!managerId) {
        setData({
          profit: { data: [], total: 0 },
          transaction: { data: [], total: 0 },
          groups: [],
          balance: { currency: '', currency_text: '' },
        })
        return
      }
      const response = await API.get(
        `/statistics/profitwithTransactions/manager/groups?start=${
          dateRange.start
        }&end=${dateRange.end}&manager=${managerId ? managerId : ''}&category=${
          categoryId ? categoryId : ''
        }&product=${productId ? productId : ''}`
      )

      const {
        balance,
        chartData: { items, totalProfit, totalTransactions },
      } = response.data.result
      const { groups, profit, transaction } = items.reduce(
        (data, el) => {
          data.profit.push(el.profit)
          data.transaction.push(el.transaction)
          data.groups.push(el.group.name)
          return data
        },
        { groups: [], profit: [], transaction: [] }
      )

      setData({
        profit: { data: profit, total: totalProfit },
        transaction: { data: transaction, total: totalTransactions },
        groups: groups,
        balance: balance,
      })
    })
  }

  const dataSeries =
    !data.profit.data.length && !data.transaction.data.length
      ? []
      : [
          {
            name: lang.formatMessage({id: 'profits'}),
            data: data.profit.data,
          },
          {
            name: lang.formatMessage({id: 'transactions'}),
            data: data.transaction.data,
          },
        ]

  const options = {
    chart: {
      type: 'bar',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    title: {
      text: lang.formatMessage({id: 'client_profit_transactions'}),
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: data.groups,
    },
    legend: {
      position: 'top',
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return formatNumber(value, '֏')
        },
      },
    },
    noData: {
      text: 'No Data Available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
    },
  }

  return (
    <Spin spinning={loading}>
      <TotalCard
        totalProfit={data.profit.total}
        totalTransactions={data.transaction.total}
        balance={data.balance}
        className={styles.totalsCard}
      />
      <ReactApexChart
        className={styles.statisticsCharts}
        options={options}
        series={dataSeries}
        type='bar'
        height={450}
      />
    </Spin>
  )
}
