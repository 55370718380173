import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

let id = 0

function DynamicFieldsEdit ({ userInfo: initialInfo, styles }) {
  const userInfo = initialInfo ? initialInfo : []
  const language = useIntl()

  return (
    <Form.List name='user_info'>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <>
                <div>
                  <Form.Item
                    name={[index, 'name']}
                    label={language.formatMessage({ id: 'key' })}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: language.formatMessage({ id: 'fill_key' })
                      }
                    ]}
                    style={{ width: '90%' }}
                  >
                    <Input />
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Form.Item
                      name={[index, 'value']}
                      validateTrigger={['onChange', 'onBlur']}
                      label={language.formatMessage({ id: 'value' })}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: language.formatMessage({ id: 'fill_value' })
                        }
                      ]}
                      style={{ width: '90%' }}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item>
                      <MinusCircleOutlined
                        className='dynamic-delete-button'
                        onClick={() => {
                          remove(field.name)
                        }}
                        style={{ fontSize: '150%' }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </>
            ))}
            <Form.Item>
              <Button
                type='dashed'
                onClick={() => {
                  add()
                }}
              >
                <PlusOutlined />
                <FormattedMessage id='add_field' />
              </Button>
            </Form.Item>
          </div>
        )
      }}
    </Form.List>
  )
}

export default DynamicFieldsEdit
