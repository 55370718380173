import React, { useEffect, useState } from 'react'
import {
  Card,
  Skeleton,
  Avatar,
  Input,
  Button,
  message,
  Modal,
  Checkbox,
  Form,
} from 'antd'
import API from '../../../api'
import OrderBundle from './OrderBundle'
import { EditFilled } from '@ant-design/icons'
import { Link, Redirect } from 'react-router-dom'
import { useBundle } from '../../../provider/MakeOrderProductsProvider'
import { useFinishOrder } from './MakeOrderUseCase'
import styles from './makeOrder.module.scss'
import settings from '../../../settings'
import { formatNumber } from '../../helper/Integer/IntegerFormatter'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSettings } from '../../helper/context/SettingsContext'

const { Meta } = Card
const { TextArea } = Input
const { confirm } = Modal

export default function OrderInfo({ userId }) {
  const [user, setUser] = useState({ role: {}, balance: {}, location: {} })
  const [assignForm] = Form.useForm()
  const [loading, setLoading] = useState(true)
  const { bundle } = useBundle()
  const {
    finishOrder,
    finishLoading,
    postmanOrder,
    postmanLoading,
    data,
    error,
    orderFinished,
  } = useFinishOrder()
  const lang = useIntl()
  const { postmanDelivery } = useSettings()

  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    if (orderFinished === true) {
      message.success(lang.formatMessage({ id: 'order_success' }))
    }
  }, [orderFinished])

  const getUser = async () => {
    const response = await API.get(`/user/withPerms/${userId}`)
    setUser(response.data.result)
    setLoading(false)
  }

  const canCreateOrder = () => {
    return Object.values(bundle).length > 0
  }

  if (data && !finishLoading) {
    return <Redirect to={{ pathname: '/users' }} />
  }

  if (error && !finishLoading) {
    message.error(error.message)
  }

  const onApprove = () => {
    confirm({
      title: lang.formatMessage({ id: 'confirmation' }),
      content: (
        <div className={styles.approvePopupContainer}>
          <span>Please approve the order for assigning to postman</span>
          <Form form={assignForm} name='assignForm'>
            <Form.Item
              name='invoice'
              label={lang.formatMessage({ id: 'invoice' })}
              valuePropName='checked'
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              name='comment'
              label={lang.formatMessage({ id: 'comment' })}
              className={styles.popupTextArea}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Form>
        </div>
      ),
      async onOk() {
        assignForm.validateFields().then((values) => {
          postmanOrder(userId, values.invoice, values.comment)
          assignForm.resetFields()
        })
      },
    })
  }

  const getActions = () => {
    let actions = []
    actions.push(
      <Button
        loading={finishLoading}
        disabled={!canCreateOrder()}
        type='primary'
        onClick={() => {
          finishOrder(userId)
        }}
      >
        <FormattedMessage id='create_order' />
      </Button>
    )
    if (postmanDelivery) {
      actions.push(
        <Button
          loading={postmanLoading}
          disabled={!canCreateOrder()}
          type='primary'
          onClick={() => {
            onApprove()
          }}
        >
          <FormattedMessage id='postman_order' />
        </Button>
      )
    }
    return actions
  }

  return (
    <Card title={user.fullname} actions={getActions()}>
      <Skeleton loading={loading} avatar active>
        <Meta
          avatar={
            <Avatar
              src={
                user.image
                  ? user.image
                  : 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
              }
            />
          }
          title={
            <Input
              size='small'
              disabled={true}
              value={`${formatNumber(
                user.balance.total,
                user.balance.currency_text,
                lang.formatMessage({ id: 'balance' }),
                -1
              )}`}
            />
          }
        />
        <OrderBundle balance={user.balance} />
      </Skeleton>
    </Card>
  )
}
