import axios from 'axios'

const host = window.location.host

let [subDomain] = host.split('.')

if (process.env.REACT_APP_ENV === 'dev') {
  subDomain = 'localDev'
} else if (process.env.REACT_APP_ENV === 'local') {
  subDomain = 'local'
}

const baseUrlV2 = {
  local: 'http://127.0.0.1:8081/',
  demo: 'https://demo-api.mad-systems.net/api',
  dev: 'https://mad-back-dev.herokuapp.com/api',
  localDev: 'https://mad-back-dev.herokuapp.com/api',
  gogreen: 'https://go-green-api.mad-systems.net',
  'go-green': 'https://go-green-api.mad-systems.net/gogreen',
}[subDomain]

// const baseUrl = {
//   local: 'http://127.0.0.1:8088/api',
//   dev: 'https://mad-back-dev.herokuapp.com/api',
//   demo: 'https://mad-back-demo.herokuapp.com/api',
//   live: 'https://mad-back.herokuapp.com/api',
//   yummy: 'https://mad-back-yummy.herokuapp.com/api',
// }[process.env.REACT_APP_ENV]

const API = axios.create({
  baseURL: baseUrlV2,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
})

let authInterceptor = 0

function registerAuthInterceptor(onError) {
  removeAuthInterceptor()
  authInterceptor = API.interceptors.response.use(
    (response) => response,
    (error) => {
      onError(error)
      return Promise.reject(error)
    }
  )
}

function removeAuthInterceptor() {
  API.interceptors.response.eject(authInterceptor)
}

function setClientToken(token) {
  API.defaults.headers.common['Authorization'] = token
}

export default API
export { registerAuthInterceptor, removeAuthInterceptor, setClientToken }
