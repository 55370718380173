import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import { useSettings } from '../../helper/context/SettingsContext'
import { IntTextFormatter } from '../../helper/Integer/IntegerFormatter'
import styles from '../statistics.module.css'

export default function ManagerTransactionTable ({ dataSet, loading, date }) {
  const lang = useIntl()
  const [data, setData] = useState({
    items: [],
    balance: { currency: '', currency_text: '' },
    selectedManager: ''
  })
  const [columns, setColumns] = useState([])
  const { returnEnabled } = useSettings()

  const history = useHistory()

  const parseData = () => {
    const { balance, items } = dataSet
    const { graphItems } = items.reduce(
      (data, el) => {
        const dataItem = {
          manager: el.manager,
          profit: el.profit,
          transaction: el.transaction,
          profitPercentage: el.profitPercentage,
          expense: el.expense ? el.expense : 0
        }
        if (returnEnabled) {
          data.graphItems.push({
            ...dataItem,
            returnTransaction: el.returnTransaction
          })
        } else {
          data.graphItems.push(dataItem)
        }
        return data
      },
      { graphItems: [] }
    )
    setData({ ...data, items: graphItems, balance: balance })
    setupTableColumns(balance.currency_text)
  }

  const setupTableColumns = currency => {
    const columns = [
      {
        title: lang.formatMessage({ id: 'manager' }),
        dataIndex: 'manager',
        render: manager =>
          manager ? (
            <Link
              onClick={e => e.stopPropagation()}
              to={{
                pathname: `/view/user/${manager._id}`
              }}
            >
              {manager.fullname}
            </Link>
          ) : (
            ''
          ),
        width: 200,
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.manager.fullname.localeCompare(b.manager.fullname),
        sortDirections: ['descend', 'ascend']
      },
      {
        title: lang.formatMessage({ id: 'transaction' }),
        dataIndex: 'transaction',
        render: transaction => (
          <IntTextFormatter currencyText={currency} number={transaction} />
        ),
        sorter: (a, b) => a.transaction - b.transaction,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: lang.formatMessage({ id: 'profit' }),
        dataIndex: 'profit',
        render: profit => (
          <IntTextFormatter currencyText={currency} number={profit} />
        ),
        sorter: (a, b) => a.profit - b.profit,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: lang.formatMessage({ id: 'profit_percentage' }),
        dataIndex: 'profitPercentage',
        render: profitPercentage =>
          `${profitPercentage ? profitPercentage + '%' : '-'}`,
        sorter: (a, b) => {
          if (!a.profitPercentage) {
            return -1
          } else if (!b.profitPercentage) {
            return 1
          }
          return a.profitPercentage - b.profitPercentage
        },
        sortDirections: ['descend', 'ascend']
      },
    ]
    if (returnEnabled) {
      columns.push({
        title: lang.formatMessage({ id: 'return_transaction' }),
        dataIndex: 'returnTransaction',
        render: returnTransaction => (
          <IntTextFormatter
            currencyText={currency}
            number={returnTransaction ?? 0}
          />
        ),
        sorter: (a, b) => a.returnTransaction - b.expense,
        sortDirections: ['descend', 'ascend']
      })
    }
    columns.push({
      title: () => lang.formatMessage({id: 'expenses'}),
      dataIndex: 'expense',
      render: expense => (
        <IntTextFormatter currencyText={currency} number={expense}/>
      ),
      sorter: (a, b) => a.expense - b.expense,
      sortDirections: ['descend', 'ascend']
    })
    setColumns(columns)
  }

  useEffect(() => {
    if (dataSet) {
      parseData()
    }
  }, [dataSet])

  return columns.length > 0 ? (
    <Table
      title={() =>
        lang.formatMessage({ id: 'profit_calculation_expense_excluded' })
      }
      showSorterTooltip={false}
      loading={loading}
      size='small'
      bordered={true}
      className={styles.statisticsTables}
      columns={columns}
      rowKey={element => element.manager._id}
      onRow={element => {
        return {
          onClick: () => {
            history.push(
              `/dashboard-categories?manager=${element.manager._id}&month=${date}`
            )
          }
        }
      }}
      rowClassName={styles.rowHoverPointer}
      pagination={{
        defaultPageSize: 9,
        total: data.items.length,
        position: ['bottomCenter']
      }}
      dataSource={data.items}
    />
  ) : ''
}
