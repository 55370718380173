import Icon from "@ant-design/icons";
import { Button, Form, Input, message, Select, Spin, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import Api from "../../api";
import DynamicFieldsEdit from "../helper/DynamicFieldsEdit";
import SBContent from "../helper/SidebarContent/";
import styles from "./user.module.scss";

function UserEdit(props) {
  const userId = props.match.params.id;
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [image, setImage] = useState(undefined);
  const [form] = Form.useForm();
  const history = useHistory();
  const language = useIntl();

  useEffect(() => {
    getUser();
  }, []);

  const genereatedPassword = Math.random().toString(36).slice(-8);

  async function getUser() {
    const response = await Api.get(`/user/${userId}`);
    const user = response.data.result;
    setUser(user);
    const checkRole = (role) => role.name == user.role;
    form.setFieldsValue({
      name: user.name,
      surname: user.surname,
      username: user.username,
      address: user.location.address,
      phone_number: user.phone_number,
      hvhh: user.hvhh,
      user_info: user.user_info,
      role: user.role._id,
    });
    setLoading(false);
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    const data = values;
    if (data["new-password"]) {
      data.password = data["new-password"];
    }
    delete data["new-password"];
    data.updateRole = user.role.name;
    try {
      const response = await Api.put(`/user/${userId}`, data);
      const userData = response.data.result;
      if (userData) {
        await uploadImage();
      }
    } catch (ex) {
      if (ex.response) {
        message.error(ex.response.data.message);
        return;
      }
      message.error(ex.message);
    } finally {
      setLoading(false);
    }
    history.goBack();
  };

  async function uploadImage() {
    const formData = new FormData();
    if (image) {
      formData.append("photo", image);
      formData.append("user_id", user._id);
    }
    try {
      await Api.post("/user/uploadImage", formData);
    } catch (ex) {
      console.error(ex);
    }
  }

  const handleSelectChange = (value) => {};

  return (
    <SBContent title="Edit User">
      <Spin spinning={loading} tip="Loading...">
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <div className={styles.userEditFormContainer}>
            <div>
              <Upload
                type
                name="logo"
                listType="picture"
                accept="jpg"
                showUploadList={false}
                beforeUpload={(file) => {
                  setImage(file);
                  return false;
                }}
              >
                <Button className={styles.userEditUploadImage}>
                  <Icon type="upload" />
                  <FormattedMessage id="click_to_upload" />
                </Button>
              </Upload>
              <img
                style={{ marginLeft: 20 }}
                src={image ? URL.createObjectURL(image) : user.image}
                width="100"
              />
              <Form.Item
                label={language.formatMessage({ id: "name" })}
                name="name"
                rules={[
                  {
                    required: true,
                    message: language.formatMessage({
                      id: "fill_user_name",
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={language.formatMessage({ id: "surname" })}
                name="surname"
                rules={[
                  {
                    required: true,
                    message: language.formatMessage({
                      id: "fill_user_surname",
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={language.formatMessage({ id: "new_password" })}
                name="new-password"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) return Promise.resolve();
                      const isMatch = value.match(
                        /[^a-zA-Z0-9\\,\\.\\;\\:\\?\\>\\<\\/\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\-\\_\\+\\=\\|]/g
                      );
                      if (isMatch) {
                        return Promise.reject(
                          language.formatMessage({ id: "password_rule" })
                        );
                      }
                      return Promise.resolve();
                    },
                    message: language.formatMessage({
                      id: "password_rule",
                    }),
                  },
                ]}
              >
                <span className={styles.passwordForm}>
                  <Input.Password
                    autocomplete="new-password"
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                  />
                </span>
              </Form.Item>
              <Form.Item
                label={language.formatMessage({ id: "select_address" })}
                name="address"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="phone_number"
                label={language.formatMessage({ id: "phone_number" })}
              >
                <Input type="tel" />
              </Form.Item>
              <Form.Item
                label={language.formatMessage({ id: "hvhh" })}
                name="hvhh"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value) return Promise.resolve();
                      if (value.length == 0 || value.length == 8) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        language.formatMessage({ id: "hvhh_rule" })
                      );
                    },
                    message: language.formatMessage({ id: "hvhh_rule" }),
                  },
                ]}
              >
                <Input max={8} min={8} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  <FormattedMessage id="save" />
                </Button>
              </Form.Item>
            </div>
            <div style={{paddingTop: 52}}>
              <DynamicFieldsEdit />
            </div>
          </div>
        </Form>
      </Spin>
    </SBContent>
  );
}

export default UserEdit;
