import React, { useState, useRef } from 'react'
import { Input, Button, Modal, InputNumber, message } from 'antd'
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons'

const DynamicCounterChanger = ({
  count,
  onSubmit,
  disabled = true,
  info: { name, suffix = '' },
  width = '100%',
  onChange
}) => {
  const [loading, setLoading] = useState(false)
  const currentCount = useRef(count)
  const [total, setTotal] = useState(count)

  const handleSubmit = async () => {
    setLoading(true)
    const amount = total - currentCount.current
    try {
      await onSubmit(amount, total)
      currentCount.current = total
    } catch (err) {
      message.error(err.message)
    }
    setLoading(false)
  }

  //
  return (
    <Input
      disabled={disabled}
      style={{ width: width, textAlign: 'right' }}
      type='number'
      step={100}
      size='small'
      suffix={suffix}
      prefix={<b>{name}</b>}
      value={total}
      onChange={(e) => {
        const changeAmount = parseInt(e.target.value)

        setTotal(changeAmount)

        if(onChange) {
          onChange(changeAmount ? changeAmount : 0)
        }
        }}
      addonAfter={
        loading ? <LoadingOutlined /> : <CheckOutlined onClick={handleSubmit} />
      }
    />
  )
}

export default DynamicCounterChanger
