import React, { useState, useEffect } from 'react'
import UserContext from '../component/helper/context/UserContext'
import api, { removeAuthInterceptor, setClientToken } from '../api'

const withUser = (Component) => ({ ...props }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem('user'))
  )
  const [isLoading, setIsLoading] = useState(true)

  const setCredentials = (user, token) => {
    setClientToken(token)
    setCurrentUser(user)
  }

  const signIn = async (username, password) => {
    const response = await api.post('/auth/admin', {
      username: username,
      password: password,
    })
    const user = response.data.result

    localStorage.setItem('user', JSON.stringify(user))
    setCredentials(user, user.token)
  }

  const signOut = () => {
    removeAuthInterceptor()
    setCredentials()
    localStorage.removeItem('user')
    setIsLoading(false)
  }
  if (currentUser) {
    setClientToken(currentUser.token)
  }

  // useEffect(() => {
  //   setIsLoading(true)
  //   const user = JSON.parse(localStorage.getItem('user'))
  //   if (user) {
  //     setCredentials(user, user.token)
  //   }
  //   setIsLoading(false)
  // }, [])

  // if (isLoading) {
  //   return <div>...loading</div>
  // }

  return (
    <UserContext.Provider
      value={{ user: currentUser, signIn, signOut, isLoading }}
    >
      <Component {...props} />
    </UserContext.Provider>
  )
}

export default withUser
