import React from 'react'
import {Button, Divider, Card, message, Typography} from 'antd'
import styles from './info.module.scss'
import API from '../../../api'
import {FormattedMessage, useIntl} from 'react-intl'
import { getDate } from '../../helper/helper'
import {hasPermission, isManager, isUserClient} from '../../../api/user'
import {useState} from 'react'
import ConfirmButton from '../../helper/ConfirmButton'
import {formatNumber, IntTextFormatter} from "../../helper/Integer/IntegerFormatter";


export default function UserOrderHistoryItem({item, user, onClick, key}) {
  const [itemStatus, setItemStatus] = useState(item.status)
  const lang = useIntl()

  const archiveOrder = async () => {
    try {
      await API.delete(`order/${item._id}`)
      setItemStatus('ARCHIVED')
    } catch (err) {
      message.error(lang.formatMessage({id: 'order_archive_error'}))
    }
  }
  const hideArchiveBtn =
    (item.agent && itemStatus === 'FINISHED') ||
    itemStatus === 'ARCHIVED' || itemStatus === 'DELIVERED' || !hasPermission('admin_archive_finished_order')

  const showPaidAmount = item.status === 'ARCHIVED' && !!item.payed_amount

  return (
    <Card key={key} className={styles.orderItem}>
      <div className={styles.orderHeader}>
        <div>
          <FormattedMessage id='id' />: <b>{item.order_id}</b>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: "space-between"}}>
            {isUserClient(user) && item.ordered_user && (
              <Typography.Text>
                <FormattedMessage id='from'/>: {item.ordered_user.fullname}
              </Typography.Text>
            )}
            {isManager(user) && item.client && (
              <Typography.Text>
                <FormattedMessage id='to'/>: {item.client.fullname}
              </Typography.Text>
            )}
            {item.agent && (
              <Typography.Text>
                <FormattedMessage id='postman'/>: {item.agent.fullname}
              </Typography.Text>
            )}
          </div>
        </div>
        <div>
          <div className={styles.orderStatus}>
            <div>
              <FormattedMessage id='status'/>: <b>{itemStatus === "FINISHED" && item.order_type && item.order_type === "RETURN" ? "RETURN" : itemStatus}</b>
            </div>
            {item.status === 'FINISHED' && (
              <Typography.Text>
                <FormattedMessage id='paid_amount'/>: <span
                className={item.payed_amount < item.total_price ? styles.paidLess : styles.paidMore}>{formatNumber(
                item.payed_amount, '֏')}</span>
              </Typography.Text>
            )}
            { showPaidAmount && (
              <Typography.Text>
                <FormattedMessage id='paid_amount'/>: <IntTextFormatter number={item.payed_amount} currencyText={'֏'}/>
              </Typography.Text>
            )}
            <Typography.Text>
              <FormattedMessage id='total'/>: {formatNumber(item.total_price, '֏')}
            </Typography.Text>
            {item.order_type === 'INVOICE' && (
              <b style={{
                backgroundColor: '#d4edda',
                width: 'auto',
                borderRadius: 10,
                textAlign: 'center',
                marginTop: 5,
                marginBottom: 5
              }}>
                <FormattedMessage id='has_invoice'/>
              </b>)}
          </div>

        </div>
      </div>
      <Divider style={{
        width: '65%', minWidth: 0, marginLeft: 'auto', marginRight: 'auto', marginTop: 12, marginBottom: 12
      }}/>
      <div className={styles.orderRow}>
        <div>
          {(item.status === 'FINISHED' || item.status === 'DELIVERED') && (
            <Typography.Text>
              Finished Date: {getDate(item.finished_date, true)}
            </Typography.Text>
          )}
          <Typography.Text>
            <FormattedMessage id='order_date'/>: {getDate(item.ordered_date, true)}
          </Typography.Text>
        </div>
        <div className={styles.orderActionContainer}>
          <Button type='primary' onClick={() => onClick(item)}>
            <FormattedMessage id='more'/>
          </Button>
          {!hideArchiveBtn && (
            <ConfirmButton
              type='primary'
              confirmTitle={lang.formatMessage({id: 'archive_question'})}
              danger
              style={{marginLeft: 10}}
              onClick={archiveOrder}
            >
              <FormattedMessage id='archive'/>
            </ConfirmButton>
          )}
        </div>
      </div>
    </Card>
  )
}
