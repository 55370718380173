import { Modal, message, Form } from 'antd'
import { useState, useEffect } from 'react'
import API from '../../../../api'
import { useLoading } from '../../../helper/Hooks'

const { confirm, warning } = Modal

export const useUserClients = user => {
  const [selectedUser, setSelectedUser] = useState()
  const [managers, setManagers] = useState([])
  const [groups, setGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState()
  const [clients, setClients] = useState([])
  const [totals, setTotals] = useState({
    total_clients: 0,
    total_balance: ''
  })
  const [changeManagerVisibility, setChangeManagerVisibility] = useState()
  const [newManager, setNewManager] = useState()
  const [editGroupVisibility, setEditGroupVisibility] = useState(false)
  const [isGroupUpdating, setGroupUpdating] = useState(false)
  const { loading, startLoading } = useLoading()
  const {
    loading: managersLoading,
    startLoading: startManagersLoading
  } = useLoading()
  const [form] = Form.useForm()

  useEffect(() => {
    if (user) {
      setSelectedUser(user)
    } else {
      getManagers()
    }
  }, [])

  useEffect(() => {
    if (selectedUser) {
      getGroups()
    }
  }, [selectedUser])

  useEffect(() => {
    if (selectedGroup) {
      getClients(selectedGroup)
      form.setFieldsValue({ name: getGroupName(selectedGroup) })
    } else {
      setClients([])
      setTotals({
        total_clients: 0,
        total_balance: 0
      })
      if(selectedUser) {
        getGroups()
      }
    }
  }, [selectedGroup])

  const getManagers = () => {
    startManagersLoading(async () => {
      const response = await API.get(`user/v2?roles=manager&blocked=false`)
      setManagers(response.data.result)
    })
  }

  const getGroups = () => {
    startLoading(async () => {
      const response = await API.get(
        `/managerGroup/manager?manager=${selectedUser}`
      )
      setGroups(response.data.result)
      if (response.data.result[0]) {
        setSelectedGroup(response.data.result[0]._id)
      } else {
        setSelectedGroup(undefined)
      }
    })
  }

  const getClients = groupId => {
    startLoading(async () => {
      const response = await API.get(`/clientGroup/withCheckout/${groupId}?manager=${selectedUser}`)
      const result = response.data.result ? response.data.result.clients : []
      console.log(result)
      setClients(result)

      let totalBalance = 0

      result.forEach(({ balance }, index, array) => {
        totalBalance += balance.total
        if (index === array.length - 1) {
          totalBalance = -totalBalance + balance.currency_text
        }
      })

      setTotals({
        total_clients: result.length,
        total_balance: totalBalance
      })
    })
  }

  const updateGroup = async name => {
    setGroupUpdating(true)
    try {
      if (!name) {
        throw new Error('Must be a valid Name')
      }

      if (name === getGroupName(selectedGroup)) {
        throw new Error('Group name has not changed')
      }

      await API.put(`/clientGroup/${selectedGroup}`, { name })

      form.resetFields()
      setEditGroupVisibility(false)
      getGroups()
    } catch (error) {
      message.error(error.message)
    } finally {
      setGroupUpdating(false)
    }
  }

  const getGroupName = id => {
    const group = groups.find(group => {
      if (id === group._id) return true
    })

    return group ? group.name : ''
  }

  const removeGroup = () => {
    if (totals.total_clients > 0) {
      warning({
        title: 'Warning! Group not empty!',
        content:
          'Warning! The selected group still has clients. Please remove all clients before deleting a group.'
      })
    } else {
      confirm({
        title: 'Do you want to delete the selected group?',
        async onOk () {
          try {
            await API.delete(`managerGroup/${selectedGroup}`)
            getGroups()
          } catch (err) {
            message.error(err.message)
          }
        }
      })
    }
  }

  const changeManager = async () => {
    try {
      await API.post(
        `/managerGroup/change-manager`, 
        {
          group:selectedGroup,
          old_manager: selectedUser,
          new_manager: newManager
        }
      )
      setNewManager(undefined)
      setChangeManagerVisibility(false)
      setSelectedGroup(undefined)
    } catch (err) {
      message.error(err.message)
    }
  }

  return {
    groups,
    selectedGroup,
    setSelectedGroup,
    clients,
    totals,
    loading,
    editGroupVisibility,
    setEditGroupVisibility,
    editGroupForm: form,
    isGroupUpdating,
    updateGroup,
    removeGroup,
    getGroupName,
    managers,
    managersLoading,
    selectedUser,
    setSelectedUser,
    changeManagerVisibility,
    setChangeManagerVisibility,
    newManager,
    setNewManager,
    changeManager
  }
}