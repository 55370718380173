import React, { useEffect, useRef, useState } from "react"
import SContent from "../helper/SidebarContent/"
import { Avatar, Button, Input, List, Select, Tag } from "antd"
import styles from "./user.module.scss"
import { Link } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { hasPermission } from "../../api/user"
import { getDate } from "../helper/helper"
import InfiniteScrollWrapper from "../helper/InfiniteScroll"
import { useQuery } from "../helper/Hooks"
import { formatNumber } from "../helper/Integer/IntegerFormatter"
import { Lightbox } from "react-modal-image"

const { Option } = Select

function Users() {
  const language = useIntl()
  const role = useQuery("role")
  const selectedRole = role ? role : "client"
  const title =
    selectedRole === "postman"
      ? language.formatMessage({ id: "postmen" })
      : language.formatMessage({
          id:
            selectedRole.charAt(0).toUpperCase() + selectedRole.slice(1) + "s",
        })
  const [searchedName, setSearchedName] = useState("")
  const [searchedHvhh, setSearchedHvhh] = useState("")
  const [showBlocked, setShowBlocked] = useState(false)
  const searchCallback = useRef()
  const searchRef = useRef()
  const hvhhSearchRef = useRef()
  const [focusModalState, setFocusedModalState] = useState({
    state: false,
    imageUrl: undefined,
  })

  useEffect(() => {
    return () => {
      if (searchCallback.current) {
        clearTimeout(searchCallback.current)
      }
    }
  }, [])

  const getActions = (item) => {
    if (hasPermission("admin_make_order")) {
      return [
        <Link key="list-loadmore-view" to={`view/user/${item._id}`}>
          <FormattedMessage id="view" />
        </Link>,
        item.role.name === "client" && (
          <Link key="list-makeorder-view" to={`user/make-order/${item._id}`}>
            <FormattedMessage id="make_order" />
          </Link>
        ),
      ]
    } else {
      return [
        <Link key="list-loadmore-view" to={`view/user/${item._id}`}>
          <FormattedMessage id="view" />
        </Link>,
      ]
    }
  }

  return (
    <SContent title={title}>
      <div className={styles.userList}>
        <div className={styles.roleSearch}>
          <Button className={styles.debtBtn} type="primary">
            <Link to="debt/users">
              <FormattedMessage id="debt_users" />
            </Link>
          </Button>
          <Button className={styles.createBtn}>
            <Link to="/create/user">
              <FormattedMessage id="create_user" />
            </Link>
          </Button>
        </div>
        <div className={styles.search}>
          <Input
            ref={(e) => (searchRef.current = e)}
            placeholder={language.formatMessage({ id: "search_for_user" })}
            onChange={(e) => {
              if (searchCallback.current) {
                clearTimeout(searchCallback.current)
              }
              const name = e.target.value
              searchCallback.current = setTimeout(() => {
                setSearchedName(name)
              }, 400)
            }}
          />
        </div>
        <div className={styles.hvhhSearch}>
          <Input
            style={{ width: 160 }}
            ref={(e) => (hvhhSearchRef.current = e)}
            placeholder={language.formatMessage({
              id: "search_for_user_hvhh",
              defaultMessage: "Search by hvhh",
            })}
            onChange={(e) => {
              if (searchCallback.current) {
                clearTimeout(searchCallback.current)
              }
              const name = e.target.value
              searchCallback.current = setTimeout(() => {
                setSearchedHvhh(name)
              }, 400)
            }}
          />
          <Select
            onChange={(value) => {
              setShowBlocked(value)
            }}
            value={showBlocked}
            className={styles.blockFilter}
          >
            <Option value={""} key={"all"}>
              <FormattedMessage id="all" />
            </Option>
            <Option value={true} key={"blocked"}>
              <FormattedMessage id="blocked" />
            </Option>
            <Option value={false} key={"unblocked"}>
              <FormattedMessage id="unblocked" />
            </Option>
          </Select>
        </div>
        <div className={styles.search}></div>
        <div style={{ width: 500 }}>
          <InfiniteScrollWrapper
            url={`/user/v2?roles=${selectedRole}&name=${searchedName}&hvhh=${searchedHvhh}&blocked=${showBlocked}`}
            render={(item) => (
              <List.Item actions={getActions(item)}>
                <List.Item.Meta
                  key={item._id}
                  avatar={
                    <div
                      onClick={() => {
                        const src = item.image
                          ? item.image
                          : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"

                        setFocusedModalState({ state: true, imageUrl: src })
                      }}
                    >
                      <Avatar
                        src={
                          item.image
                            ? item.image
                            : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                        }
                      />
                    </div>
                  }
                  title={item.fullname}
                  description={
                    <>
                      {item.hvhh && <div>HVHH: {item.hvhh}</div>}
                      <div style={{ fontWeight: "bold", color: "#000" }}>
                        {formatNumber(item.balance.total, "֏", "", -1)}
                      </div>
                      {item.locationDate && (
                        <div>
                          <FormattedMessage id="last_update" />:{" "}
                          {getDate(item.locationDate, true)}
                        </div>
                      )}
                    </>
                  }
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: 50,
                  }}
                >
                  {item.newRegistered && (
                    <Tag
                      color="#87d068"
                      style={{ width: 120, textAlign: "center" }}
                    >
                      <FormattedMessage id="new_registered" />
                    </Tag>
                  )}
                  {item.hasProducts && (
                    <Tag color="#1991ff" style={{ width: 120 }}>
                      <FormattedMessage id="products_available" />
                    </Tag>
                  )}
                </div>
              </List.Item>
            )}
          />
          {focusModalState.state && (
            <Lightbox
              medium={focusModalState.imageUrl}
              hideDownload={true}
              hideZoom={true}
              onClose={() => {
                setFocusedModalState({
                  state: false,
                  imageUrl: undefined,
                })
              }}
            />
          )}
        </div>
      </div>
    </SContent>
  )
}

export default Users
