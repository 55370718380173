import { useFetcher, useLazyFetcher } from "../../helper/Fetcher"

export default function useGroups(url) {
  const { data, isLoading, refetch } = useLazyFetcher(url)

  return {
    products: !isLoading && data ? data.products : [],
    isProductsLoading: isLoading,
    getProducts: refetch,
  }
}
