import { Button, Form, message, Modal, Table } from 'antd'
import React, { Fragment, useEffect, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useReactToPrint } from 'react-to-print'
import API from '../../api'
import { hasPermission } from '../../api/user'
import OrderInfo from './orderInfo'
import styles from './order.module.scss'
import { IntTextFormatter } from '../helper/Integer/IntegerFormatter'
import moment from 'moment'

const { confirm } = Modal

export default function OrderEditPostmanPopup(props) {
  const order = props.order
  const onClose = props.onClose
  const [form] = Form.useForm()
  const [bonusForm] = Form.useForm()
  const language = useIntl()
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  useEffect(() => {
    if (props.order) {
      if (props.order.agent) {
        form.setFieldsValue({ agent_id: props.order.agent._id })
      }
      bonusForm.setFieldsValue({
        bonus: props.order.bonus ? props.order.bonus : 0,
      })
    }
  }, [props.order])

  const archiveOrder = async () => {
    confirm({
      title: language.formatMessage({ id: 'archive_question' }),
      onOk: async () => {
        try {
          await API.delete(`order/${order._id}`)
          onClose(true)
        } catch (err) {
          message.error(language.formatMessage({ id: 'order_archive_error' }))
        }
      },
    })
  }

  function showArchiveButton() {
    return (
      (order.status === 'PENDING' || order.status === 'PICKED') &&
      hasPermission('admin_archive_pending_progress_order')
    )
  }

  return (
    <Fragment>
      <OrderInfo {...props} />
      {showArchiveButton() && (
        <Button
          style={{ marginTop: 10, marginRight: 10 }}
          onClick={() => {
            archiveOrder()
          }}
          type="danger"
        >
          <FormattedMessage id="archive" />
        </Button>
      )}
      <Button
        style={{ marginTop: 10 }}
        onClick={() => {
          handlePrint()
        }}
        type="primary"
      >
        <FormattedMessage id="print" />
      </Button>
      <div className={styles.orderInfoContainer} ref={componentRef}>
        <div className={styles.headerContainer}>
          <div>
            {order.manager && (
              <div>
                <FormattedMessage id="manager" />: {order.manager.fullname}
              </div>
            )}
            {order.ordered_user && (
              <div>
                <FormattedMessage id="ordered_user" />:{' '}
                {order.ordered_user.fullname}
              </div>
            )}
            {order.agent && (
              <div>
                <FormattedMessage id="postman" />: {order.agent.fullname}
              </div>
            )}
          </div>
          <div>
            <FormattedMessage id="status" />:
            {order.status === 'FINISHED' &&
            order.order_type &&
            order.order_type === 'RETURN'
              ? 'RETURN'
              : order.status}
          </div>
        </div>
        <div className={styles.clientInfoContainer}>
          {order.client && (
            <div>
              <div>
                <FormattedMessage id="client" />: {order.client.fullname}
              </div>
              <div>
                <FormattedMessage id="hvhh" />: {order.client.hvhh}
              </div>
            </div>
          )}

          {order.client && order.client.location.address && (
            <div>
              <FormattedMessage id="client_address" />:{' '}
              {order.client.location.address}
            </div>
          )}
        </div>
        <Table
          showSorterTooltip={false}
          bordered={true}
          size={'small'}
          className={'tableClass'}
          columns={[
            {
              title: language.formatMessage({ id: 'product' }),
              dataIndex: 'product',
              render: (product, record) =>
                product ? product.description : record.description,
            },
            {
              title: language.formatMessage({ id: 'product_count' }),
              dataIndex: 'product_count',
              width: 100,
              render: (count) => <IntTextFormatter number={count} />,
            },
            {
              title: language.formatMessage({ id: 'product_price' }),
              dataIndex: 'product_price',
              width: 120,
              render: (totalPrice, record) => (
                <IntTextFormatter
                  number={record.product_price}
                  currencyText="֏"
                />
              ),
            },
            {
              title: language.formatMessage({ id: 'total_price' }),
              dataIndex: 'detail_price',
              width: 150,
              render: (totalPrice, record) => (
                <IntTextFormatter
                  number={record.product_count * record.product_price}
                  currencyText="֏"
                />
              ),
            },
          ]}
          dataSource={order.order_details}
          pagination={false}
        />
        <div className={styles.footerContainer}>
          <div>
            <div>
              <FormattedMessage id="order_date" />:{' '}
              {moment(order.ordered_date).format('DD/MM/YYYY HH:mm:ss')}
            </div>
            {order.finished_date && (
              <div>
                <FormattedMessage id="finish_date" />:{' '}
                {moment(order.finished_date).format('DD/MM/YYYY HH:mm:ss')}
              </div>
            )}
          </div>
          <div>
            <div>
              <FormattedMessage id="paid_amount" />:{' '}
              <IntTextFormatter number={order.payed_amount} currencyText="֏" />
            </div>
            <div>
              <FormattedMessage id="total_price" />:{' '}
              <IntTextFormatter number={order.total_price} currencyText="֏" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
